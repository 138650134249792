import DimensionInput from "../interfaces/DimensionInput";
import {useDiagramEditorContext} from "../index";
import React, {useCallback, useMemo} from "react";
import {lookupFromArray} from "../../../utils/miscUtilities";
import {InputType} from "../constants/enums";
import _ from "lodash";
import {InputRenderer} from "../interfaces/InputRenderer";
import {filterDimension} from "../utilities/helperUtilities";
import SelectInputRenderer from "./SelectInputRenderer";
import {Button, Dropdown} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPen as editIcon,
    faPlus as plusIcon
} from "@fortawesome/free-solid-svg-icons";
import {useSelectorEditorContext} from "../../selectorEditor";
import {useSelector} from "react-redux";
import {getUserData} from "../../../store/selectors/user";

// @ts-ignore
import className from "../../../assets/scss/components/diagrameditor.scss";

function EditDimensionGraphButton() {
    const { dimensionGraphs } = useSelector(getUserData);
    const {
        editDimensionGraph
    } = useSelectorEditorContext();
    const items = useMemo(() => dimensionGraphs?.map(({ id: key, label }) => {
        return { key, label }
    }), [dimensionGraphs]);
    const onClick = useCallback(
        ({ key }) => editDimensionGraph(key, false), [editDimensionGraph]);
    return items ? (
        <Dropdown
            menu={{ items, onClick }}
            placement="bottomRight">
            <Button icon={<FontAwesomeIcon icon={editIcon} />} />
        </Dropdown>
    ) : <></>;
}

function AddDimensionButton() {
    const {
        addNewDimensionGraph
    } = useSelectorEditorContext();
    return <Button
        onClick={() => addNewDimensionGraph(false)}
        icon={<FontAwesomeIcon icon={plusIcon} />} />;
}

export default function DimensionInputRenderer(
    props: InputRenderer<DimensionInput>
) {
    const {
        input,
        update,
        value,
        label,
        dataProperty
    } = props;
    const {
        dimensionType,
        allowActions = true
    } = input;
    const { chartConfig, dataDimensions } = useDiagramEditorContext();

    const dataDimensionLookup = useMemo(
        () => lookupFromArray(dataDimensions, d => d.id), [dataDimensions]);

    const dataDimensionOptions = useMemo(() => dataDimensions
        .filter(dimension => filterDimension(dimensionType, dimension, chartConfig))
        .map(({label, id: value}) => ({label, value})), [dimensionType, chartConfig]);

    return (
        <SelectInputRenderer
            dataProperty={dataProperty}
            label={label}
            input={{
                ...input,
                type: InputType.Select,
                options: dataDimensionOptions
            }}
            update={value => {
                const dataDimension = _.isArray(value)
                    ? value.map(v => dataDimensionLookup[v])
                    : dataDimensionLookup[value];
                update(dataDimension);
            }}
            value={_.isArray(value) ? value.map(v => v.id) : value?.id}
            elementAfterSelect={
                allowActions ? (
                    <>
                        <AddDimensionButton />
                        <EditDimensionGraphButton />
                    </>
                ): undefined
            } />
    );
}
