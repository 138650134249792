import Plugin from "../../interfaces/Plugin";
import {DataDimension, DiagramInstance, GeneralDiagramConfig} from "../../constants/types";
import Diagram from "../../interfaces/Diagram";
import {DiagramType, Legend} from "../../constants/enums";
import {Aggregation, Chart, DimensionTransform, Unit} from "../../../../constants/enums";
import dayjs from "dayjs";
import {DimensionWithSelector, DimensionType} from "../../../../interfaces/Config";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {lookupFromArray} from "../../../../utils/miscUtilities";
import _ from "lodash";
import {Crossfilter, Dimension as CrossfilterDimension, Group, OrderedValueSelector,} from "crossfilter2";
import {Key} from "react";
import * as dc from "dc";
import {BaseMixin, CoordinateGridMixin, PieChart, SunburstChart, UnitFunction as DcUnitFunction} from "dc";
import * as d3 from "d3";
import {Axis} from "d3";
// @ts-ignore
import reductio from "reductio";
import {Mapper} from "../../../statisticViewMaker/CategoricalRenderer";
import {MONTHS} from "../../../../constants/misc";
import {disposeGroup, isCompositeChart, isCoordinateMixin, isHeatmap} from "../../utilities/chartUtilities";
import {GroupingDefinition, GroupingDefinitionWithId, UnitFunction} from "../../../../constants/globalTypes";
import {noneOf, oneOf} from "../../utilities/helperUtilities";
import { DiagramConfig } from "./types";
import {paddedHeatmapGroup} from "./utilities";
import {COLORS, HEATMAP_SCHEME} from "./constants";
import {generalDiagram} from "./diagrams";

dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default class DcPlugin<Data> implements Plugin<Data> {
    private readonly _crossfilter: Crossfilter<Data>;

    private readonly _dimensions: Record<Key, CrossfilterDimension<Data, any>> = {};

    private readonly _uniqueValues: Record<Key, any[]> = {};

    private readonly _mappers: Record<Key, Mapper> = {};
    private _dimensionWithSelectorLookup!: Record<Key, DimensionWithSelector<Data>>;

    constructor(
        crossfilter: Crossfilter<Data>
    ) {
        this._crossfilter = crossfilter;
    }

    public getType(): string {
        return 'dc';
    }

    public render(
        dimensions: DimensionWithSelector<Data>[],
        element: HTMLElement,
        diagram: Diagram<any>,
        data: DiagramConfig,
        editing: boolean
    ): DiagramInstance {

        this._dimensionWithSelectorLookup = lookupFromArray(dimensions, ({ id }: DimensionWithSelector<Data>) => id);

        switch (diagram.type) {
            case DiagramType.GeneralDiagram:
                return this.renderGeneralDiagram(element, data as GeneralDiagramConfig, editing);
        }

    }

    public diagrams(): Diagram<any>[] {
        return [
            generalDiagram
        ];
    }

    private renderGeneralDiagram(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {
        switch (options.chartType) {
            case Chart.Bar:
                return this.createBarChart(element, options, editing);
            case Chart.Pie:
                return this.createPieChart(element, options, editing);
            case Chart.Heat:
                return this.createHeatChart(element, options, editing);
            case Chart.Line:
                return this.createLineChart(element, options, editing);
            case Chart.Scatter:
                return this.createScatterPlot(element, options, editing)
            case Chart.Bubble:
                return this.createBubbleChart(element, options, editing);
            case Chart.Box:
                return this.createBoxPlot(element, options, editing);
        }
    }

    private createSingleDimension(
        dimension: DataDimension,
        transform?: DimensionTransform
    ) {
        const dimensionFunction = this.createDimensionFunction(dimension, transform);
        return this._crossfilter.dimension(dimensionFunction, dimension.isArray);
    }

    private createDoubleDimension(
        a: DataDimension,
        b: DataDimension,
        aTransform?: DimensionTransform,
        bTransform?: DimensionTransform,
        groupBy?: DataDimension
    ) {
        const aFunc = this.createDimensionFunction(a, aTransform);
        const bFunc = this.createDimensionFunction(b, bTransform);
        if (_.isUndefined(groupBy)) {
            return this._crossfilter.dimension((d: Data) => {
                const first = _.castArray(aFunc(d));
                const second = _.castArray(bFunc(d));
                return _.flatMap(first, (x) => _.map(second, (y) => [x, y])) as any;
            }, true);
        } else {
            const groupByDimensionWithSelector = this._dimensionWithSelectorLookup[groupBy?.id];
            return this._crossfilter.dimension((d: Data) => {
                const first = _.castArray(aFunc(d));
                const second = _.castArray(bFunc(d));
                const groupValue = groupByDimensionWithSelector.selector(d);
                return _.flatMap(first, (x) => _.map(second, (y) => [x, y]))
                    .map(v => v.concat(groupValue)) as any;
            }, true);
        }
    }

    private createDimensionFunction(
        dataDimension: DataDimension,
        aggregation?: DimensionTransform,
    ): OrderedValueSelector<Data> {
        const dimensionWithSelector = this._dimensionWithSelectorLookup[dataDimension.id]
        switch (dimensionWithSelector.type) {
            case DimensionType.Date:
                return this.createDateDimensionFunction(dimensionWithSelector, aggregation!);
            case DimensionType.GeoCategorical:
            case DimensionType.Categorical: {
                return this.createCategoricalDimensionFunction(dimensionWithSelector);
            }
            case DimensionType.Numerical:
            case DimensionType.Coordinates:
            case DimensionType.Text:
                return dimensionWithSelector.selector;
        }
    }

    private createCategoricalDimensionFunction(
        dimension: DimensionWithSelector<Data>
    ) {
        const {
            id,
            selector,
            isArray
        } = dimension;
        const mapper = this.getOrdinalMapper(id);
        return (d: Data) => {
            const value = selector(d);
            return isArray
                ? value.map(mapper!.ordinalToInteger)
                : mapper!.ordinalToInteger(value);
        }
    }

    private createDateDimensionFunction(
        dimension: DimensionWithSelector<Data>,
        aggregation: DimensionTransform
    ): OrderedValueSelector<Data> {
        const {
            selector,
        } = dimension;
        switch (aggregation) {
            case DimensionTransform.Date:
                return d => dayjs(selector(d));
            case DimensionTransform.Weeks:
                return d => dayjs(selector(d)).week();
            case DimensionTransform.Months:
                return d => dayjs(selector(d)).month();
            case DimensionTransform.Year:
            default:
                return d => dayjs(selector(d)).year();
        }
    }

    private createBarChart(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {

        const {
            groupBy,
            xAxis,
            xAxisTransform,
            yAxis,
            yAxisAggregation,
            legend
        } = options;

        const chart = dc.barChart(element as any);

        const xUnits = this.getXAxisUnits(options);
        const crossfilterDimension = this.createSingleDimension(xAxis, xAxisTransform);
        const group = this.createGroup(crossfilterDimension, {
            dimension: yAxis,
            groupBy,
            aggregation: yAxisAggregation
        }, xUnits);

        this.setupDualAxisChart(element, chart, crossfilterDimension, options, editing);
        const valueAccessor = this.generateValueAccessorByAggregation(options.yAxisAggregation);

        if (!!legend && noneOf(legend, Legend.None)) {
            this.createLegend(chart);
        }

        if (groupBy || _.isArray(yAxis)) {
            const groupByValues = _.isArray(yAxis)
                ? yAxis.map(({ label }) => label)
                : this.getUniqueValues(groupBy!.id);
            groupByValues.forEach((groupByValue, index) => {
                if (index === 0) {
                    // @ts-ignore
                    chart.group(group, groupByValue, d => valueAccessor(d.value[groupByValue]));
                } else {
                    chart.stack(group, groupByValue, d => valueAccessor(d.value[groupByValue]));
                }
            });
        } else {
            chart
                .group(group)
                .valueAccessor(d => valueAccessor(d.value));
        }

        chart
            .xAxisPadding(0.5)
            .centerBar(true)
            .render();

        if (!!legend && noneOf(legend, Legend.None)) {
            this.positionAndResizeLegend(element, chart, legend);
            return this.createDiagramInstance(element, chart, (width, height) => {
                console.log('width', width, 'height', height);
                this.positionAndResizeLegend(element, chart, legend);
            });
        }
        return this.createDiagramInstance(element, chart);
    }

    private createHeatChart(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {
        const {
            valueAggregation,
            xAxis,
            xAxisUnit,
            xAxisTransform,
            yAxis,
            yAxisUnit,
            yAxisTransform,
            value,
            margins
        } = options;

        const chart = dc.heatMap(element as any);
        const crossfilterDimension = this.createDoubleDimension(xAxis, yAxis!, xAxisTransform, yAxisTransform);
        const unPaddedGroup = this.createGroup(crossfilterDimension, {
            dimension: value,
            aggregation: valueAggregation
        });
        const group = paddedHeatmapGroup(unPaddedGroup, valueAggregation!);
        const colorAccessor = this.generateValueAccessorByAggregation(valueAggregation!);
        const colsLabelFunction = this.getDimensionLabelFunction(xAxis, xAxisUnit, xAxisTransform);
        const rowsLabelFunction = this.getDimensionLabelFunction(yAxis!, yAxisUnit, yAxisTransform);

        chart
            .transitionDuration(0)
            .dimension(crossfilterDimension)
            .group(group)
            .keyAccessor(p => p.key[0])
            .valueAccessor(p => p.key[1])
            .colorAccessor(p => colorAccessor(p.value))
            .xBorderRadius(5)
            .colsLabel(colsLabelFunction)
            .rowsLabel(rowsLabelFunction)
            .colors(HEATMAP_SCHEME)
            .renderlet(this.adjustMarginsForXAxis)
            .calculateColorDomain();

        if (margins) {
            chart.margins(margins);
        }

        return this.createDiagramInstance(element, chart);
    }

    private createBubbleChart(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {
        const chart = dc.bubbleChart(element as any);
        const {
            groupBy,
            xAxis,
            xAxisAggregation,
            yAxis,
            yAxisAggregation,
            value,
            valueAggregation
        } = options;

        const crossfilterDimension = this.createSingleDimension(groupBy!);
        const group = this.createGroup(crossfilterDimension, [
            {
                id: 'x',
                dimension: xAxis,
                aggregation: xAxisAggregation,
            },
            {
                id: 'y',
                dimension: yAxis,
                aggregation: yAxisAggregation
            },
            {
                id: 'value',
                dimension: value,
                aggregation: valueAggregation
            }
        ]);

        this.setupDualAxisChart(element, chart, crossfilterDimension, options, editing);

        const xAccessor = this.generateValueAccessorByAggregation(xAxisAggregation!);
        const yAccessor = this.generateValueAccessorByAggregation(yAxisAggregation!);
        const valueAccessor = this.generateValueAccessorByAggregation(valueAggregation!);

        const labelFunction = this.getDimensionLabelFunction(groupBy!);

        chart
            .group(group)
            .keyAccessor(p => xAccessor(p.value.x))
            .valueAccessor(p => yAccessor(p.value.y))
            .radiusValueAccessor(p => valueAccessor(p.value.value))
            .label(d => labelFunction(d.key))
            .render();

        return this.createDiagramInstance(element, chart);
    }

    private createBoxPlot(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {
        const {
            xAxis,
            xAxisTransform,
            yAxis,
        } = options;
        const chart = dc.boxPlot(element as any);
        const crossfilterDimension = this.createSingleDimension(xAxis, xAxisTransform);
        this.setupDualAxisChart(element, chart, crossfilterDimension, options, editing);
        const { selector } = this._dimensionWithSelectorLookup[yAxis!.id];
        const group = crossfilterDimension.group().reduce(
            (p: any, d) => {
                const value = selector(d);
                if (_.isNumber(value)) {
                    p.splice(d3.bisectLeft(p, value), 0, value);
                }
                return p;
            },
            (p: any, d) => {
                const value = selector(d);
                if (_.isNumber(value)) {
                    p.splice(d3.bisectLeft(p, value), 1);
                }
                return p;
            },
            () => []
        );
        chart
            .group(group)
            .render();
        return this.createDiagramInstance(element, chart);
    }

    private createScatterPlot(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ) {
        const {
            xAxis,
            xAxisTransform,
            yAxis,
            yAxisTransform,
            groupBy
        } = options;
        const chart = dc.scatterPlot(element as any);
        const crossfilterDimension = this.createDoubleDimension(xAxis, yAxis!, xAxisTransform, yAxisTransform, groupBy);
        this.setupDualAxisChart(element, chart, crossfilterDimension, options, editing);
        const group = crossfilterDimension.group();
        chart
            .group(group)
            .symbolSize(12)
            .keyAccessor(d => d.key[0])
            .valueAccessor(d => d.key[1])
            .useCanvas(true)
            .render()

        if (groupBy) {
            const colors = COLORS.slice();
            const uniqueValues = this.getUniqueValues(groupBy.id);
            // @ts-ignore
            chart.colors(i => colors[i])
            chart.colorAccessor(d => uniqueValues.indexOf(d.key[2]) % colors.length);
        }

        return this.createDiagramInstance(element, chart);
    }

    private createLineChart(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {

        const {
            xAxis,
            xAxisTransform,
            yAxis,
            yAxisAggregation,
            groupBy
        } = options;

        if (_.isArray(yAxis)) {
            return this.createMultiLineChartFromYAxisArray(element, options, editing)
        } else if (groupBy) {
            return this.createMultiLineChartFromUniqueValues(element, options, editing)
        } else {
            const chart = dc.lineChart(element as any);
            const xUnits = this.getXAxisUnits(options);
            const crossfilterDimension = this.createSingleDimension(xAxis, xAxisTransform);
            const group = this.createGroup(crossfilterDimension, {
                dimension: yAxis,
                aggregation: yAxisAggregation
            }, xUnits);
            this.setupDualAxisChart(element, chart, crossfilterDimension, options, editing);
            const valueAccessor = this.generateValueAccessorByAggregation(options.yAxisAggregation);

            chart
                .group(group)
                .valueAccessor(d => valueAccessor(d.value))
                .render();

            return this.createDiagramInstance(element, chart);
        }
    }

    private createMultiLineChartFromYAxisArray(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ) {
        const composite = dc.compositeChart(element as any);

        const {
            xAxis,
            xAxisTransform,
            yAxisAggregation,
            yAxis,
            legend
        } = options;

        const xUnits = this.getXAxisUnits(options);
        const crossfilterDimension = this.createSingleDimension(xAxis, xAxisTransform);
        const yAxisId = [yAxis].flatMap(v => v!.id) as string[]
        const groups = _.flatten([yAxis]).map(dimension => {
            return this.createGroup(crossfilterDimension, {
                dimension,
                aggregation: yAxisAggregation
            }, xUnits);
        })
        this.setupDualAxisChart(element, composite, crossfilterDimension, options, editing);
        const valueAccessor = this.generateValueAccessorByAggregation(yAxisAggregation);

        if (legend) {
            this.createLegend(composite);
        }

        composite
            .renderHorizontalGridLines(true)
            .compose(groups.map((group, index) => {
                const chart = dc.lineChart(composite)
                    .dimension(crossfilterDimension)
                    .colors(COLORS[index % COLORS.length])
                    .valueAccessor((d: any) => valueAccessor(d.value))
                    .group(group, yAxisId[index]);
                this.setupXAxisUnits(chart, options);
                return chart;
            }));

        composite.transitionDuration(0);
        composite.render();

        if (legend) {
            this.positionAndResizeLegend(element, composite, legend);
        }

        return this.createDiagramInstance(element, composite);
    }

    private createMultiLineChartFromUniqueValues(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ): DiagramInstance {
        const {
            xAxis,
            xAxisTransform,
            yAxis,
            groupBy,
            yAxisAggregation,
            legend
        } = options;
        const composite = dc.compositeChart(element as any);
        const uniqueValues = this.getUniqueValues(groupBy!.id);
        const xUnits = this.getXAxisUnits(options);
        const crossfilterDimension = this.createSingleDimension(xAxis, xAxisTransform);
        const groups = uniqueValues.map(
            uniqueValue => this.createGroup(crossfilterDimension, {
                dimension: yAxis,
                groupBy,
                aggregation: yAxisAggregation,
                uniqueValue
            }, xUnits));
        this.setupDualAxisChart(element, composite, crossfilterDimension, options, editing);
        const valueAccessor = this.generateValueAccessorByAggregation(yAxisAggregation);

        if (legend) {
            this.createLegend(composite);
        }

        composite
            .renderHorizontalGridLines(true)
            .compose(groups.map((group, index) => {
                const chart = dc.lineChart(composite)
                    .dimension(crossfilterDimension)
                    .colors(COLORS[index % COLORS.length])
                    .valueAccessor((d: any) => valueAccessor(d.value))
                    .group(group, uniqueValues[index]);
                this.setupXAxisUnits(chart, options);
                return chart;
            }));

        composite.transitionDuration(0);
        composite.render();

        if (legend) {
            this.positionAndResizeLegend(element, composite, legend);
        }

        return this.createDiagramInstance(element, composite);
    }

    private createPieChart(
        element: HTMLElement,
        options: DiagramConfig,
        editing: boolean
    ) {
        const {
            groupBy,
            yAxisAggregation,
            xAxis,
            xAxisUnit,
            xAxisTransform,
            value,
            innerRadius = 0,
            legend
        } = options;
        const { height, width } = element.getBoundingClientRect();
        const isSunburstChart = !!groupBy;

        const chart = isSunburstChart
            ? dc.sunburstChart(element)
            : dc.pieChart(element);

        const xUnit = this.getXAxisUnits(options);
        const crossfilterDimension = isSunburstChart
            ? this.createDoubleDimension(groupBy!, xAxis, xAxisTransform)
            : this.createSingleDimension(xAxis, xAxisTransform);
        const group = this.createGroup(crossfilterDimension, {
            dimension: value,
            aggregation: yAxisAggregation,
            ignoreGroupBy: true,
            groupBy
        }, xUnit);

        const valueAccessor = this.generateValueAccessorByAggregation(yAxisAggregation);
        const xLabelFunction = this.getDimensionLabelFunction(xAxis, xAxisUnit, xAxisTransform)
        const groupByLabelFunction = this.getDimensionLabelFunction(groupBy!)

        if (!!legend && noneOf(legend, Legend.None)) {
            this.createLegend(chart);
        }

        this.adjustPositionAndRadius(chart, width, height, options);
        chart
            .innerRadius(innerRadius)
            .width(width)
            .height(height)
            .dimension(crossfilterDimension)
            .group(group)
            .valueAccessor(d => valueAccessor(d.value))
            .label(
                ({ key, depth }) => depth === 1
                    ? groupByLabelFunction(key)
                    : xLabelFunction(key))
            .render();

        if (legend) {
            this.positionAndResizeLegend(element, chart, legend);
        }

        return this.createDiagramInstance(element, chart, (width, height) =>
                this.adjustPositionAndRadius(chart, width, height, options, true));
    }

    private adjustPositionAndRadius(
        chart: PieChart | SunburstChart,
        width: number,
        height: number,
        options: DiagramConfig,
        render: boolean = false
    ) {
        const {
            margins,
            maxRadius = Infinity
        } = options;
        const {
            top = 0,
            right = 0,
            bottom = 0,
            left = 0
        } = margins || {};
        const horizontalMaxRadius = (width - left - right) / 2;
        const verticalMaxRadius = (height - top - bottom) / 2;
        const radius = Math.min(horizontalMaxRadius, verticalMaxRadius, maxRadius);
        const cx = (left + (width - right)) / 2;
        const cy = (top + (height - bottom)) / 2;
        chart.radius(radius);
        chart.cx(cx);
        chart.cy(cy);
        if (render) {
            chart.render();
        }
    }

    private createGroup(
        crossfilterDimension: CrossfilterDimension<Data, any>,
        groupings: GroupingDefinition<Data>|GroupingDefinitionWithId<Data>[],
        groupUnitFunction?: UnitFunction|undefined,
    ) {
        let group = _.isFunction(groupUnitFunction)
            ? crossfilterDimension.group(groupUnitFunction)
            : crossfilterDimension.group();

        const reducer = reductio();

        if (_.isArray(groupings)) {
            groupings.forEach(grouping => this.reduce(reducer, grouping, grouping.id));
        } else {
            this.reduce(reducer, groupings);
        }

        return reducer(group);
    }

    private reduce(
        baseReducer: any,
        grouping: GroupingDefinition<Data>,
        value?: string
    ) {
        const {
            dimension,
            groupBy,
            uniqueValue,
            aggregation = Aggregation.Count,
            ignoreGroupBy = false
        } = grouping;

        if (_.isArray(dimension)) {
            for (const { label, id } of dimension) {
                const dimensionWithSelector = this._dimensionWithSelectorLookup[id];
                const filter = this.createFilter(dimensionWithSelector);
                const valuePath = _.isUndefined(value) ? `${label}` : `${value}.${label}`;
                const reducer = baseReducer.value(valuePath).filter(filter);
                this.addAggregations(reducer, aggregation, dimensionWithSelector?.selector);
            }
        } else {
            const dimensionWithSelector = this._dimensionWithSelectorLookup[dimension?.id!];
            const groupByDimensionWithSelector = this._dimensionWithSelectorLookup[groupBy?.id!];

            if (groupByDimensionWithSelector && uniqueValue && !ignoreGroupBy) {
                const filter = this.createFilter(dimensionWithSelector, groupByDimensionWithSelector, uniqueValue);
                let reducer = _.isUndefined(value) ? baseReducer : baseReducer.value(value);
                reducer = reducer.filter(filter);
                this.addAggregations(reducer, aggregation, dimensionWithSelector?.selector);
            } else if (groupByDimensionWithSelector && _.isUndefined(uniqueValue) && !ignoreGroupBy) {
                const uniqueValues = this.getUniqueValues(groupBy?.id);
                for (const groupByValue of uniqueValues!) {
                    const filter = this.createFilter(dimensionWithSelector, groupByDimensionWithSelector, groupByValue);
                    const valuePath = _.isUndefined(value) ? groupByValue : `${value}.${groupByValue}`;
                    const reducer = baseReducer.value(valuePath).filter(filter);
                    this.addAggregations(reducer, aggregation, dimensionWithSelector?.selector);
                }
            } else {
                const filter = this.createFilter(dimensionWithSelector);
                let reducer = _.isUndefined(value) ? baseReducer : baseReducer.value(value);
                reducer = reducer.filter(filter);
                this.addAggregations(reducer, aggregation, dimensionWithSelector?.selector);
            }
        }

    }

    private createFilter(
        dimensionWithSelector?: DimensionWithSelector<Data>,
        groupByDimensionWithSelector?: DimensionWithSelector<Data>,
        value?: any,
        ignoreValues?: any[]
    ) {
        if (groupByDimensionWithSelector) {
            return groupByDimensionWithSelector.isArray
                ? (d: Data) => groupByDimensionWithSelector.selector(d).includes(value)
                    && (!dimensionWithSelector || !_.isUndefined(dimensionWithSelector?.selector(d)))
                : (d: Data) => groupByDimensionWithSelector.selector(d) === value
                    && (!dimensionWithSelector || !_.isUndefined(dimensionWithSelector?.selector(d)));
        } else if (dimensionWithSelector) {
            return (d: Data) => !_.isUndefined(dimensionWithSelector?.selector(d));
        }
        return () => true;
    }

    private addAggregations(
        reducer: reductio,
        groupAggregation: Aggregation,
        selector?: (d: Data) => any
    ) {
        return reducer
            .count((groupAggregation & Aggregation.Count) === Aggregation.Count)
            .avg((groupAggregation & Aggregation.Average) === Aggregation.Average ? selector : undefined)
            .sum((groupAggregation & Aggregation.Sum) === Aggregation.Sum ? selector : undefined)
            .max((groupAggregation & Aggregation.Max) === Aggregation.Max ? selector : undefined)
            .min((groupAggregation & Aggregation.Min) === Aggregation.Min ? selector : undefined)
            .std((groupAggregation & Aggregation.StandardDeviation) === Aggregation.StandardDeviation
                ? selector : undefined);
    }

    private setupDualAxisChart(
        element: HTMLElement,
        chart: CoordinateGridMixin<any>,
        crossfilterDimension: CrossfilterDimension<Data, any>,
        options: DiagramConfig,
        editing: boolean
    ) {

        const { height, width } = element.getBoundingClientRect();
        const x = this.getScale(options);
        const {
            xAxis,
            xAxisUnit,
            xAxisTransform,
            yAxis,
            yAxisUnit,
            yAxisTransform,
            margins
        } = options;

        chart
            .transitionDuration(0)
            .width(width)
            .height(height)
            .x(x)
            .brushOn(false)
            .elasticX(true)
            .elasticY(true)
            .margins({ ... this.defaultMargin })
            .dimension(crossfilterDimension)
            .renderlet(this.adjustMarginsForXAxis);
        if(xAxis) {
            this.setupXAxisUnits(chart, options);
            this.setupAxisTicks(chart.xAxis(), xAxis, xAxisUnit, xAxisTransform);
        }
        if (yAxis && !_.isArray(yAxis)) {
            this.setupAxisTicks(chart.yAxis(), yAxis, yAxisUnit, yAxisTransform);
        }
        if (margins) {
            chart.margins(margins);
        }
    }

    private createLegend(
        chart: BaseMixin<any>,
        itemHeight = 13,
        itemGap = 5
    ) {
        const legend = dc.legend().itemHeight(itemHeight).gap(itemGap);
        chart.legend(legend);
    }

    private getScale(
        options: DiagramConfig
    ) {
        const { xAxis } = options;
        switch (xAxis?.type) {
            case DimensionType.Date:
            case DimensionType.Numerical:
            case DimensionType.GeoCategorical:
            case DimensionType.Categorical:
            default:
                return d3.scaleLinear();
        }
    }

    private adjustMarginsForXAxis(
        chart: BaseMixin<any>,
        rotateXAxisLabels: boolean = true
    ) {
        if (rotateXAxisLabels) {
            if (isCoordinateMixin(chart)) {
                chart.selectAll('g.x text')
                    .attr('transform', 'translate(-10,10) rotate(315)')
                    .style("text-anchor", "end");
                const { height: height } = (chart.svg().select('.axis.x').node() as SVGGraphicsElement).getBBox();
                const oldMargins = { ...chart.margins()  };
                if (oldMargins.bottom < height ) {
                    chart.margins({
                        ...oldMargins,
                        bottom: height
                    }).render();
                }
            } else if (isHeatmap(chart)) {
                d3.selectAll('g.cols.axis > text').each(function() {
                    const element: any = this;
                    const selectedElement = d3.select(element);
                    const x = selectedElement.attr('x');
                    const y = selectedElement.attr('y');
                    selectedElement
                        .attr('x', 0)
                        .attr('y', 0);

                    d3.select(element.parentNode)
                        .insert("g")
                        .attr("class", "wrapped")
                        .attr("transform", `translate(${x}, ${y})`)
                        .append(() => element);
                });
                chart.selectAll('g.cols.axis text')
                    .attr('transform', 'translate(-3,0) rotate(315)')
                    .attr('x', 0)
                    .attr('y', 0)
                    .style("text-anchor", "end");
            }
        }
    }

    private setupXAxisUnits(
        chart: CoordinateGridMixin<any>,
        options: DiagramConfig
    ) {
        const {
            xAxis,
            xAxisUnit,
            xAxisTransform
        } = options;
        const xUnits = this.getAxisUnits(xAxis, xAxisUnit, xAxisTransform);
        if (_.isFunction(xUnits)) {
            chart.xUnits(xUnits as DcUnitFunction);
        }
    }

    private setupAxisTicks(
        axis: any,
        dimension: DataDimension,
        unit?: Unit,
        transform?: DimensionTransform
    ) {
        switch (dimension.type) {
            case DimensionType.GeoCategorical:
            case DimensionType.Categorical: {
                const mapper = this.getOrdinalMapper(dimension.id);
                (axis as Axis<any>)
                    .tickValues(d3.range(mapper.length()))
                    .tickFormat(mapper.integerToOrdinal)
                break;
            }
            case DimensionType.Date: {
                const tickFormatter = this.getDateFormatter(unit, transform);
                (axis as Axis<any>).tickFormat(tickFormatter)
                break;
            }
            default: {
                (axis as Axis<any>).tickFormat(_.toString);
            }
        }
    }

    private getDimensionLabelFunction(
        dimension?: DataDimension,
        unit?: Unit,
        transform?: DimensionTransform
    ) {
        const type = _.isUndefined(dimension) ? undefined : dimension.type;
        switch (type) {
            case DimensionType.GeoCategorical:
            case DimensionType.Categorical: {
                const mapper = this.getOrdinalMapper(dimension!.id);
                return (key: number) => mapper.integerToOrdinal(key);
            }
            case DimensionType.Date: {
                return this.getDateFormatter(unit, transform);
            }
            default: {
                return (key: any) => _.toString(key);
            }
        }
    }

    private getDateFormatter(
        unit?: Unit,
        aggregation?: DimensionTransform
    ) {
        if (aggregation === DimensionTransform.Date) {
            switch (unit) {
                case Unit.Year:
                    return (v: any) => dayjs(v).year().toString();
                case Unit.Months:
                    return (v: any) => `${dayjs(v).year()}, ${MONTHS[dayjs(v).month()]}`;
                case Unit.Weeks:
                    return (v: any) => `${dayjs(v).year()}, ${dayjs(v).week()}`;
            }
        } else if (aggregation === DimensionTransform.Months) {
            return (v: any) => MONTHS[v];
        }
        return (v: any) => v.toString();
    }

    private generateValueAccessorByAggregation(
        metric: Aggregation
    ) {
        switch (metric) {
            case Aggregation.Sum:
                return (d: any) => d.sum;
            case Aggregation.Average:
                return (d: any) => d.avg;
            case Aggregation.Max:
                return (d: any) => d.max;
            case Aggregation.Min:
                return (d: any) => d.min;
            case Aggregation.StandardDeviation:
                return (d: any) => d.std;
            case Aggregation.Count:
            default:
                return (d: any) => d.count;
        }
    }

    private get defaultMargin() {
        return {
            top: 10,
            right: 10,
            bottom: 20,
            left: 42
        };
    }

    private getUniqueValues(
        dimensionId?: Key
    ) {
        if (!_.isUndefined(dimensionId) && !this._uniqueValues[dimensionId!]) {
            const groupByDimensionWithSelector = this._dimensionWithSelectorLookup[dimensionId!];
            const data = this._crossfilter.all();
            this._uniqueValues[dimensionId!] = _.chain(data).map(groupByDimensionWithSelector.selector).flatten().uniq().value() as any;
        }
        return this._uniqueValues[dimensionId!] || [];
    }

    private getOrdinalMapper(
        dimensionId: Key
    ) {
        if (!_.isUndefined(dimensionId) && !this._mappers[dimensionId]) {
            const uniqueValues = this.getUniqueValues(dimensionId);
            this._mappers[dimensionId] = new Mapper(uniqueValues);

        }
        return this._mappers[dimensionId];
    }

    private getXAxisUnits(
        options: DiagramConfig
    ) {
        const {
            xAxis,
            xAxisUnit,
            xAxisTransform
        } = options;
        return this.getAxisUnits(xAxis, xAxisUnit, xAxisTransform);
    }

    private getAxisUnits(
        xAxis: DataDimension,
        xAxisUnit?: Unit,
        xAxisAggregation?: DimensionTransform
    ): ((d: Date) => any)|undefined {
        if (xAxis.type === DimensionType.Date && xAxisAggregation === DimensionTransform.Date) {
            switch (xAxisUnit) {
                case Unit.Weeks:
                    return d3.timeWeek;
                case Unit.Months:
                    return d3.timeMonth;
                case Unit.Year:
                    return d3.timeYear;
                default:
                case Unit.Date:
                    return d3.timeDay;
            }
        }
    }

    private createDiagramInstance(
        element: HTMLElement,
        chart: BaseMixin<any>,
        updateSizeCallback: (width: number, height: number) => void = _.noop
    ) {
        return {
            dispose() {
                chart.dimension().dispose();
                isCompositeChart(chart)
                    ? chart.children().forEach(disposeGroup)
                    : disposeGroup(chart);
                dc.chartRegistry.deregister(chart);
                while (element.firstChild) {
                    element.removeChild(element.lastChild!);
                }
            },
            updateSize() {
                const {
                    width,
                    height
                } = element.getBoundingClientRect();
                chart.width(width).height(height);
                updateSizeCallback(width, height);
                chart.render();
            }
        }
    }

    private positionAndResizeLegend(
        element: HTMLElement,
        chart: BaseMixin<any>,
        legend: Legend,
        itemHeight: number = 13,
        itemGap: number = 5,
        horizontalPadding: number = 10,
        verticalPadding: number = 10
    ) {
        if (chart.legend()) {
            const {
                height, width
            } = element.getBoundingClientRect();
            const marginTop = isCoordinateMixin(chart)
                ? chart.margins().top : 0;
            const maxItems = Math.floor((height - horizontalPadding - marginTop + itemGap) / (itemHeight + itemGap));
            const {
                width: legendWidth,
                height: legendHeight
            } = (chart.svg().select('.dc-legend').node() as SVGGraphicsElement).getBBox();
            const x = oneOf(legend, Legend.TopLeft, Legend.BottomLeft)
                ? horizontalPadding : width - legendWidth - horizontalPadding;
            const y = oneOf(legend, Legend.TopLeft, Legend.TopRight)
                ? marginTop : height - legendHeight - verticalPadding;
            console.log('inner', width, height, 'legendWidth', legendWidth, 'x', x, 'y', y, 'horizontalPadding', horizontalPadding)
            chart.legend().x(x).y(y).maxItems(maxItems);
        }
    }

}