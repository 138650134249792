import React, {ReactElement, useRef, useState} from "react";
import TabBarProperties from "../../interfaces/properties/TabBarProperties";
import {Tabs} from "antd";
import {useInjection} from "inversify-react";
import Config from "../../interfaces/Config";
import Injectable from "../../injection/injectable";
import TabProperties from "../../interfaces/properties/TabProperties";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import className from "../../assets/scss/components/tabbar.scss";
import { TabDefinition } from "../../constants/globalTypes";
import {analytics, AnalyticsEvent} from "../analytics";

function Tab(props: TabProperties) {
  const {definition} = props;
  if (definition) {
    const { name, icon } = definition;
    return (
      <div className={className.tab}>
        <div className={className.icon}>
          <FontAwesomeIcon icon={icon as IconProp}/>
        </div>
        {name}
      </div>
    );
  }
  return (
    <div
      className={`${className.tab}
          ${className.icon}`}>
      <FontAwesomeIcon
        icon={faExclamationTriangle as IconProp}/>
    </div>
  );
}

export interface TabBarContextState {
  activeTab: TabDefinition,
  setActiveTab: (activeTab: TabDefinition) => void;
}

export const TabBarContext = React.createContext<TabBarContextState>({} as TabBarContextState);

export default function AntDTabBar(
  props: TabBarProperties
) {
  const ref = useRef<HTMLDivElement>(null);
  const extraContent = useRef<ReactElement|undefined>();
  const { children, forceShowTabs } = props;
  const config = useInjection<Config>(Injectable.Config);
  const tabs = children.filter(child => !!child.props.tab).map(child => child.props.tab) as TabDefinition[];
  const [activeTab, setActiveTab] = useState<TabDefinition>(tabs[0]);

  const childWithTabDefinition = children.find(child => !!child.props.tab)
  if (!!childWithTabDefinition && !forceShowTabs) {
    return (
      <TabBarContext.Provider
        value={{
          activeTab: childWithTabDefinition.props.tab!,
          setActiveTab
        }}>
        <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
          {childWithTabDefinition}
        </div>
      </TabBarContext.Provider>
    )
  }

  return (
    <TabBarContext.Provider value={{ activeTab, setActiveTab }}>
      <Tabs
        type="card"
        tabBarGutter={4}
        className={className.tabBar}
        activeKey={tabs.indexOf(activeTab).toString()}
        tabBarExtraContent={extraContent.current}
        onChange={value => {
          const index = parseInt(value);
          const tab = tabs[index];
          extraContent.current = children[index].props.extraContent;
          analytics.track(AnalyticsEvent.TabChanged, {
            name: tab.name
          }).catch(console.error);
          setActiveTab(tab);
        }}
        items={
            children
                .filter(child => !!child.props.tab)
                .map((Component, index) => {
            const { tab } = Component.props;
            return ({
              key: index.toString(),
              label: <Tab definition={tab!} />,
              children: Component
            })
          })
        }
        size={config.tabSize} />
    </TabBarContext.Provider>
  );
}
