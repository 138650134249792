import RadioInput from "../interfaces/RadioInput";
import {useDiagramEditorContext} from "../index";
import {Form, Radio} from "antd";
import React from "react";
import {InputRenderer} from "../interfaces/InputRenderer";
import {useOptions} from "../utilities/hooks";
import FormItemLabel from "../FormItemLabel";

export default function RadioInputRenderer(
    props: InputRenderer<RadioInput>
) {
    const { input, update } = props;
    const {
        options,
        canBeUndefined,
        size = 'middle'
    } = input;
    const { chartConfig } = useDiagramEditorContext();
    const [value, defaultValue] = useOptions(props, options, chartConfig);

    return (
        <Form.Item
            label={<FormItemLabel {...props} />}
            rules={[{ required: !canBeUndefined }]}>
            <Radio.Group
                buttonStyle="solid"
                defaultValue={defaultValue}
                value={value}
                size={size}
                onChange={e => update(e.target.value)} >
                {
                    options.map(
                        ({ value, label }) => (
                            <Radio.Button
                                key={value}
                                value={value}>
                                {label}
                            </Radio.Button>
                        )
                    )
                }
            </Radio.Group>
        </Form.Item>
    );
}