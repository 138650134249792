import {DimensionId, FilterType, LayerType, Operator} from "./enums";
import {DefinedFilter, CustomFilter} from "./globalTypes";
import {DashboardSerialized, StatisticView} from "../components/statisticViewMaker";
import {AccidentLayerConfig, UserData} from "../store/interfaces/states/UserState";

export const DEFAULT_MESSAGE_DURATION = 2000;
export const DEFAULT_FIT_DURATION = 500;
export const DEFAULT_ZOOM = 3;
export const DEFAULT_MAX_ZOOM = 20;

export const DEFAULT_GEO_FILTER: CustomFilter = {
  type: FilterType.Geo,
  filterId: 'geo',
  label: 'Kartfilter',
  shouldIncludeInFilterView: false
};

export const DEFAULT_DESCRIPTION_FILTER: DefinedFilter = {
  dimensionId: DimensionId.Description,
  filterId: DimensionId.Description.toString(),
  type: FilterType.Text,
  operator: Operator.In,
  shouldIncludeInFilterView: false
}

export const DEFAULT_HOME_STATISTICS_VIEW: StatisticView = {
  components: [],
  layout: {}
}

export const DEFAULT_DASHBOARD: DashboardSerialized = {
  components: [],
  layout: {}
}

export const DEFAULT_ACCIDENT_LAYER_CONFIG: AccidentLayerConfig = {
  aggregationValueDimensionId: null,
  layerType: LayerType.ClusteredPoints,
  normalized: false
}

export const DEFAULT_USER_DATA: UserData = {
  savedFilters: [],
  homeStatisticView: DEFAULT_HOME_STATISTICS_VIEW,
  accidentLayerConfig: DEFAULT_ACCIDENT_LAYER_CONFIG,
  nightMode: false,
  filterExpanded: true
}

export const DEFAULT_ACTIVE_DIMENSION_IDS_IN_MORE_INFO_MODAL = [
  DimensionId.Coordinates,
  DimensionId.Date,
  DimensionId.County,
  DimensionId.AccidentType,
  DimensionId.ShipType,
  DimensionId.Dead,
  DimensionId.Injured,
  DimensionId.Missing,
  DimensionId.Description
]
