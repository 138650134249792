import {InputRenderer} from "../interfaces/InputRenderer";
import CheckBoxInput from "../interfaces/CheckBoxInput";
import {Checkbox, Form} from "antd";
import React from "react";
import FormItemLabel from "../FormItemLabel";

export default function CheckboxInputRender(
    props: InputRenderer<CheckBoxInput>
) {
    return (
        <Form.Item label={<FormItemLabel {...props} />}>
            <Checkbox />
        </Form.Item>
    );
}