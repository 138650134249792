import Diagram from "./interfaces/Diagram";
import {InputType} from "./constants/enums";
import React, {useCallback} from "react";
import {Collapse, Form} from "antd";
import {FunctionalComponent} from "../../constants/globalTypes";
import _ from "lodash";
import {InputEntry} from "./constants/types";
import {useDiagramEditorContext} from "./index";
import {groupInputs} from "./utilities/helperUtilities";
import {InputRenderer} from "./interfaces/InputRenderer";
import StringInputRenderer from "./inputRenderers/StringInputRenderer";
import NumericalInputRenderer from "./inputRenderers/NumericalInputRenderer";
import DimensionInputRenderer from "./inputRenderers/DimensionInputRenderer";
import SelectInputRenderer from "./inputRenderers/SelectInputRenderer";
import RadioInputRenderer from "./inputRenderers/RadioInputRenderer";
import CheckboxInputRender from "./inputRenderers/CheckboxInputRender";

// @ts-ignore
import className from "../../assets/scss/components/diagrameditor.scss";

function getRenderer(
    type: InputType
): FunctionalComponent<InputRenderer<any>> {
    switch (type) {
        case InputType.String:
            return StringInputRenderer;
        case InputType.Numerical:
            return NumericalInputRenderer;
        case InputType.Dimension:
            return DimensionInputRenderer;
        case InputType.Select:
            return SelectInputRenderer;
        case InputType.Radio:
            return RadioInputRenderer;
        case InputType.Checkbox:
            return CheckboxInputRender;
    }
}

interface ConfigurationRendererProperties {
    diagram: Diagram<any>;
}


export function DiagramConfigurationRenderer(props: ConfigurationRendererProperties) {
    const {
        diagram: {
            inputs,
            labelColumnSpan: span = 4
        },
    } = props;
    const {
        chartConfig,
        updateChartConfig
    } = useDiagramEditorContext()
    const [form] = Form.useForm();

    const renderer = useCallback((dataProperty, input) => {
        const {
            conditionalShow,
            label: labelGenerator
        } = input;
        const label = _.isFunction(labelGenerator) ? labelGenerator(chartConfig) : labelGenerator;
        const Renderer = getRenderer(input.type);
        const value = chartConfig[dataProperty];
        return !conditionalShow || conditionalShow(chartConfig) ? (
            <Renderer
                key={dataProperty}
                dataProperty={dataProperty}
                label={label}
                value={value}
                input={input}
                update={value => updateChartConfig((draft: any) => ({
                    ...draft,
                    [dataProperty]: value
                }))} />
        ) : <></>;
    }, [chartConfig]);

    const groupedInputs = groupInputs(chartConfig, inputs);
    const defaultActiveKeys = _.uniq(_.values(inputs)
        .map(({ group }) => _.isFunction(group) ? group(chartConfig) : group)
        .filter(group => !_.isUndefined(group))) as string[];

    return (
        <Form
            className={className.form}
            form={form}
            name="editor"
            labelAlign="left"
            labelCol={{ span }}>
            <Collapse defaultActiveKey={defaultActiveKeys} ghost>
                {
                    groupedInputs.map((inputEntry) => {
                        if (_.isString(inputEntry[0])) {
                            const [dataProperty, input] = inputEntry;
                            return renderer(dataProperty, input);
                        } else {
                            const { group } = inputEntry[0][1];
                            const groupLabel = _.isFunction(group) ? group(chartConfig) : group;
                            return (
                                <Collapse.Panel
                                    key={groupLabel!}
                                    header={groupLabel}>
                                    {
                                        (inputEntry as InputEntry[]).map(
                                            ([dataProperty, input]) =>
                                                renderer(dataProperty, input))
                                    }
                                </Collapse.Panel>
                            );
                        }
                    })
                }
            </Collapse>
        </Form>
    );
}