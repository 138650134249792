import React, {PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import DiagramEditorModal from "./DiagramEditorModal";
import Plugin from "./interfaces/Plugin";
import {DataDimension, DiagramInstance} from "./constants/types";
import {Updater, useImmer} from "use-immer";
import _ from "lodash";
import Diagram, {DataValidatorResponse} from "./interfaces/Diagram";
import {useSelector} from "react-redux";
import {getUserData} from "../../store/selectors/user";
import {DimensionWithSelector} from "../../interfaces/Config";
import {useSelectorEditorContext} from "../selectorEditor";
import {DataPoint} from "../../interfaces/models/DataPoint";

export type DiagramDoneCallback = (chartConfig: any) => void;

export interface DiagramEditorContextState {
    dataDimensions: DataDimension[];
    availableDiagrams: Diagram<any>[];
    renderDiagram: (element: HTMLElement, diagram: Diagram<any>, data: any, preview: boolean) => DiagramInstance;
    chartConfig: any;
    updateChartConfig: Updater<any>;
    openDiagramEditor: (chartConfig: any|undefined, callback: DiagramDoneCallback) => void;
    onSave: () => void
    onClose: () => void
    isDiagramValid: boolean
}

export const DiagramEditorContext = React.createContext<DiagramEditorContextState>({} as DiagramEditorContextState);

export function useDiagramEditorContext(): DiagramEditorContextState {
    return useContext(DiagramEditorContext);
}

interface DiagramState {
    diagrams: any[];
}

interface DiagramEditorProperties {
    plugins: Plugin<DataPoint>[];
    dimensions: DimensionWithSelector<any>[];
    onStateChange?: (state: DiagramState) => void;
    initialDiagramState?: DiagramState;
}

export default function DiagramEditorProvider(
    props: PropsWithChildren<DiagramEditorProperties>
) {
    const {
        dimensions: staticDataDimensions,
        plugins,
        children
    } = props;

    const availableDiagrams = _.flatMap(plugins.map(plugin => plugin.diagrams()));
    const initialCurrentDiagram = availableDiagrams[0]

    const callbackRef = useRef<DiagramDoneCallback | undefined>(undefined)
    const [open, setOpen] = useState(false);
    const [currentDiagram, setCurrentDiagram] = useState<Diagram<any>>(initialCurrentDiagram);
    const [chartConfig, updateChartConfig] = useImmer<any>({});

    useEffect(() => {
        const propertiesToRemove = _.entries(currentDiagram?.inputs)
            .filter(([dataProperty, { conditionalShow }]) =>
                conditionalShow && !conditionalShow(chartConfig) && !_.isUndefined(chartConfig[dataProperty]))
            .map(([dataProperty, ]) => dataProperty);
        if (propertiesToRemove.length > 0) {
            updateChartConfig((draft: any) => _.omit(draft, propertiesToRemove));
        }
    }, [chartConfig, currentDiagram]);

    const {
        dimensionGraphs = []
    } = useSelector(getUserData);
    const { convertDimensionGraphToDimension, nodePluginLookup } = useSelectorEditorContext();

    const dataDimensions = useMemo(() => dimensionGraphs
        .map(dimensionGraphs =>
            convertDimensionGraphToDimension(dimensionGraphs, staticDataDimensions, nodePluginLookup))
        .concat(staticDataDimensions), [dimensionGraphs]);

    const pluginLookup = useMemo(() => {
        const pluginLookup: Record<string, Plugin<DataPoint>> = {};
        plugins.forEach(plugin => {
           plugin.diagrams().forEach(({ type }) => pluginLookup[type] = plugin);
        });
        return pluginLookup;
    }, [plugins]);

    const isDiagramValid = useMemo(() => {
        const validateResponse = initialCurrentDiagram.validateData(chartConfig)
        console.log('validateresponse', validateResponse)
        return validateResponse.valid
    }, [chartConfig])

    const renderDiagram = useCallback(
        (element: HTMLElement, diagram: Diagram<any>, data: any, preview: boolean) => {
            return pluginLookup[diagram.type].render(dataDimensions, element, diagram, data, preview)
        },
        [dataDimensions, pluginLookup]);

    const openDiagramEditor = useCallback((chartConfig: any | undefined, callback: DiagramDoneCallback) => {
        callbackRef.current = callback
        setOpen(true);
        updateChartConfig(chartConfig ?? {});
    }, [setOpen, updateChartConfig]);

    const onClose = useCallback(() => {
        setOpen(false)
        callbackRef.current = undefined
        updateChartConfig({})
    }, [])

    const onSave = useCallback(() => {
        callbackRef.current?.(chartConfig)
        onClose()
    }, [onClose, chartConfig])

    return (
        <DiagramEditorContext.Provider value={{
            availableDiagrams,
            chartConfig,
            dataDimensions,
            openDiagramEditor,
            renderDiagram,
            updateChartConfig,
            onSave,
            onClose,
            isDiagramValid
        }}>
            {children}
            <DiagramEditorModal
                dataDimensions={dataDimensions}
                diagram={currentDiagram}
                open={open} />
        </DiagramEditorContext.Provider>
    );
}