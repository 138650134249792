import NodePlugin from "../../../interface/NodePlugin";
import {DataPoint} from "../../../../../interfaces/models/DataPoint";
import {NodeType, OutputType} from "../../../enums";
import {Edge, Handle, NodeProps, Position} from "reactflow";
import React, {memo} from "react";
import {Context, MenuItem} from "../../../types";
import {RESULT_IN} from "./constants";
import {checkHandleConnectionCount} from "../../../utilities/helperUtilities";

// @ts-ignore
import className from "../../../../../assets/scss/components/selectoreditor.scss";

export default class ResultNode implements NodePlugin<DataPoint, any> {

    public renderer(): React.FC<NodeProps> {
        return memo((props: NodeProps<{}>) => {
            const { isConnectable } = props;

            return (
                <div className={className.outputNode}>
                    <Handle
                        id={RESULT_IN}
                        type="target"
                        position={Position.Left}
                        style={{ background: 'black' }}
                        isConnectable={isConnectable} />
                    <span className={className.label}>Resultat</span>
                </div>
            );
        });
    }

    public execute(
        dataPoint: DataPoint,
        inputValues: Record<string, any>,
        nodeData: any,
        context: Context
    ): Record<string, any> {
        return { result: inputValues.in };
    }

    public validateConnection(handle: string, node: any): boolean {
        return handle === RESULT_IN && node.data.outputType === OutputType.Numerical;
    }

    public validateNode(
        nodeData: any,
        incomingEdges: Edge[],
        outgoingEdges: Edge[]
    ): boolean {
        return checkHandleConnectionCount(RESULT_IN, 1, 1, incomingEdges);
    }

    public menuItems(): MenuItem<any>[] {
        return [];
    }

    public type(): NodeType {
        return NodeType.Result;
    }

}