import {inject, injectable} from "inversify";
import axios, {AxiosResponse} from "axios";
import Client, {GetOptions, PostOptions, ResponsePromise, PutOptions, BaseCallOptions} from "../../interfaces/Client";
import _ = require("lodash");
import Injectable from "../../injection/injectable";
import Config from "../../interfaces/Config";
import {UserData} from "../../store/interfaces/states/UserState";


@injectable()
export default class ClientImplementation implements Client {

  @inject(Injectable.Config)
  private readonly _config!: Config;

  constructor() {
    axios.defaults.withCredentials = true;
  }

  public put<T>(options: PutOptions): Promise<AxiosResponse<T>> {
    const { data, config } = options || {};
    return this.performRequest(
      options,
      url => axios.put<T, AxiosResponse<T>>(url, data, config));
  }

  public get<T>(options: GetOptions): Promise<AxiosResponse<T>> {
    const { config } = options || {};
    return this.performRequest(
      options,
      url =>axios.get<T, AxiosResponse<T>>(url, config));
  }

  public post<T>(options: PostOptions): Promise<AxiosResponse<T>> {
    const { data, config } = options || {};
    return this.performRequest(
      options,
      url => axios.post<T, AxiosResponse<T>>(url, data, config));
  }

  public redirectToLogin() {
    const url = ClientImplementation.generateApiUrlFromEndpoint('auth/login');
    window.location.replace(url);
  }

  public get userData(): ResponsePromise<UserData> {
    return Promise.reject();
  }

  private performRequest<T>(
    options: BaseCallOptions,
    promiseCreator: (url: string) => Promise<AxiosResponse<T>>
  ) {
    const url = ClientImplementation.getUrlFromOptions(options);
    return promiseCreator(url);
  }

  private static getUrlFromOptions(options: GetOptions): string {
    if (_.isString(options.url)) {
      return options.url
    } else if (_.isString(options.apiEndpoint)) {
      return ClientImplementation.generateApiUrlFromEndpoint(options.apiEndpoint)
    }
    throw new Error('Url or endpoint not valid');
  }

  private static generateApiUrlFromEndpoint(endPoint: string) {
    console.log("i generateapiurl")
    const host = "uat2.digimake.no";// process.env.API_HOST; //process.env.API_HOST;
    const port = "443";//process.env.API_PORT;
    const version = "v1";//process.env.API_VERSION;

    console.log("API_HOST:"+host)
    console.log("API_PORT:"+port)
    console.log("API_VERSION:"+version)
    if (!host) {
      throw Error("API_HOST missing in environment");
    }
    if (!port) {
      throw Error("API_PORT missing in environment");
    }
    if (!version) {
      throw Error("API_VERSION missing in environment");
    }
    //const proto = (host === "localhost") || (host === "127.0.0.1") ? "http" : "https";
    const proto = "https";
    return `${proto}://${host}:${port}/api/${version}/${endPoint}`;
  }

}
