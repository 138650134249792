import {Node as ReactFlowNode} from "@reactflow/core/dist/esm/types/nodes";
import {useCallback, useEffect, useMemo} from "react";
import {getIncomers, useEdges, useNodes} from "reactflow";
import {useConfig} from "../../../../../utils/hooks";
import {DimensionNodeData} from "../dimension/interfaces";
import {DimensionWithSelector} from "../../../../../interfaces/Config";
import {DataPoint} from "../../../../../interfaces/models/DataPoint";
import {v4 as uuid} from "uuid";
import _ from "lodash";
import {useDataSourceContext} from "../../../../datasource/DataSourceProvider";
import {CaseData, DefaultCaseData} from "./types";
import {DEFAULT_CASE, DEFAULT_DEFAULT_CASE} from "./constants";
import {useNodeEditorContext} from "../../NodeEditor";
import {SwitchNodeData} from "./interfaces";

export function useNode(nodeId: string, nodes: ReactFlowNode[]) {
    return useMemo(
        () => nodes.filter(({ id }) => id === nodeId).pop(),
        [nodeId]) as ReactFlowNode;
}
export function useDimension(nodeId: string) {
    const edges = useEdges();
    const nodes = useNodes();
    const node = useNode(nodeId, nodes);
    const {dimensions} = useConfig();
    return useMemo(() => {
        const incomingEdges = getIncomers(node, nodes, edges);
        if (incomingEdges.length > 0) {
            const { dimensionId } = incomingEdges[0].data as DimensionNodeData;
            return dimensions.filter(({ id }) => id === dimensionId).pop();
        }
        return undefined;
    }, [edges]);
}

export function useCaseState(nodeId: string, data: SwitchNodeData, dimension?: DimensionWithSelector<DataPoint>) {
    const { updateNodeData } = useNodeEditorContext();
    const {
        cases = {},
        defaultCase = DEFAULT_DEFAULT_CASE
    } = data;

    const updateCaseData = useCallback((caseId: string, caseData: CaseData) => {
        updateNodeData(nodeId, {
            ...data,
            cases: {
                ...cases,
                [caseId]: caseData
            }
        });
    }, [cases, data, updateNodeData]);
    const addCase = useCallback(() => updateCaseData(uuid(), DEFAULT_CASE), [updateCaseData]);
    const clearCases = useCallback(() => {
        updateNodeData(nodeId, {
            ...data,
            cases: {},
            defaultCase: DEFAULT_DEFAULT_CASE
        });
    }, [data, updateNodeData]);
    const updateDefaultCase = useCallback(
        (defaultCase: DefaultCaseData) => updateNodeData(nodeId, { ...data, defaultCase }),
        [cases, defaultCase, data, updateNodeData]);

    useEffect(() => {
        if (_.isUndefined(dimension)) {
            clearCases();
        }
    }, [dimension]);

    return {
        addCase,
        cases,
        defaultCase,
        updateCaseData,
        updateDefaultCase
    };
}

export function useValueOptions(
    data: SwitchNodeData,
    dimension?: DimensionWithSelector<DataPoint>
) {
    const { dataSource, data: dataSourceData } = useDataSourceContext();
    const { cases } = data;
    const usedValues = useMemo(() => _.flatMap(_.values(cases).map(v => v.caseValues)), [cases]);
    const valueOptions = useMemo(() =>
        dimension
            ? dataSource.getUniqueValuesForDimension(dimension)
                .filter(({ value }) => !usedValues.includes(value))
                .map(({ value }) => ({
                    value: value,
                    label: value
                }))
            : [], [dimension, usedValues, dataSourceData]);
    const shouldIncludeDefault = useMemo(
        () => valueOptions.length > 0 || _.isUndefined(dimension), [valueOptions]);
    return {
        shouldIncludeDefault,
        valueOptions
    };
}