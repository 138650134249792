import * as ol from 'ol';
import {Feature} from 'ol';
import * as d3 from 'd3';
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DimensionId, DimensionLabel, GeoFilterType, LayerKey, LayerType} from "../../../constants/enums";
import VectorSource from "ol/source/Vector";
import {Geometry, Point, Polygon} from "ol/geom";
// @ts-ignore
import SuperCluster from "ol-supercluster";
import VectorLayer from "ol/layer/Vector";
import {Heatmap} from "ol/layer";
import {Circle, Fill, Stroke, Style, Text} from "ol/style";
import _ from "lodash";
import {useDataSourceContext} from "../../datasource/DataSourceProvider";
import DataSource from "../../../interfaces/DataSource";
import {fromLonLat} from "ol/proj";
import {DataPoint} from "../../../interfaces/models/DataPoint";
import {useSelector} from "react-redux";
import {getFocusId, getGeoFilter} from '../../../store/selectors/data';
import {Disposable, FunctionalComponent, CustomFilter, StyleConfig} from "../../../constants/globalTypes";
import {fromExtent} from "ol/geom/Polygon";
import {alwaysTrue, rgbToRgba} from "../../../utils/miscUtilities";
import {getNightMode} from "../../../store/selectors/user";
import {
  FILTERED_IN_STYLE_CONFIG,
  FILTERED_IN_STYLE_CONFIG_NIGHT_MODE,
  FILTERED_OUT_STYLE_CONFIG,
  FILTERED_OUT_STYLE_CONFIG_NIGHT_MODE
} from '../../../constants/colors';
import Injectable from "../../../injection/injectable";
import {injectReactComponent} from "../../../utils/injectionUtilities";
import {GeoJSON} from "ol/format";
import Config, {DimensionWithSelector, GeoJSONLayerConfig} from "../../../interfaces/Config";
import {useInjection} from "inversify-react";
import {useDimension} from "../../../utils/hooks";
import RenderFeature from "ol/render/Feature";
import LayerGroup from "ol/layer/Group";
import BaseLayer from "ol/layer/Base";
import {v4 as uuid} from "uuid";
import {GradientControlProperties} from "../../ui/AntDGradientControl";
import {createEmpty, extend} from 'ol/extent';
import useGeoFilter, {GeoFilterUpdater} from "./useGeoFilter";

function getClusterStyle(
  size: number,
  clusterFill: string,
  clusterStroke: string,
  textColor: string
): Style {
  return new Style({
    image: new Circle({
      radius: 12,
      fill: new Fill({
        color: clusterFill,
      }),
      stroke: new Stroke({
        color: clusterStroke,
        width: 2
      })
    }),
    text: new Text({
      text: size.toString(),
      fill: new Fill({
        color: textColor,
      })
    })
  });
}

function updatePointFeatureStyleFunction(
  pointFeatureLayer: VectorLayer<VectorSource<Point>>|undefined,
  config: StyleConfig
): void {
  const {
    markerFill,
    clusterFill,
    clusterStroke,
    textColor
  } = config;
  const markerStyle = new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: markerFill,
      }),
    })
  });
  const styleCache: Record<string, Style> = {};
  pointFeatureLayer?.setStyle((cluster) => {
    const features = cluster.get('features');
    const size = features?.length;
    if (size > 1) {
      return styleCache[size] = styleCache[size] ?? getClusterStyle(size, clusterFill, clusterStroke, textColor);
    }
    return markerStyle;
  })
  pointFeatureLayer?.changed();
}

function createCountiesLayer(
  countiesLayerConfig?: GeoJSONLayerConfig
): VectorLayer<VectorSource<Polygon>>|null {
  if (countiesLayerConfig) {
    const source = new VectorSource<Polygon>({
      wrapX: false,
      url: countiesLayerConfig.url,
      format: new GeoJSON(),
    });
    return new VectorLayer({
      source,
      properties: { includeInLayerSwitcher: false },
      zIndex: 2,
      visible: false,
    });
  }
  return null;
}

function zoomToFeatures(
  map: ol.Map,
  features: Feature<Geometry>[]
) {
  const extent = createEmpty();
  features.forEach(function(feature){
    const featureExtent = feature.getGeometry()?.getExtent();
    if (featureExtent) {
      extend(extent, featureExtent);
    }
  });
  map.getView().fit(extent, {
    size: map.getSize(),
    duration: 100
  });
}

function createAccidentPointFeatureLayer(
  map: ol.Map|undefined,
  styleConfig: StyleConfig,
  zIndex: number = 2,
  geoFilterUpdater: GeoFilterUpdater|null = null
) {
  if (map) {
    const source = new VectorSource<Point>({
      wrapX: false
    });
    const clusterSource = new SuperCluster({
      source: source,
      view: map.getView(),
      radius: 40,
      wrapX: false
    });
    const layer = new VectorLayer({
      source: clusterSource,
      properties: { includeInLayerSwitcher: false },
      zIndex: zIndex,
      visible: false,
    });
    layer.set('featureSource', source);
    updatePointFeatureStyleFunction(layer, styleConfig);
    if (_.isFunction(geoFilterUpdater)) {
      const layerFilter = (comparatorLayer: BaseLayer) => comparatorLayer === layer;
      map.on('click', e => {
        const { pixel } = e;
        const features = map.getFeaturesAtPixel(pixel, { layerFilter, hitTolerance: 0 });
        if (features.length > 0) {
          const subFeatures = features[0].get('features');
          if (subFeatures.length === 1) {
            const feature = subFeatures[0];
            const { id } = feature.getProperties().data;
            geoFilterUpdater(GeoFilterType.ID, id);
          }
        }
      });
    }
    return layer;
  }
  /*
  const pointFeatureLayer: PointFeatureLayer = {
    getLayer: () => layer,
    setVisible: (visible: boolean) => layer.setVisible(visible),
    getSource: () => source,
    getFeatures: () => source.getFeatures(),
    setStyle: style => {
      _.keys(styleCache).forEach(key => delete styleCache[key]);
      layer.setStyle(feature => style(feature, styleCache));
      layer.changed();
    },
    updateFeatures: features => {
      source.clear(true);
      source.addFeatures(features);
      layer.changed();
    }
  };
  updatePointFeatureStyleFunction(pointFeatureLayer, styleConfig);
  return pointFeatureLayer;
   */
}

function createHeatmapLayer(
    dataSource: DataSource,
    pointFeatureLayer?: VectorLayer<VectorSource<Point>>,
    aggregationValueDimension?: DimensionWithSelector<DataPoint>,
    visible: boolean = false
) {
  if (pointFeatureLayer) {
    return new Heatmap({
      source: pointFeatureLayer.get('featureSource'),
      properties: {
        includeInLayerSwitcher: false
      },
      visible,
      zIndex: 2,
      radius: 3,
      blur: 3,
      weight: aggregationValueDimension
        ? (feature: Feature<Geometry>) => {
          const { data } = feature.getProperties();
          const value = Math.max(aggregationValueDimension.selector(data), 0);
          const { max } = dataSource.getBoundsForDimension(aggregationValueDimension);
          return value / max;
        }
        : () => 1
    });
  }
  return null;
}

function getFilterFunction(
  geoFilter?: CustomFilter
) {
  const { type, value } = geoFilter?.settings || {};
  switch (type) {
    case GeoFilterType.Polygon: {
      const polygon = new Polygon([value]);
      return (d: any) => polygon.intersectsCoordinate(d.coordinates);
    }
    case GeoFilterType.Extent: {
      const extentPolygon = fromExtent(value);
      return (d: any) => extentPolygon.intersectsCoordinate(d.coordinates);
    }
    case GeoFilterType.ID: {
      return (d: any) => d.id === value;
    }
    case GeoFilterType.IDs: {
      return (d: any) => value.includes(d.id);
    }
  }
  return alwaysTrue;
}

function getGeoDimension(
  dataSource: DataSource
) {
  return dataSource?.dimension(
    DimensionId.Geo,
    DimensionLabel.Geo,
    ({
       id,
       location: {
         coordinates
       },
      county
    }) => {
      return {
        id,
        coordinates: fromLonLat(coordinates),
        county
      };
    });
}

function featureFromDataPoint(
  dataPoint: DataPoint,
  index: number
): Feature<Point> {
  const { location } = dataPoint
  const coordinates = fromLonLat(location.coordinates);
  const geometry = new Point(coordinates);
  const feature = new Feature({
    geometry,
    data: dataPoint
  });
  feature.setId(index.toString());
  feature.set('index', index);
  return feature;
}

function generateUpdateFunction(
  keyAccessor: (dataPoint: DataPoint) => string,
  valueAccessor: (dataPoint: DataPoint) => number = () => 0,
  factor: number = 1
) {
  return (p: any, v: DataPoint) => {
    const key = keyAccessor(v);
    p[key] = p[key] || {
      sum: 0,
      count: 0
    }
    p[key].sum += factor * Math.max(valueAccessor(v), 0)
    p[key].count += 1
    return p;
  }
}

function polygonAggregationStyleFunction(
  valueAccessor: (name: string) => number,
  minValue: number,
  maxValue: number
) {
  return (feature: Feature<Geometry>|RenderFeature) => {
    const { navn: name } = feature.getProperties();
    const t = (valueAccessor(name) - minValue) / (maxValue - minValue);
    const color = d3.interpolateWarm(t);
    return new Style({
      fill: new Fill({
        color: rgbToRgba(color, 1.0),
      })
    });
  }
}

function generateSumFunction(
  valueLookup: Record<string, { sum: number, count: number }>,
  normalized: boolean
) {
  if (normalized) {
    return (name: string) => {
      const {
        sum = 0,
        count = Infinity
      } = valueLookup[name] || {};
      return sum / count;
    }
  } else {
    return (name: string) => {
      const {
        sum = 0,
      } = valueLookup[name] || {};
      return sum;
    }
  }
}


function generateCountFunction(
  valueLookup: Record<string, { sum: number, count: number }>,
) {
  return (name: string) => {
    const {
      count = Infinity
    } = valueLookup[name] || {};
    return count;
  }
}

function linkPolygonAggregationLayerWithDataSource(
  countiesLayer: VectorLayer<VectorSource<Polygon>>|null,
  normalized: boolean,
  layerType: LayerType,
  aggregationKeyDimensionId: DimensionId,
  aggregationValueDimensionId: DimensionId|null
): [number, number] {
  const { dataSource } = useDataSourceContext();
  const geoDimension = getGeoDimension(dataSource);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1);
  const dataFilteredDisposable = useRef<Disposable|null>(null);
  const aggregationKeyDimension = useDimension(aggregationKeyDimensionId);
  const aggregationValueDimension = useDimension(aggregationValueDimensionId);

  const countiesGroup = useMemo(() => {
    if (geoDimension && aggregationKeyDimension) {
      const keyAccessor = aggregationKeyDimension.selector;
      return geoDimension?.groupAll<Record<string, {
        sum: number,
        count: number
      }>>().reduce(
        generateUpdateFunction(keyAccessor, aggregationValueDimension?.selector),
        generateUpdateFunction(keyAccessor, aggregationValueDimension?.selector, -1),
        () => ({})
      )
    }
  }, [geoDimension, aggregationKeyDimension, aggregationValueDimension, normalized]);

  const updateStyleFunction = useCallback(() => {
    const valueLookup = countiesGroup?.value();
    if (valueLookup) {
      const valueAccessor = aggregationValueDimension
        ? generateSumFunction(valueLookup, normalized)
        : generateCountFunction(valueLookup);
      const maxValue = Math.max(..._.keys(valueLookup).map(valueAccessor));
      const minValue = Math.min(..._.keys(valueLookup).map(valueAccessor));
      const styleFunction = polygonAggregationStyleFunction(valueAccessor, minValue, maxValue);
      countiesLayer?.setStyle(styleFunction);
      countiesLayer?.changed();
      setMaxValue(maxValue);
      setMinValue(minValue);
    }
  }, [countiesLayer, countiesGroup, normalized]);

  useEffect(() => {
    dataFilteredDisposable.current?.dispose();
    if (layerType == LayerType.Counties) {
      updateStyleFunction();
      dataFilteredDisposable.current = dataSource?.onDataFiltered(() => updateStyleFunction());
    }
  }, [layerType, updateStyleFunction]);

  return [minValue, maxValue]
}

function updateFeatures(
  pointFeatureLayer: VectorLayer<VectorSource<Point>>|undefined,
  features: Feature<Point>[]
) {
  if (pointFeatureLayer) {
    const source = pointFeatureLayer?.get('featureSource') as VectorSource<Point>;
    source.clear(true);
    source.addFeatures(features);
    pointFeatureLayer.changed();
  }
}

function linkPointLayersWithDataSource(
  filteredInFeatureLayer: VectorLayer<VectorSource<Point>>|undefined,
  filteredOutFeatureLayer: VectorLayer<VectorSource<Point>>|undefined,
  layerType: LayerType
): void {
  const { dataSource } = useDataSourceContext();
  const geoFilter = useSelector(getGeoFilter);
  const coordinateDimension = getGeoDimension(dataSource);
  const featureLookup = useRef<Record<string, Feature<Point>>>({});
  const dataChangedDisposable = useRef<Disposable|null>(null);
  const dataFilteredDisposable = useRef<Disposable|null>(null);

  const updateFeatureSource = useCallback(() => {
    const features = _.values(featureLookup.current);
    const filteredInFeatures = features.filter(feature => dataSource.isElementFiltered(feature.get('index')));
    const filteredOutFeatures = features.filter(feature => !dataSource.isElementFiltered(feature.get('index')));
    updateFeatures(filteredInFeatureLayer, filteredInFeatures);
    updateFeatures(filteredOutFeatureLayer, filteredOutFeatures);
  }, [filteredInFeatureLayer, filteredOutFeatureLayer]);

  useEffect(() => {
    if (filteredInFeatureLayer && filteredOutFeatureLayer) {
      dataChangedDisposable.current = dataSource.onDataChanged(() => {
        dataSource.crossfilter.all()
          .filter(({id}) => !featureLookup.current[id])
          .forEach((dataPoint, index) => featureLookup.current[dataPoint.id] = featureFromDataPoint(dataPoint, index))
        updateFeatureSource();
      });
    }
  }, [filteredInFeatureLayer, filteredOutFeatureLayer, updateFeatureSource])

  useEffect(() => {
    dataFilteredDisposable.current?.dispose();
    if (layerType == LayerType.ClusteredPoints || layerType == LayerType.HeatMap) {
      dataFilteredDisposable.current = dataSource?.onDataFiltered(() => updateFeatureSource());
    }
  }, [layerType, updateFeatureSource]);

  useEffect(() => {
    if (layerType == LayerType.ClusteredPoints || layerType == LayerType.HeatMap) {
      let filterFunc = getFilterFunction(geoFilter);
      coordinateDimension.filter(filterFunc);
    }
  }, [geoFilter, layerType]);

}

interface DataLayerConfig {
  layerType: LayerType;
  normalized: boolean;
  aggregationValueDimensionId: DimensionId|null;
}

export default function useDataLayer(
  config: DataLayerConfig,
  map?: ol.Map
): [BaseLayer, FunctionalComponent, FunctionalComponent] {
  const {
    geoJSONLayerConfigs = []
  } = useInjection<Config>(Injectable.Config);
  const {
    dataSource
  } = useDataSourceContext();
  const {
    layerType,
    normalized,
    aggregationValueDimensionId
  } = config;
  const [geoFilter, geoFilterUpdater] = useGeoFilter();

  const focusId = useSelector(getFocusId);

  const countiesLayerConfig = useMemo(
    () => geoJSONLayerConfigs.filter(({ id }) => id == LayerKey.Counties).pop(), [geoJSONLayerConfigs]);
  const nightMode = useSelector(getNightMode);
  const aggregationValueDimension = useDimension(aggregationValueDimensionId);

  const filteredInStyleConfig = useMemo(
    () => nightMode ? FILTERED_IN_STYLE_CONFIG_NIGHT_MODE : FILTERED_IN_STYLE_CONFIG, [nightMode]);
  const filteredOutStyleConfig = useMemo(
    () => nightMode ? FILTERED_OUT_STYLE_CONFIG_NIGHT_MODE : FILTERED_OUT_STYLE_CONFIG, [nightMode]);

  useEffect(() => {
    const {
      settings
    } = geoFilter;
    if (!focusId && settings?.type !== GeoFilterType.Polygon && settings?.type !== GeoFilterType.Extent) {
      geoFilterUpdater();
    } else if (focusId) {
      geoFilterUpdater(GeoFilterType.ID, focusId);
    }
  }, [focusId]);

  const {
    filteredInFeatureLayer,
    filteredOutFeatureLayer,
    countiesLayer
  } = useMemo(() => {
    const filteredInFeatureLayer = createAccidentPointFeatureLayer(map, filteredInStyleConfig, 2, geoFilterUpdater);
    const filteredOutFeatureLayer = createAccidentPointFeatureLayer(map, filteredOutStyleConfig, 1);
    const countiesLayer = createCountiesLayer(countiesLayerConfig);
    return {
      filteredInFeatureLayer,
      filteredOutFeatureLayer,
      countiesLayer
    }
  }, [map]);
  const filteredInHeatmapLayer = useMemo(
    () => createHeatmapLayer(dataSource, filteredInFeatureLayer, aggregationValueDimension),
    [filteredInFeatureLayer, aggregationValueDimension]);

  useEffect(() => {
    updatePointFeatureStyleFunction(filteredInFeatureLayer, filteredInStyleConfig);
    updatePointFeatureStyleFunction(filteredOutFeatureLayer, filteredOutStyleConfig);
  }, [nightMode]);

  linkPointLayersWithDataSource(filteredInFeatureLayer, filteredOutFeatureLayer, layerType);
  const [minValue, maxValue] = linkPolygonAggregationLayerWithDataSource(
    countiesLayer, normalized, layerType, DimensionId.County, aggregationValueDimensionId);

  useEffect(() => {
    filteredInHeatmapLayer?.setVisible(layerType === LayerType.HeatMap);
    filteredInFeatureLayer?.setVisible(layerType === LayerType.ClusteredPoints);
    filteredOutFeatureLayer?.setVisible(layerType === LayerType.ClusteredPoints);
    countiesLayer?.setVisible(layerType === LayerType.Counties);
  }, [layerType, filteredInHeatmapLayer, filteredInFeatureLayer, filteredOutFeatureLayer, countiesLayer]);

  const layerGroup = useRef<LayerGroup>(new LayerGroup({
    visible: true,
    properties: {
      key: uuid(),
      includeInLayerSwitcher: false
    }
  }));
  const layer = useMemo(() => {
    if (filteredInHeatmapLayer && filteredInFeatureLayer && filteredOutFeatureLayer && countiesLayer) {
      layerGroup.current.setLayers(new ol.Collection<BaseLayer>([
        filteredInFeatureLayer,
        filteredOutFeatureLayer,
        countiesLayer,
        filteredInHeatmapLayer
      ]));
    }
    return layerGroup.current;
  }, [filteredInHeatmapLayer, filteredInFeatureLayer, filteredOutFeatureLayer, countiesLayer]);

  const AccidentControl = injectReactComponent(Injectable.AccidentControl);
  const GradientControl = injectReactComponent<GradientControlProperties>(Injectable.GradientControl);

  return [
    layer,
    AccidentControl,
    useMemo(() => () => {
      return <GradientControl
        show={layerType === LayerType.Counties}
        min={minValue}
        max={maxValue} />
    }, [layerType, minValue, maxValue])
  ];
}
