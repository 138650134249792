import React, {useCallback} from "react";
import {NodeToolbar, Position, useNodeId} from "reactflow";
import { Button } from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {useNodeEditorContext} from "./NodeEditor";

type NodeMenuAction = {
    icon: IconDefinition;
    action: () => void;
}
interface NodeMenuProperties {
    actions?: NodeMenuAction[];
}

export default function NodeMenu(
    props: NodeMenuProperties
) {
    const {
        actions = []
    } = props;
    const nodeId = useNodeId();
    const { deleteNode } = useNodeEditorContext();

    const deleteNodeAction = useCallback(() => {
        if (nodeId) {
            deleteNode(nodeId);
        }
    }, [nodeId, deleteNode]);

    return (
        <NodeToolbar position={Position.Top}>
            <Button.Group>
                {
                    actions.map(({ icon, action }) => (
                        <Button type="primary" onClick={action}>
                            <FontAwesomeIcon icon={icon} />
                        </Button>
                    ))
                }
                <Button
                    type="primary"
                    onClick={deleteNodeAction}
                    danger>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </Button.Group>
        </NodeToolbar>
    )
}