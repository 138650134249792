import * as React from "react";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {ToolbarComponent} from "../../constants/globalTypes";
import {PropsWithChildren} from "react";
import {Alert} from "antd";

import className from '../../assets/scss/main.scss';

export default function SharedLayout(props: PropsWithChildren<{}>) {

  const { children } = props;
  const Toolbar = useInjection<ToolbarComponent>(Injectable.Toolbar);
  return (
    <div className={className.mainLayout}>
        <Alert
            // message="Det er ikke lov til å bruke plattformen til kommersiell virksomhet."
            message="Viktig: Informasjonen i plattformen må kun brukes for uttesting av funksjonalitet, ikke for operativ virksomhet."
            banner
            closable
            style={{
                height: 42,
                fontSize: 14,
            }}
        />
        <Toolbar />
        <div className={className.content}>
        { children }
      </div>
    </div>
  );
}
