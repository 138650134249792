import BaseInput from "../interfaces/BaseInput";
import React, {useEffect, useMemo} from "react";
import _ from "lodash";
import NumericalInput from "../interfaces/NumericalInput";
import {useDiagramEditorContext} from "../index";
import {Form, InputNumber} from "antd";
import {InputRenderer} from "../interfaces/InputRenderer";

// @ts-ignore
import className from "../../../assets/scss/components/diagrameditor.scss";
import FormItemLabel from "../FormItemLabel";

function useValue(
    props: InputRenderer<BaseInput>
) {
    const {
        value,
        update,
        input: {
            defaultValue,
        }
    } = props;

    useEffect(() => {
        if (_.isUndefined(value) && !_.isUndefined(defaultValue)) {
            update(defaultValue);
        }
    }, [value, defaultValue]);

    return useMemo(
        () => props.value || defaultValue, [props.value, defaultValue]);
}

export default function NumericalInputRenderer(
    props: InputRenderer<NumericalInput>
) {
    const { update, input } = props;
    const {
        properties,
        minValue,
        maxValue,
    } = input;
    const value = useValue(props);

    if (_.isArray(properties)) {
        return (
            <Form.Item
                style={{ marginBottom: 0 }}
                label={<FormItemLabel {...props} />}>
                <div className={className.numericalInput}>
                    {
                        properties.map(property => (
                            <Form.Item label={_.capitalize(property)}>
                                <InputNumber
                                    style={{width: 60}}
                                    min={minValue}
                                    max={maxValue}
                                    value={value[property]}
                                    onChange={newPropertyValue => update({
                                        ...value,
                                        [property]: newPropertyValue
                                    })} />
                            </Form.Item>
                        ))
                    }
                </div>
            </Form.Item>
        );
    }
    return (
        <Form.Item label={<FormItemLabel {...props} />}>
            <InputNumber
                value={value}
                min={minValue}
                max={maxValue}
                onChange={update} />
        </Form.Item>
    );
}