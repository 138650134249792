import {GeoFilterValue, CustomFilter} from "../../../constants/globalTypes";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import _ from "lodash";
import {updateFilterDefinition} from "../../../store/actions/data";
import {GeoFilterType} from "../../../constants/enums";
import {getGeoFilter} from "../../../store/selectors/data";

export type GeoFilterUpdater = (type?: GeoFilterType, value?: string|string[]|number[]) => void;

export default function useGeoFilter(): [CustomFilter<GeoFilterValue>, GeoFilterUpdater] {
  const geoFilter = useSelector(getGeoFilter);
  const dispatch = useDispatch();
  const updateGeoFilter = useCallback((type, value) => {
    const nextGeoFilter: CustomFilter = {
      ...geoFilter,
      settings: _.isNumber(type)
        ? { type, value } : undefined
    };
    updateFilterDefinition(nextGeoFilter)(dispatch);
  }, [geoFilter])

  return [
    geoFilter,
    updateGeoFilter
  ];
}
