import * as React from "react";
import * as ol from "ol";
import {connect, ConnectedProps, useSelector} from "react-redux";
import {
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
  useContext,
  useEffect,
  useRef,
  MutableRefObject
} from "react";
import {useInjection} from "inversify-react";
import ResizeObserver from "resize-observer-polyfill";
import BaseLayer from "ol/layer/Base";
import {getAccidentLayerConfig} from "../../../store/selectors/user";
import GlobalState from "../../../store/interfaces/states/GlobalState";
import Injectable from "../../../injection/injectable";
import {injectReactComponent} from "../../../utils/injectionUtilities";
import {DEFAULT_MAX_ZOOM} from "../../../constants/defaults";
import useGeoJSONLayers from "./useGeoJSONLayers";
import Config from "../../../interfaces/Config";
import {useBaseLayers} from "./useMap";
import useGeoFilter from "./useGeoFilter";
import useDataLayer from "./useDataLayer";

// @ts-ignore
import className from "../../../assets/scss/components/map.scss";
import _ from "lodash";
import {Feature} from "ol";
import {Geometry} from "ol/geom";
import {TEXT_ALIGN} from "ol/render/canvas/TextBuilder";
import top = TEXT_ALIGN.top;
import {Constructor, FunctionalComponent} from "../../../constants/globalTypes";
import TabComponentProperties from "../../../interfaces/properties/TabComponentProperties";

export interface OlMapFunctionalProperties extends TabComponentProperties {
  includeFilterControl?: boolean;
  includeAccidentControl?: boolean;
  includeGradientControl?: boolean;
  includeLayerSwitcherControl?: boolean;
  includeMapFilterControl?: boolean;
}

export interface MapProperties {
  maxZoom?: number,
  layers?: BaseLayer[];
  topLeftControls?: ReactElement[];
  topRightControls?: ReactElement[];
  bottomLeftControls?: ReactElement[];
  clickToClear?: boolean;
  mapRef?: MutableRefObject<ol.Map|undefined>;
}


export const MapContext = React.createContext<ol.Map|undefined>(undefined);

export function useMap() {
  return useContext(MapContext) as ol.Map;
}

function addResizeObserver(
  element: HTMLElement,
  map: ol.Map
) {
  const resizeCallback = () => map.updateSize();
  new ResizeObserver(resizeCallback).observe(element);
}

function addClickToClear(
  map: ol.Map,
  updateGeoFilter: () => void
) {
  map.on('click', ({ pixel }) => {
    const clickedOnEmpty = map.getFeaturesAtPixel(pixel, { hitTolerance: 5 })
      .filter(feature => !feature.getProperties().ignore || false).length === 0;
    if (clickedOnEmpty) {
      updateGeoFilter();
    }
  });
}


function Map(
  props: PropsWithChildren<MapProperties>
) {

  const {
    maxZoom = DEFAULT_MAX_ZOOM,
    topLeftControls,
    topRightControls,
    bottomLeftControls,
    layers,
    mapRef,
    clickToClear = false
  } = props;

  const [, updateGeoFilter] = useGeoFilter();
  const [mapTarget, setMapTarget] = useState<HTMLDivElement|null>(null);
  const map = useMemo(() => {
    if (mapTarget) {
      const map = new ol.Map({
        controls: [],
        target: mapTarget,
        view: new ol.View({
          center: [ 1543064.7758262376, 12342707.078435263 ],
          zoom: 3.659,
          maxZoom: maxZoom + 1
        })
      });
      if (mapRef) {
        mapRef.current = map;
      }
      addResizeObserver(mapTarget, map);
      if (clickToClear) {
        addClickToClear(map, updateGeoFilter)
      }
      return map;
    }
  }, [mapTarget]);

  useEffect(() => {
    if (map && layers) {
      const oldLayers = map.getLayers().getArray();
      layers
        .filter(layer => !oldLayers.includes(layer))
        .forEach(layer => {
          map.addLayer(layer);
        });
      map.changed();
    }
  }, [map, layers]);

  return (
    <MapContext.Provider value={map}>
      <div
        ref={setMapTarget}
        className={className.map}>
        <div className={className.buttonContainer}>
          <div className={className.topLeft}>
            {
              topLeftControls?.map((control, index) => {
                return (
                  <div key={index}>
                    { control }
                  </div>
                )
              })
            }
          </div>
          <div className={className.topRight}>
            {
              topRightControls?.map((control, index) => {
                return (
                  <div key={index}>
                    { control }
                  </div>
                )
              })
            }
          </div>
          <div className={className.bottomLeft}>
            {
              bottomLeftControls?.map((control, index) => {
                return (
                  <div key={index}>
                    { control }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </MapContext.Provider>
  );
}

function useControls(
    props: OlMapFunctionalProperties,
    AccidentControl: FunctionalComponent,
    GradientControl: FunctionalComponent
) {

  const MapFilterControl = injectReactComponent(Injectable.MapFilterControl);
  const LayerSwitcherControl = injectReactComponent(Injectable.LayerSwitcherControl);
  const FilterControl = injectReactComponent(Injectable.FilterListControl);

  const {
    includeLayerSwitcherControl = true,
    includeMapFilterControl = true,
    includeAccidentControl = true,
    includeGradientControl = true,
    includeFilterControl = true,
  } = props

  const topRightControls: ReactElement[] = []

  if (includeMapFilterControl) {
    topRightControls.push(<MapFilterControl />)
  }
  if (includeLayerSwitcherControl) {
    topRightControls.push(<LayerSwitcherControl />)
  }
  if (includeAccidentControl) {
    topRightControls.push(<AccidentControl />)
  }

  const topLeftControls: ReactElement[] = []

  if (includeFilterControl) {
    topLeftControls.push(<FilterControl />)
  }

  const bottomLeftControls: ReactElement[] = []

  if (includeGradientControl) {
    bottomLeftControls.push(<GradientControl />)
  }

  return {
    topLeftControls,
    topRightControls,
    bottomLeftControls
  }
}

function OlMainMap(
  props: OlMapFunctionalProperties & PropsFromRedux
) {
  const {
    nightMode,
      includeFilterControl = true
  } = props;
  const {
    geoJSONLayerConfigs = []
  } = useInjection<Config>(Injectable.Config);
  const accidentLayerConfig = useSelector(getAccidentLayerConfig);

  const map = useRef<ol.Map>();
  const baseLayers = useBaseLayers(nightMode);
  const geoJsonLayers = useGeoJSONLayers(geoJSONLayerConfigs, nightMode);
  const [accidentLayer, AccidentControl, GradientControl] = useDataLayer(accidentLayerConfig, map.current);

  const layers = geoJsonLayers.concat(baseLayers).concat(accidentLayer);

  const {
    topRightControls,
    topLeftControls,
    bottomLeftControls
  } = useControls(props, AccidentControl, GradientControl)

  return (
      <Map
        mapRef={map}
        topRightControls={topRightControls}
        topLeftControls={topLeftControls}
        bottomLeftControls={bottomLeftControls}
        layers={layers}
        clickToClear
      />
  )
}

function mapStateToProps(state: GlobalState) {
  const {
    nightMode = false
  } = state.user.data;
  return {
    nightMode
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(OlMainMap);

