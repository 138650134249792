import React, {useMemo} from "react";
import {Modal} from "antd";
import Diagram from "./interfaces/Diagram";
import {DataDimension} from "./constants/types";
import {DiagramConfigurationRenderer} from "./DiagramConfigurationRenderer";
import {DiagramPreviewArea} from "./DiagramPreviewArea";
import {useDiagramEditorContext} from "./index";

// @ts-ignore
import className from "../../assets/scss/components/diagrameditor.scss";

export interface DiagramEditorModalProperties {
    open?: boolean;
    diagram?: Diagram<any>;
    dataDimensions: DataDimension[];
    initialData?: any;
}

export default function DiagramEditor(props: DiagramEditorModalProperties) {

    const {
        open,
        diagram,
    } = props;

    const {
        onSave,
        onClose,
        isDiagramValid,
    } = useDiagramEditorContext()

    return (
        <Modal
            title={diagram?.title}
            width={1200}
            open={open}
            onOk={() => onSave()}
            okButtonProps={{
                disabled: !isDiagramValid
            }}
            onCancel={onClose}
            closable={false}
            destroyOnClose={true}>
            <div className={className.modalContent}>
                <div className={className.formContainer}>
                    {
                        diagram && <DiagramConfigurationRenderer diagram={diagram} />
                    }
                </div>
                <div className={className.previewContainer}>
                    <DiagramPreviewArea
                        open={open}
                        diagram={diagram} />
                </div>
            </div>
        </Modal>
    );

}