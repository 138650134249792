import NodePlugin from "../../../interface/NodePlugin";
import {DataPoint} from "../../../../../interfaces/models/DataPoint";
import {Edge, Handle, NodeProps, Position} from "reactflow";
import {Context} from "vm";
import React, {memo} from "react";
import NodeMenu from "../../NodeMenu";
import {MathOperation} from "./enums";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDivide, faMinus, faMultiply, faPlus} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";
import {checkHandleConnectionCount, isNumberArray} from "../../../utilities/helperUtilities";
import {NodeCategory, NodeType, OutputType} from "../../../enums";
import {MathNodeData} from "./interfaces";
import {MenuItem} from "../../../types";

// @ts-ignore
import className from "../../../../../assets/scss/components/selectoreditor.scss";
import {MATH_IN_A, MATH_IN_B, MATH_OUT} from "./constants";

export default class MathNode implements NodePlugin<DataPoint, MathNodeData> {
    public renderer(): React.FC<NodeProps<MathNodeData>> {
        return memo((props: NodeProps<MathNodeData>) => {
            const { data, isConnectable } = props;
            const { operation } = data;

            return (
                <div className={className.mathNode}>
                    <NodeMenu />
                    <Handle
                        id={MATH_IN_A}
                        type="target"
                        position={Position.Left}
                        style={{ background: 'black', top: 12 }}
                        isConnectable={isConnectable} />
                    <Handle
                        id={MATH_IN_B}
                        type="target"
                        position={Position.Left}
                        style={{ background: 'black', top: 36 }}
                        isConnectable={isConnectable} />
                    <div className={className.label}>
                        { this.getOperationIcon(operation) }
                    </div>
                    <Handle
                        type="source"
                        position={Position.Right}
                        id={MATH_OUT}
                        style={{ background: 'black' }}
                        isConnectable={isConnectable} />
                </div>
            );
        });
    }

    public execute(
        dataPoint: DataPoint,
        inputValues: Record<string, any>,
        nodeData: MathNodeData,
        context: Context
    ): Record<string, any> {
        const { operation } = nodeData;
        const { inA, inB } = inputValues;
        if ((!_.isNumber(inA) && !isNumberArray(inA)) || !_.isNumber(inB)) {
            context.reportError('some input is not a number');
            return { out: 0 };
        } else {
            switch (operation) {
                case MathOperation.Add:
                    return { out: inA + inB };
                case MathOperation.Subtract:
                    return { out: inA - inB };
                case MathOperation.Divide: {
                    if (inB === 0) {
                        context.reportError('division by zero');
                        return { out: 0 };
                    } else {
                        return { out: inA / inB };
                    }
                }
                case MathOperation.Multiply:
                    return { out: inA * inB };
                case MathOperation.Product:
                    return {
                        out: (inA as number[]).reduce((a,b) => a * b, 1)
                    };
                case MathOperation.Sum:
                    return {
                        out: (inA as number[]).reduce((a,b) => a + b, 0)
                    };
            }
        }
    }

    public validateConnection(
        handle: string,
        node: any,
        incomingEdges: Edge[]
    ): boolean {
        return (handle === MATH_IN_A || handle === MATH_IN_B)
            && node.data.outputType === OutputType.Numerical
            && incomingEdges.length === 0;
    }

    public validateNode(
        nodeData: MathNodeData,
        incomingEdges: Edge[],
        outgoingEdges: Edge[]
    ): boolean {
        return checkHandleConnectionCount(MATH_IN_A, 1, 1, incomingEdges)
            && checkHandleConnectionCount(MATH_IN_B, 1, 1, incomingEdges)
            && checkHandleConnectionCount(MATH_OUT, 1, Infinity, outgoingEdges);
    }

    public type(): NodeType {
        return NodeType.Math;
    }
    public category(): NodeCategory {
        return NodeCategory.Math;
    }

    public menuItems(): MenuItem<MathNodeData>[] {
        return [
            {
                type: NodeType.Math,
                label: <FontAwesomeIcon icon={faPlus} />,
                data: { operation: MathOperation.Add, outputType: OutputType.Numerical }
            },
            {
                type: NodeType.Math,
                label: <FontAwesomeIcon icon={faMinus} />,
                data: { operation: MathOperation.Subtract, outputType: OutputType.Numerical }
            },
            {
                type: NodeType.Math,
                label: <FontAwesomeIcon icon={faMultiply} />,
                data: { operation: MathOperation.Multiply, outputType: OutputType.Numerical }
            },
            {
                type: NodeType.Math,
                label: <FontAwesomeIcon icon={faDivide} />,
                data: { operation: MathOperation.Divide, outputType: OutputType.Numerical }
            }
        ];
    }


    private getOperationIcon(operation: MathOperation) {
        switch (operation) {
            case MathOperation.Add:
                return <FontAwesomeIcon icon={faPlus as IconProp} />;
            case MathOperation.Subtract:
                return <FontAwesomeIcon icon={faMinus as IconProp} />;
            case MathOperation.Multiply:
                return <FontAwesomeIcon icon={faMultiply as IconProp} />;
            case MathOperation.Divide:
                return <FontAwesomeIcon icon={faDivide as IconProp} />;
        }
    }

}