import React, {useEffect} from "react";
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {RouterPath} from "./constants/enums";
import { history } from "./store"
import Error from "./pages/error";
import Models from "./pages/models";
import Statistics from "./pages/statistics";
import "./assets/scss/main.scss";
import "./assets/scss/resizer.scss";
import {analytics} from "./components/analytics";
import {ConfigProvider} from "antd";
import {defaultTheme} from "./constants/theme";
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs from "dayjs";
import { theme } from 'antd';
import {useSelector} from "react-redux";
import {getNightMode} from "./store/selectors/user";
import Login from "./pages/login";
import DataExtractor from "./pages/dataExtractor";
import {useInjection} from "inversify-react";
import {DataPointInfoModalComponent, FunctionalComponent} from "./constants/globalTypes";
import Injectable from "./injection/injectable";
import Home from "./pages/home";
import RiskModel from "./pages/RiskModel";
const { defaultAlgorithm, darkAlgorithm, compactAlgorithm } = theme;

import 'reactflow/dist/style.css';
import Comparison from "./pages/Comparison";
import CheckAuthWrapper from "./components/ui/CheckAuthWrapper";

dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

function App() {
  const nightMode = useSelector(getNightMode);
  useEffect(() => {
    analytics.page({
      route: RouterPath.Prediction
    }).catch(console.error);
  });
  const SharedLayout = useInjection<DataPointInfoModalComponent>(Injectable.SharedLayout);
  const DataSourceProvider = useInjection<FunctionalComponent>(Injectable.DataSourceProvider);

  return (
    <ConfigProvider
      theme={{
        token: { ...defaultTheme },
        algorithm: [
          nightMode ? darkAlgorithm : defaultAlgorithm,
          compactAlgorithm
        ]
      }}>
      <ConnectedRouter history={history}>
        <Switch>
          <CheckAuthWrapper>
            <DataSourceProvider active={true}>
              <SharedLayout>
                <Route exact={true} path={RouterPath.Home} component={Home} />
                <Route exact={true} path={RouterPath.Statistics} component={Statistics} />
                <Route exact={true} path={RouterPath.RiskModel} component={RiskModel} />
                <Route exact={true} path={RouterPath.Comparison} component={Comparison} />
                <Route exact={true} path={RouterPath.Models} component={Models} />
                <Route exact={true} path={RouterPath.Error} component={Error} />
                <Route exact={true} path={RouterPath.Login} component={Login} />
                <Route exact={true} path={RouterPath.DataExtractor} component={DataExtractor} />
                <Redirect to={RouterPath.Home} />
              </SharedLayout>
            </DataSourceProvider>
          </CheckAuthWrapper>
        </Switch>
      </ConnectedRouter>
    </ConfigProvider>
  );
}

export default App;
