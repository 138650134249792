import {BaseMixin, CompositeChart, CoordinateGridMixin, HeatMap} from "dc";
import _ from "lodash";

export function cartesian(...a: any[]) {
    return a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())));
}

export function disposeGroup(chart: BaseMixin<any>) {
    chart.group().dispose();
}

export function isCoordinateMixin(chart: BaseMixin<any>): chart is CoordinateGridMixin<any> {
    return _.isFunction((chart as any).xAxis);
}

export function isHeatmap(chart: BaseMixin<any>): chart is HeatMap {
    return _.isFunction((chart as any).xBorderRadius);
}

export function isCompositeChart(chart: BaseMixin<any>): chart is CompositeChart {
    return _.isFunction((chart as any).compose);
}