import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import * as dc from "dc";
import {DimensionId, FilterId} from "../../constants/enums";
import {addFilter, updateFilterDefinition} from "../../store/actions/data";
import {analytics, AnalyticsEvent} from "../analytics";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import Search from "antd/es/input/Search";
import _ from "lodash";
import {isDefinedFilter, isFilter} from "../../utils/validationUtilities";
import {DEFAULT_DESCRIPTION_FILTER} from "../../constants/defaults";
import {DefinedFilter} from "../../constants/globalTypes";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faGear} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {PopoverWrapperProperties} from "../../interfaces/properties/PopoverWrapperProperties";
import {injectReactComponent} from "../../utils/injectionUtilities";
import Injectable from "../../injection/injectable";
import className from "../../assets/scss/components/infomodal.scss";
import {setActiveDimensionIdsInDataCard} from "../../store/actions/user";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import {useInjection} from "inversify-react";
import Config, {DimensionType} from "../../interfaces/Config";
import {IncludeColumnsModalProperties} from "../ui/IncludeColumnsModal";
import {downloadDataAsExcelFile} from "../../utils/excelUtilities";

function AntDTextFilter(props: PropsFromRedux) {
  const {
    descriptionFilter,
    updateFilterDefinition,
    activeDimensions,
    setActiveDimensionIdsInDataCard,
    filters
  } = props;
  const {
    comparatorValue
  } = descriptionFilter;

  const config = useInjection<Config>(Injectable.Config);
  const { dataSource } = useDataSourceContext();

  const [searchText, setSearchText] = useState<string>(descriptionFilter?.comparatorValue as string || "")
  const [editing, setEditing] = useState(false);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value), [setSearchText]);
  const onSearch = useCallback((comparatorValue: string) => {
    analytics.track(AnalyticsEvent.FilterChanged, {
      name: DimensionId.Description,
      filtered: comparatorValue
    }).catch(console.error);
    updateFilterDefinition({
      ...descriptionFilter,
      comparatorValue
    });
    dc.redrawAll();
  }, [descriptionFilter]);
  useEffect(() => {
    if (!comparatorValue || _.isString(comparatorValue) && comparatorValue.length === 0) {
      setSearchText("");
    }
  }, [comparatorValue]);

  const PopoverWrapper = injectReactComponent<PopoverWrapperProperties>(Injectable.PopoverWrapper);
  const IncludeColumnsModal = injectReactComponent<IncludeColumnsModalProperties>(Injectable.IncludeColumnsModal);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: 8
    }}>
      <Search
        placeholder="Søketekst"
        multiple={true}
        allowClear
        size="large"
        value={searchText}
        onChange={onChange}
        onSearch={onSearch} />
      <Button
        type="default"
        size="large"
        onClick={() => setEditing(true)}
        icon={
          <FontAwesomeIcon
            className={className.icon}
            icon={faGear as IconProp} />
        } />
      <PopoverWrapper
        element={
          <Button
            type="default"
            icon={<FontAwesomeIcon icon={faDownload as IconProp} />}
            size="large" />
        }
        tooltipTitle="Last ned data"
        tooltipPlacement="topRight"
        popoverItems={[
          {
            label: "Alle datapunkter",
            onClick: () => {
              const allData = dataSource.crossfilter.all();
              downloadDataAsExcelFile(allData, config, [], [DimensionType.Coordinates]);
            }
          },
          {
            label: "Filtrerte datapunkter",
            onClick: () => {
              const filteredData = dataSource.crossfilter.allFiltered();
              downloadDataAsExcelFile(filteredData, config, filters, [DimensionType.Coordinates]);
            }
          },
        ]}/>
      <IncludeColumnsModal
        show={editing}
        activeDimensionIds={activeDimensions}
        onClose={() => setEditing(false)}
        onDimensionsChange={
          nextActiveDimensions => setActiveDimensionIdsInDataCard(nextActiveDimensions)
        } />
    </div>
  );
}

function mapStateToProps(state: GlobalState) {
  const { filters } = state.data;
  const {
    activeDimensionIdsInDataCard: activeDimensions = []
  } = state.user.data;
  const descriptionFilter = filters
    .filter(f => isDefinedFilter(f) && f.dimensionId === FilterId.Description)
    .pop() as DefinedFilter || DEFAULT_DESCRIPTION_FILTER;
  return {
    descriptionFilter,
    activeDimensions,
    filters
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    addFilter,
    updateFilterDefinition,
    setActiveDimensionIdsInDataCard
  }, dispatch);
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDTextFilter);
