import React, {useCallback, useMemo, useState} from "react";
import {Button, Dropdown, Input, Popover, Typography} from "antd";
import {FolderOpenOutlined, SaveOutlined} from "@ant-design/icons";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {connect, ConnectedProps} from "react-redux";

// @ts-ignore
import className from "../../assets/scss/filterView.scss";
import {bindActionCreators, Dispatch} from "redux";
import {addFilter, setFilterDefinitionsFromSavedFilter} from "../../store/actions/data";
import {addSavedFilter} from "../../store/actions/user";

interface SaveSnapshotProps {
    buttonType?: "link" | "text" | "ghost" | "primary" | "default" | "dashed" | undefined;
    buttonSize?: 'small' | 'middle' | 'large' | undefined
}

function SaveSnapshot(
    {
        filters,
        savedFilters = [],
        buttonType = 'default',
        buttonSize = 'large',
        addSavedFilter
    }: SaveSnapshotProps & PropsFromRedux
) {
    const [name, setName] = useState('')
    const [open, setOpen] = useState(false)

    const content = useMemo(() => (
        <div className={className.saveSnapshot}>
            <Typography.Title level={4}>
                Lagre snapshot
            </Typography.Title>
            <Input
                value={name}
                placeholder="Snapshot navn"
                onChange={e => setName(e.target.value)} />
            <div className={className.buttonContainer}>
                <Button
                    disabled={name.length === 0}
                    onClick={() => {
                        addSavedFilter(name, filters)
                        setOpen(false);
                    }}
                    icon={<SaveOutlined />}>Lagre</Button>
            </div>
        </div>
    ), [name, setName])

    return (
        <>
            <Popover
                onVisibleChange={() => setOpen(false)}
                open={open}
                placement="bottomRight"
                content={content}>
                <Button
                    onClick={() => setOpen(true)}
                    size={buttonSize}
                    type={buttonType}
                    icon={<SaveOutlined />} />
            </Popover>
            <Dropdown
                disabled={savedFilters.length === 0}
                menu={{
                    items: savedFilters.map(({ name: label }, key) => ({
                        key,
                        label,
                        onClick: () => {
                            const savedFilter = savedFilters[key];
                            setFilterDefinitionsFromSavedFilter(savedFilter);
                        }
                    }))
                }}
                placement="bottomRight">
                <Button
                    disabled={savedFilters.length === 0}
                    size={buttonSize}
                    type={buttonType}
                    icon={<FolderOpenOutlined />} />
            </Dropdown>
        </>
    )
}

function mapStateToProps(state: GlobalState) {
    const {
        data: {
            filters: filters
        },
        user: {
            data: {
                savedFilters
            }
        }
    } = state;

    return { filters, savedFilters };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        addFilter,
        addSavedFilter,
        setFilterDefinitionsFromSavedFilter
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(SaveSnapshot);
