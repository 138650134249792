import {CaseData, DefaultCaseData} from "./types";

export const DEFAULT_DEFAULT_CASE: DefaultCaseData = {
    isDefaultCase: true,
    outputValue: 1
}

export const DEFAULT_CASE: CaseData = {
    caseValues: [],
    outputValue: 1
}

// IMPORTANT, DO NOT CHANGE!: Changing this will invalidate userdata that has been created while using these values.
export const SWITCH_IN = 'in';
export const SWITCH_OUT = 'out';
export const SWITCH_CASE_INPUT = 'case-input';