import {ComparatorValue, CustomFilter, DateFilterSettings, DefinedFilter} from "../../constants/globalTypes";
import {FilterType, Operator} from "../../constants/enums";
import React, {useMemo} from "react";
import {DatePicker, InputNumber, Select, Tag, theme, Typography} from "antd";
import {isMultipleOperator, operatorsForType} from "../../utils/filterUtilities";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import Config, {DimensionWithSelector} from "../../interfaces/Config";
import {bindActionCreators} from "redux";
import {filterDefinitionActions} from "../../store/actions/data";
import {useDispatch, useSelector} from "react-redux";
import {useDimension} from "../../utils/hooks";
import dayjs from "dayjs";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import {isCustomFilter, isDefinedFilter} from "../../utils/validationUtilities";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


// @ts-ignore
import className from "../../assets/scss/filterView.scss";
import {monthOptions} from "../../constants/options";

export interface FilterRendererProperties {
  filters: DefinedFilter[];
  onChange: (filter: DefinedFilter|CustomFilter) => void;
}

interface FilterInputProperties {
  filter: DefinedFilter;
  onComparatorValueChange: (filter: DefinedFilter, value: ComparatorValue|ComparatorValue[]) => void;
  dimension?: DimensionWithSelector<DataPoint>;
}

interface DateFilterInput {
  filter: CustomFilter<DateFilterSettings>;
  onChange: (filter: DefinedFilter|CustomFilter) => void;
  onDelete: () => void;
}

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  .row {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    .heading {
      width: 100%;
      display: flex;
      flex-direction: row;
      :last-child {
        margin-top: 0 !important;
      }
      .title-container {
        display: flex;
        flex: 1;
      }
    }
  }
`

interface DeleteFilterButtonProps {
  onDelete: () => void
}

const DeleteFilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-left: 12px;
  margin-top: 6px;
  svg {
    cursor: pointer;
  }
`

function DeleteFilterButton({ onDelete }: DeleteFilterButtonProps) {
  const { token } = theme.useToken()
  return (
      <DeleteFilterButtonContainer>
        <FontAwesomeIcon
            style={{
              color: token.colorPrimary
            }}
            onClick={onDelete}
            className={className.icon}
            icon={faTrashAlt as IconProp} />
      </DeleteFilterButtonContainer>
  )
}

function DateInput(props: DateFilterInput) {

  const {
    filter,
    onChange,
    onDelete
  } = props;
  const {
    settings = {}
  } = filter;
  const {
    startDate,
    endDate,
    months = []
  } = settings as Partial<DateFilterSettings>

  const value = useMemo(() => {
    if (startDate && endDate) {
      const dayJsStartDate = dayjs.unix(startDate);
      const dayJsEndDate = dayjs.unix(endDate);
      if (dayJsStartDate.isValid() && dayJsEndDate.isValid()) {
        return [dayJsStartDate, dayJsEndDate];
      }
    }
    return null;
  }, [startDate, endDate]);

  return (
      <DateInputContainer>
        <div className="row">
          <div className="heading">
            <div className="title-container">
              <Typography.Text strong>Tidsrom</Typography.Text>
            </div>
            <DeleteFilterButton onDelete={onDelete} />
          </div>
          <DatePicker.RangePicker
              value={value as any}
              allowClear={true}
              onChange={(value) => onChange({
                ...filter,
                settings: {
                  ...settings,
                  startDate: value && value[0] ? value[0].unix() : undefined,
                  endDate: value && value[1] ? value[1].unix() : undefined
                }
              })}
              style={{ width: '100%' }} />
        </div>
        <div className="row">
          <Typography.Text strong>Måneder</Typography.Text>
          <Select
              style={{ width: '100%' }}
              value={months}
              onChange={(months) => onChange({
                ...filter,
                settings: {...settings, months}
              })}
              mode="multiple"
              options={monthOptions} />
        </div>
      </DateInputContainer>
  );
}

function CategoricalInput(props: FilterInputProperties) {
  const config = useInjection<Config>(Injectable.Config);
  const { filter, onComparatorValueChange } = props;
  const dimension = useDimension(filter.dimensionId);
  const { dataSource } = useDataSourceContext();
  const {
    redactionString,
    redactionInt
  } = useSelector((state: GlobalState) => state.data);
  const optionValues = dimension
      ? dataSource.getUniqueValuesForDimension(dimension).filter(({ label, value }) => value !== redactionString && value !== redactionInt)
      : [];
  return (
    <Select
      maxTagCount={2}
      maxTagTextLength={config.maxLengthFilterValue}
      mode={isMultipleOperator(filter.operator) ? "multiple" : undefined}
      value={filter.comparatorValue as any}
      optionFilterProp="children"
      options={optionValues}
      onChange={value => onComparatorValueChange(filter, value)}
      style={{ width: '100%' }}
      dropdownMatchSelectWidth={false}>
    </Select>
  );
}

function NumericalInput(props: FilterInputProperties) {
  const {
    filter,
    onComparatorValueChange,
    dimension
  } = props;
  return (
    <InputNumber
      className={className.numberInput}
      value={filter.comparatorValue as any}
      onChange={value => onComparatorValueChange(filter, value)}
      addonAfter={dimension?.unit} />
  );
}

export function FilterRenderer(
  props: FilterRendererProperties
) {
  const {
    filters,
    onChange
  } = props;
  const { type, dimensionId } = filters[0] || {};
  const dimension = useDimension(dimensionId);
  const operators = useMemo(() => operatorsForType(type), [type]);
  const updateOperator = (filter: DefinedFilter, operator: Operator) => onChange({ ...filter, operator });
  const updateComparatorValue = (filter: DefinedFilter, comparatorValue: ComparatorValue|ComparatorValue[]) =>
    onChange({ ...filter, comparatorValue });
  const dispatch = useDispatch();
  const { deleteFilterDefinition } = useMemo(() => bindActionCreators(filterDefinitionActions, dispatch), []);

  const tagElements = filters.map((filter: DefinedFilter|CustomFilter, index: number) => (
    <Tag
      key={index}
      className={className.filterContainer}
      closable={false}>
      <div className={className.filter}>
        {
          isDefinedFilter(filter) && (
              <>
                <div>
                  <Select
                      value={filter.operator}
                      optionFilterProp="children"
                      className={className.operator}
                      onChange={value => updateOperator(filter, value)}>
                    {
                      operators.map(([value, label], index) =>
                          <Select.Option key={index} value={value}>{label}</Select.Option>)
                    }
                  </Select>
                </div>
                {
                    filter.type === FilterType.Categorical && <CategoricalInput
                        filter={filter}
                        dimension={dimension}
                        onComparatorValueChange={updateComparatorValue} />
                }
                {
                    filter.type === FilterType.Numerical && <NumericalInput
                        filter={filter}
                        dimension={dimension}
                        onComparatorValueChange={updateComparatorValue} />
                }
                <DeleteFilterButton onDelete={() => deleteFilterDefinition(filter)} />
              </>
          )
        }
        {
          isCustomFilter(filter) && (
              <>
                {
                    filter.type === FilterType.Date && (
                        <DateInput
                            onDelete={() => deleteFilterDefinition(filter)}
                            filter={filter}
                            onChange={onChange} />
                    )
                }
              </>
          )
        }
      </div>
    </Tag>
  ));
  const containerClassName = filters.length > 1 ? className.moreThanOneFilter : undefined;
  return (
    <div
      className={containerClassName}>
      <Typography.Title
        level={5}
        className={className.filterLabel}>
        {dimension?.label}
      </Typography.Title>
      <div className={className.tags}>
        {
          tagElements
        }
      </div>
    </div>
  );
}
