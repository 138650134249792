import React, {useRef, useState} from "react";
import {Component, Trend} from "../../constants/globalTypes";
import {injectComponentRenderer} from "../../utils/injectionUtilities";
import {useResizeObserver} from "../../utils/hooks";
import {ComponentType} from "../../constants/enums";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {SettingOutlined} from "@ant-design/icons";
import {theme} from "antd";
import {useSelector} from "react-redux";
import {getNightMode} from "../../store/selectors/user";

import className from "../../assets/scss/components/filtergrids.scss";
import {useImmer} from "use-immer";

export interface ComponentRendererContainerProperties {
    component: Component;
    onEdit: () => void;
    onDelete: () => void;
}

const ComponentRendererTitle = (
  props: ComponentRendererContainerProperties
) => {
  const {
    component: {
      data
    },
    onEdit,
    onDelete
  } = props;
  const [hover, setHover] = useImmer({
      settings: false,
      trash: false
  });
  const { token } = theme.useToken();
  return (
    <div>
      <div
        style={{
            borderBottomColor: token.colorBorder,
        }}
        className={className.title}>
          <div className={className.draggableHandle}>
              <FontAwesomeIcon
                  style={{
                      color: token.colorBorder,
                  }}
                  className={className.movableIcon}
                  icon={faGripVertical as IconProp} />
              <div className={className.text}>{data?.title}</div>
          </div>
          <SettingOutlined
              onMouseEnter={() => setHover(draft => ({ ...draft, settings: true }))}
              onMouseLeave={() => setHover(draft => ({ ...draft, settings: false }))}
              style={{
                  color: hover.settings ? token.colorPrimary : token.colorText
              }}
              onClick={onEdit}
              className={className.icon} />
          <FontAwesomeIcon
              onMouseEnter={() => setHover(draft => ({ ...draft, trash: true }))}
              onMouseLeave={() => setHover(draft => ({ ...draft, trash: false }))}
              style={{
                  color: hover.trash ? token.colorPrimary : token.colorText
              }}
              onClick={onDelete}
              className={className.icon}
              icon={faTrashAlt as IconProp} />
      </div>
    </div>
  )
}

const ComponentRendererOverlay = (
  props: ComponentRendererContainerProperties
) => {
  const {
    onEdit
  } = props;

  return (
    <div className={`${className.full} ${className.draggableHandle}`}>
      <div className={className.buttonContainer}>
        <SettingOutlined
          onClick={onEdit}
          className={className.icon} />
      </div>
    </div>
  );
}


export default function ComponentRendererContainer(
  props: ComponentRendererContainerProperties
) {
  const {
    data,
    type,
  } = props.component;
  const ref = useRef(null);
  const { width, height } = useResizeObserver(ref);
  const { token } = theme.useToken();
  const nightMode = useSelector(getNightMode);

  const ComponentRendererInstance = injectComponentRenderer(type);
  const withTitle = type !== ComponentType.Statistic;
  return (
    <div
      style={{
        background: nightMode ? token.colorBgElevated : token.colorFillSecondary,
        boxShadow: token.boxShadow
      }}
      className={`${className.gridItem} ${nightMode ? className.night : ''}`}>
      {
        withTitle
          ? <ComponentRendererTitle {...props} />
          : <ComponentRendererOverlay {...props} />
      }
      <div
        ref={ref}
        style={{ margin: withTitle ? 20 : 0 }}
        className={className.gridItemContent}>
        {
          ComponentRendererInstance && (
            <ComponentRendererInstance
              isEditingExistingComponent={false}
              width={width}
              height={height}
              data={data as Trend} />
          )
        }
      </div>
    </div>
  )
}
