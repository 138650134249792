import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {Button, Form, Modal, Select, theme, Typography} from "antd";
import _ from "lodash";
import {useImmer} from "use-immer";
import SplitPane from "react-split-pane";
import Config, {DimensionType, DimensionWithSelector} from "../../interfaces/Config";
import {useActions, useDimensionOptions} from "../../utils/hooks";
import {CustomFilter, DefinedFilter} from "../../constants/globalTypes";
import {
  getDefaultOperatorForFilterType,
  getFilterTypeFromDimensionType,
  groupFiltersWithSameDimension,
  isFilterValid,
  shouldIncludeInFilterBar
} from "../../utils/filterUtilities";
import {injectReactComponent} from "../../utils/injectionUtilities";
import Injectable from "../../injection/injectable";
import {FilterRendererProperties} from "./FilterRenderer";
import {v4 as uuid} from "uuid";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators} from "redux";
import {useDispatch, useSelector} from "react-redux";
import {filterDefinitionActions} from "../../store/actions/data";
import {isCustomFilter} from "../../utils/validationUtilities";
import {useInjection} from "inversify-react";
import {setDragging} from "../../store/actions/layout";
import {setFilterBarSize} from "../../store/actions/user";
import {DataPoint} from "../../interfaces/models/DataPoint";
import SaveSnapshot from "./SaveSnapshot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

import className from "../../assets/scss/filterView.scss";

export interface FilterViewProperties {
  children: ReactElement;
  minSize?: number
}

interface AddFilterModalProperties {
  show: boolean;
  onHide: () => void;
  onOk: (filter: DefinedFilter) => void;
}

function AddFilterModal(
  props: AddFilterModalProperties
) {
  const {
    show,
    onHide,
    onOk
  } = props;
  const dimensionOptions = useDimensionOptions(
    {
      notType: [DimensionType.Text, DimensionType.Coordinates],
      onlyFilterable: true
    });
  const [currentDimension, setCurrentDimension] = useState<DimensionWithSelector<DataPoint>|null>();
  const [filter, updateFilter] = useImmer<Partial<DefinedFilter>|Partial<CustomFilter>>({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentDimension && !_.isUndefined(currentDimension.type)) {
      const { label } = currentDimension
      const type = getFilterTypeFromDimensionType(currentDimension.type);
      const dimensionId = currentDimension.id;
      if (type) {
        if (isCustomFilter(type)) {
          const filter: CustomFilter = {
            filterId: uuid(),
            type,
            label,
            settings: {},
            shouldIncludeInFilterView: true
          }
          updateFilter(() => filter);
        } else {
          const operator = getDefaultOperatorForFilterType(type);
          const filter: DefinedFilter = {
            filterId: uuid(),
            dimensionId,
            type,
            operator,
          }
          updateFilter(() => filter);
        }
      }
    }
  }, [currentDimension]);

  useEffect(() => setCurrentDimension(null), [show]);

  return (
    <Modal
      title="Legg til filter"
      onOk={() => {
        onOk(filter as DefinedFilter);
        onHide();
      }}
      okButtonProps={{
        disabled: !isFilterValid(filter)
      }}
      destroyOnClose={true}
      onCancel={onHide}
      open={show}
      width={400}>
      <Form
        form={form}>
        <Select
          value={currentDimension?.label}
          options={dimensionOptions}
          style={{ width: '100%' }}
          onChange={(value, options: any) => {
            setCurrentDimension(options.dimension);
          }} />
      </Form>
    </Modal>
  );
}


function FilterContainer() {
  const [showAddFilterModal, setShowAddFilterModal] = useState(false);
  const filters = useSelector(
    ({ data }: GlobalState) => data.filters.filter(shouldIncludeInFilterBar) as DefinedFilter[]);
  const dispatch = useDispatch();
  const {
    updateFilterDefinition,
    insertFilterDefinition,
    clearAllFilters
  } = useMemo(() => bindActionCreators(filterDefinitionActions, dispatch), [dispatch]);
  const FilterRenderer = injectReactComponent<FilterRendererProperties>(Injectable.FilterRenderer);
  const { token } = theme.useToken()
  return (
    <>
      <Button.Group className={className.buttonGroup}>
        <Button
          type="default"
          size="large"
          onClick={() => setShowAddFilterModal(true)}
          icon={
            <FontAwesomeIcon
                style={{ color: token.colorPrimary }}
                className={className.icon}
                icon={faPlus as IconProp} />
          }
        />
        <Button
          type="default"
          size="large"
          onClick={clearAllFilters}
          className={className.flexButton}>
          Nullstill alle
        </Button>
        <SaveSnapshot />
      </Button.Group>
      <div className={className.scroll}>
        {
          groupFiltersWithSameDimension(filters)
            .map((filters, index) =>
              <FilterRenderer key={index} onChange={updateFilterDefinition} filters={filters} />)
        }
      </div>
      <AddFilterModal
        onOk={filter => insertFilterDefinition(filter)}
        onHide={() => setShowAddFilterModal(false)}
        show={showAddFilterModal} />
    </>
  );
}

export default function FilterView(
  props: FilterViewProperties
) {
  const {
    filterBarDefaultSize,
    filterBarMinSize
  } = useInjection<Config>(Injectable.Config);
  const {
    children,
  } = props;
  const ref = useRef<SplitPane>(null);
  const { token } = theme.useToken();
  const filterExpanded = useSelector((state: GlobalState) => state.user.data.filterExpanded);
  const [width, setWidth] = useState(filterBarDefaultSize);
  const actions = useActions({ setDragging, setFilterBarSize });
  return (
    <div className={className.container} style={{
      background: token.colorBgLayout
    }}>
      {
        // @ts-ignore
        <SplitPane
          ref={ref}
          resizerStyle={{
            background: token.colorBorderSecondary
          }}
          split="vertical"
          onDragStarted={() => actions.setDragging(true)}
          onDragFinished={size => {
            if (ref) {
              actions.setDragging(false);
              actions.setFilterBarSize(size);
            }
          }}
          allowResize={filterExpanded}
          minSize={filterBarMinSize}
          size={filterExpanded ? width : 0}
          onChange={setWidth}>
          <div className={className.filter}>
            {
              filterExpanded
                ? (
                  <>
                    <div className={className.header}>
                      <Typography.Title level={4} className={className.text}>Filtre</Typography.Title>
                    </div>
                    <FilterContainer />
                  </>
                )
                : <></>
            }
          </div>
          {children}
        </SplitPane>
      }
    </div>
  );
}
