import Network from "./models/Network";
import {GroupID} from "./enums";
import {useNetworkAsFlow} from "./utilities/hooks";

export const POSSIBLE_VALUES = [
    { value: -2, label: "-2", color: "#DE5539" },
    { value: -1, label: "-1", color: "#EF9548" },
    { value: 0, label: "0", color: "#FCF467" },
    { value: 1, label: "1", color: "#D1E55F" },
    { value: 2, label: "2", color: "#4CA389" },
];

export function testNetwork() {

       const network = new Network({
        resultNodeLabel: 'Risiko for fritidsfartøy',
        possibleValues: POSSIBLE_VALUES
    });

    //Regelverk og  krav  
    const certificateNode = network.addNode({
        label: 'Sertifikatkrav',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'certificate'
    });

    const speedPortAndWaterLawNode = network.addNode({
        label: 'Fartsforskrifter og havne- og farvannsloven',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'speedPortAndWaterLaw'
    });

    const alcoholNode = network.addNode({
        label: 'Promillegrense',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'alcohol'
    });

    const technicalRequirementsNode = network.addNode({
        label: 'Tekniske krav og vedlikeholdskrav',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'technicalRequirements'
    });

    const rentalRequirementsNode = network.addNode({
        label: 'Krav til utleie',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'rentalRequirements'
    });
    
    //Regulerende aktivitet
    const trainingNode = network.addNode({
        label: 'Opplæring/Kurs/Kompetansehevende aktivitet',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.4,
        statisticsCategory: 'training'
    });

    const disseminationOfRegulationNode = network.addNode({
        label: 'Holdningsskapende arbeid og formidling av regelverk',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.6,
        statisticsCategory: 'disseminationOfRegulation'
    });

    const controlsAndPresenceNode = network.addNode({
        label: 'Kontroller og tilstedeværelse',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'controlsAndPresence'
    });

    const supervisionNode = network.addNode({
        label: 'Tilsynsaktivitet',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'supervision'
    });

     const harbourAndFairwayWorkNode = network.addNode({
        label: 'Havne- og farledsarbeid',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'harbourAndFairwayWork'
    });

    //Båtutleiere
    const rentalSafetyNode = network.addNode({
        label: 'Båtutleiers sikkerhetsstyring og -praksis',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.4,
        statisticsCategory: 'rental'
    });

    //Tilstand personer om bord
    const passengerConditionNode = network.addNode({
        label: 'Passasjerenes tilstand',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'passengerCondition'
    });

    const captainsConditionNode = network.addNode({
        label: 'Båtførerens tilstand',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.7,
        statisticsCategory: 'captainCondition'
    });

    const competencyNode = network.addNode({
        label: 'Kompetanse båtfører',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.6,
        statisticsCategory: 'Competency'
    });  

    const intermediateCaptainNode = network.addNode({
        label: 'Mellomregning båtfører',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.0,
        statisticsCategory: 'intermediateCaptain'
    });

    //Fartøy og Utsry
    const supportSystemNode = network.addNode({
        label: 'Støttesystemer og Sikkerhetsbarrierer',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.7,
        statisticsCategory: 'supportSystem'
    });  

    const designNode = network.addNode({
        label: 'Design',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.6,
        statisticsCategory: 'Design'
    });  

    const maneuvrabilityNode = network.addNode({
        label: 'Manøvrerbarhet',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.0,
        statisticsCategory: 'maneuvrability'
    });  

    const motorAndPropulsionNode = network.addNode({
        label: 'Motor og fremdriftssystem',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.7,
        statisticsCategory: 'motorAndPropulsion'
    });  

    const maintenanceNode = network.addNode({
        label: 'Vedlikehold',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'maintenance'
    });  

    //Aktivitet og adferd
    const useAreaNode = network.addNode({
        label: 'Bruksområde',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'useArea'
    });

    const passengerBehaviourNode = network.addNode({
        label: 'Passasjerenes adferd',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.2,
        statisticsCategory: 'passengerBehaviour'
    });

    const navigationAndBoatHandlingNode = network.addNode({
        label: 'Navigasjon og båtføring',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.1,
        statisticsCategory: 'navigationAndBoatHandling'
    });

    const loadingNode = network.addNode({
        label: 'Lasting',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.2,
        statisticsCategory: 'loading'
    });

    //Ytre forhold
    const trafficNode = network.addNode({
        label: 'Trafikkbilde',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 1.0,
        statisticsCategory: 'traffic'
    });

    const firesAndMarkingsNode = network.addNode({
        label: 'Fyr og merking',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'firesAndMarkings'
    });

    const speedLimitNode = network.addNode({
        label: 'Fartsgrense',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.8,
        statisticsCategory: 'speedLimit'
    });

    const pierAndQuayConditionNode = network.addNode({
        label: 'Brygge-/ kaiforhold',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.6,
        statisticsCategory: 'pierAndQuayCondition'
    });

    const fairwayNode = network.addNode({
        label: 'Farvann',
        possibleValues: POSSIBLE_VALUES,
        defaultValue: 0,
        weight: 0.3,
        statisticsCategory: 'fairway'
    });

    //Opplæring og kurs
    network.addEdge(certificateNode, trainingNode, 0.8);
    network.addEdge(speedPortAndWaterLawNode, trainingNode, 0.2);

    //Holdningskapende og formidling
    network.addEdge(speedPortAndWaterLawNode, disseminationOfRegulationNode, 0.5);
    network.addEdge(alcoholNode, disseminationOfRegulationNode, 0.5);

    //Kontroller
    network.addEdge(alcoholNode, controlsAndPresenceNode, 0.5);
    network.addEdge(technicalRequirementsNode, controlsAndPresenceNode, 0.5);

    //Tilsyn
    network.addEdge(technicalRequirementsNode, supervisionNode, 0.9);
    network.addEdge(rentalRequirementsNode, supervisionNode, 0.1);

    // Havne- og farledsarbeid
    network.addEdge(speedPortAndWaterLawNode, harbourAndFairwayWorkNode, 1.0);    
    
    //Båtutleiere sikkerhetspraksis
    network.addEdge(supervisionNode, rentalSafetyNode, 0.7);
    network.addEdge(rentalRequirementsNode, rentalSafetyNode, 0.3);

    //Passasjerenes tilstand
    network.addEdge(disseminationOfRegulationNode, passengerConditionNode, 0.3);
    network.addEdge(controlsAndPresenceNode, passengerConditionNode, 0.7);

    //Båtførerens tilstand
    network.addEdge(disseminationOfRegulationNode, captainsConditionNode, 0.1);
    network.addEdge(controlsAndPresenceNode, captainsConditionNode, 0.4);
    network.addEdge(competencyNode, captainsConditionNode, 0.5);

    // kompetanse Båtføreren
    network.addEdge(trainingNode, competencyNode, 0.65);
    network.addEdge(disseminationOfRegulationNode, competencyNode, 0.2);
    network.addEdge(controlsAndPresenceNode, competencyNode, 0.1);
    network.addEdge(rentalSafetyNode, competencyNode, 0.05);

    //Mellomregning båtfører
    network.addEdge(captainsConditionNode, intermediateCaptainNode, 0.6);
    network.addEdge(competencyNode, intermediateCaptainNode, 0.4);

    //støttesystemer og Sikkerhetsbarrierer
    network.addEdge(disseminationOfRegulationNode, supportSystemNode, 0.4);
    network.addEdge(supervisionNode, supportSystemNode, 0.3);
    network.addEdge(rentalSafetyNode, supportSystemNode, 0.05);
    network.addEdge(maintenanceNode, supportSystemNode, 0.25);

    //Design
    network.addEdge(technicalRequirementsNode, designNode, 0.8);
    network.addEdge(supervisionNode, designNode, 0.2);

    //Vedlikehold
    network.addEdge(supervisionNode, maintenanceNode, 0.8);
    network.addEdge(rentalSafetyNode, maintenanceNode, 0.2);

    //Motor og fremdriftssystem
    network.addEdge(supervisionNode, motorAndPropulsionNode, 0.1);
    network.addEdge(maintenanceNode, motorAndPropulsionNode, 0.9);
    
    //Manøvrerbarhet
    network.addEdge(designNode, maneuvrabilityNode, 0.6);
    network.addEdge(motorAndPropulsionNode, maneuvrabilityNode, 0.3);    
    network.addEdge(loadingNode, maneuvrabilityNode, 0.1);       

    //Passasjerenes adferd
    network.addEdge(useAreaNode, passengerBehaviourNode, 0.3);
    network.addEdge(passengerConditionNode, passengerBehaviourNode, 0.4);
    network.addEdge(competencyNode, passengerBehaviourNode, 0.3);

    //Lasting
    network.addEdge(competencyNode, loadingNode, 1.0);

    //Navigasjon  og båtføring
    network.addEdge(useAreaNode, navigationAndBoatHandlingNode, 0.05);
    network.addEdge(passengerBehaviourNode, navigationAndBoatHandlingNode, 0.05);
    network.addEdge(intermediateCaptainNode, navigationAndBoatHandlingNode, 0.5);
    network.addEdge(supportSystemNode, navigationAndBoatHandlingNode, 0.05);
    network.addEdge(maneuvrabilityNode, navigationAndBoatHandlingNode, 0.2);
    network.addEdge(fairwayNode, navigationAndBoatHandlingNode, 0.15);

    //Fyr og merking
    network.addEdge(harbourAndFairwayWorkNode, firesAndMarkingsNode, 1.0);

    //Fartsgrense
    network.addEdge(harbourAndFairwayWorkNode, speedLimitNode, 1.0);

    //Brygge og kaiforhold
    network.addEdge(harbourAndFairwayWorkNode, pierAndQuayConditionNode, 1.0);

    //Farvann
    network.addEdge(trafficNode, fairwayNode, 0.4);
    network.addEdge(firesAndMarkingsNode, fairwayNode, 0.3);
    network.addEdge(speedLimitNode, fairwayNode, 0.3);

    //Fritidsbåtulykke
    network.addEdge(passengerBehaviourNode, network.resultNode, 0.1);
    network.addEdge(navigationAndBoatHandlingNode, network.resultNode, 0.35);
    network.addEdge(loadingNode, network.resultNode, 0.05);
    network.addEdge(designNode, network.resultNode, 0.2);
    network.addEdge(motorAndPropulsionNode, network.resultNode, 0.2);
    network.addEdge(trafficNode, network.resultNode, 0.05);
    network.addEdge(pierAndQuayConditionNode, network.resultNode, 0.05);


    const group1 = network.addNodeGroup(
        'Regelverk og krav',
        [certificateNode, speedPortAndWaterLawNode, alcoholNode, technicalRequirementsNode, rentalRequirementsNode],
        GroupID.Rules);
    const group2 = network.addNodeGroup(
        'Regulerende aktiviteter',
        [trainingNode, disseminationOfRegulationNode, controlsAndPresenceNode, supervisionNode, harbourAndFairwayWorkNode],
        GroupID.Regulations);

    const group3 = network.addNodeGroup(
        'Båtutleiere',
        [rentalSafetyNode],
        GroupID.Rental);

    const group4 = network.addNodeGroup(
        'Tilstand personer om bord',
        [passengerConditionNode, captainsConditionNode, competencyNode,intermediateCaptainNode],
        GroupID.Person);

    const group5 = network.addNodeGroup(
        'Fartøy og utstyr',
        [supportSystemNode, maneuvrabilityNode, designNode, motorAndPropulsionNode, maintenanceNode],
        GroupID.Vessel);

    const group6 = network.addNodeGroup(
        'Aktivitet og adferd',
        [useAreaNode, passengerBehaviourNode, navigationAndBoatHandlingNode, loadingNode],
        GroupID.Activity);

    const group7 = network.addNodeGroup(
        'Ytre forhold',
        [trafficNode, firesAndMarkingsNode, speedLimitNode, pierAndQuayConditionNode,fairwayNode],
        GroupID.External);

    network.setComparisonValues({
        2017: {
            [group1.id]: -1,
            [group2.id]: -2,
            [group3.id]: -1,
            [group4.id]: 0,
            [group5.id]: 1,
            [group6.id]: -1,                        
            [group7.id]: -2,  
            result: -1
        },
        2018: {
            [group1.id]: 2,
            [group2.id]: 1,
            [group3.id]: 1,
            [group4.id]: 1,
            [group5.id]: 1,
            [group6.id]: 2,                        
            [group7.id]: 0,  
            result: 2
        },
    });
    network.execute();

    return network;
}
