import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import * as ol from 'ol';
import {useInjection} from "inversify-react";
import {MapControlComponent, CustomFilter} from "../../constants/globalTypes";
import Injectable from "../../injection/injectable";
import {Button} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDrawPolygon} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {GeoFilterType, SelectionType} from "../../constants/enums";
import {Fill, Stroke, Style} from "ol/style";
import {useSelector, useStore} from "react-redux";
import {getNightMode} from "../../store/selectors/user";
import {hexToRGB} from "../../utils/miscUtilities";
import {DRAW_FEATURE_COLOR} from "../../constants/colors";
import VectorSource from "ol/source/Vector";
import {Polygon} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import useGeoFilter, {GeoFilterUpdater} from "../dataviews/map/useGeoFilter";
import {DragBox, Draw} from "ol/interaction";
import {Feature} from "ol";
import {fromExtent} from "ol/geom/Polygon";
import {getGeoFilter} from "../../store/selectors/data";
import _ from "lodash";
// @ts-ignore
import simplify from "simplify";
import {useMap} from "../dataviews/map/OlMainMap";
import { v4 as uuid } from "uuid";

import className from "../../assets/scss/components/map.scss";

export interface MapFilterControlProperties {
  selectionType: SelectionType|null,
  setSelectionType: (selectionType: SelectionType|null) => void
}

function useDrawFeatureStyle(): Style {
  const nightMode = useSelector(getNightMode);
  return useMemo(() => new Style({
    fill: new Fill({
      color: hexToRGB(DRAW_FEATURE_COLOR, 0.1)
    }),
    stroke: new Stroke({
      color: DRAW_FEATURE_COLOR,
      width: 2
    })
  }), [nightMode]);
}


function createDrawFeatureLayer(
  map: ol.Map
) {
  const drawFeatureSource = new VectorSource<Polygon>();
  const drawFeatureLayer = new VectorLayer({
    source: drawFeatureSource,
    properties: {
      includeInLayerSwitcher: false,
      key: uuid()
    },
    zIndex: 1,
  });
  map.addLayer(drawFeatureLayer);
  return drawFeatureLayer;
}

function addDragBoxInteraction(
  map: ol.Map,
  setSelectionType: (selectionType: SelectionType|null) => void,
  updateGeoFilter: GeoFilterUpdater
) {
  const dragBox = new DragBox({
    className: className.dragBox,
  })
  dragBox.setActive(false);
  dragBox.on('boxend', () => {
    const extent = dragBox.getGeometry().getExtent().map(Math.round);
    updateGeoFilter(GeoFilterType.Extent, extent);
    setSelectionType(null);
  });
  map.addInteraction(dragBox);
  return dragBox;
}

function addPolygonDrawInteraction(
  map: ol.Map,
  setSelectionType: (selectionType: SelectionType|null) => void,
  updateGeoFilter: GeoFilterUpdater
) {
  const draw = new Draw({
    type: "Polygon",
    freehand: true,
  });
  draw.setActive(false);
  draw.on('drawend', ({ feature }) => {
    const zoom = map.getView().getZoom() || 1;
    const tolerance = 5000 / zoom ** 0.5;
    const polygon = feature.getGeometry<Polygon>();
    const oldCoordinates = polygon.getCoordinates()[0].map(([x, y]: number[]) => ({ x, y }));
    const simpleCoordinates = simplify(oldCoordinates, tolerance, true).map(({x, y}: any) => [x, y]);
    updateGeoFilter(GeoFilterType.Polygon, simpleCoordinates);
    setSelectionType(null);
  });
  map.addInteraction(draw);
  return draw;
}

function updateDrawFeature(
  drawFeatureLayer: VectorLayer<VectorSource<Polygon>>|null,
  geoFilter: CustomFilter,
) {
  const {
    settings
  } = geoFilter;
  drawFeatureLayer?.getSource().clear();
  if (settings && drawFeatureLayer) {
    const { type } = settings;
    let feature: Feature<Polygon>|undefined = undefined;
    if (type === GeoFilterType.Polygon) {
      const coordinates = [settings.value];
      const polygon = new Polygon(coordinates);
      feature = new Feature(polygon);
    }
    if (type === GeoFilterType.Extent) {
      const polygon = fromExtent(settings.value);
      feature = new Feature(polygon);
    }
    if (feature) {
      feature.set('ignore', true);
      drawFeatureLayer?.getSource().addFeature(feature);
    }
  }
}

function useExtentFilterer(
  map: ol.Map|null,
  updateGeoFilter: GeoFilterUpdater
) {
  const { getState } = useStore();
  const filterExtent = useCallback(() => {
    let extent = map?.getView().calculateExtent(map?.getSize()).map(Math.round);
    const currentExtent = getGeoFilter(getState()).settings?.value;
    if (extent && !_.isEqual(extent, currentExtent)) {
      updateGeoFilter(GeoFilterType.Extent, extent);
    }
  }, [map]);
  return useMemo(() => ({
    setActive: (active: boolean) => {
      if (active) {
        map?.on('moveend', filterExtent);
        filterExtent();
      } else {
        map?.un('moveend', filterExtent);
      }
    }
  }), [map]);
}

export default function AntDMapFilterControl() {
  const map = useMap();
  const [geoFilter, updateGeoFilter] = useGeoFilter();
  const dragBox = useRef<DragBox|null>(null);
  const drawPolygon = useRef<Draw|null>(null);
  const extentFilterer = useExtentFilterer(map, updateGeoFilter);

  const drawLayer = useRef<VectorLayer<VectorSource<Polygon>>|null>(null);
  const drawFeatureStyle = useDrawFeatureStyle();
  const [selectionType, setSelectionType] = useState<SelectionType|null>(null);

  useEffect(() => {
    if (map && !drawLayer.current) {
      drawLayer.current = createDrawFeatureLayer(map);
      dragBox.current = addDragBoxInteraction(map, setSelectionType, updateGeoFilter);
      drawPolygon.current = addPolygonDrawInteraction(map, setSelectionType, updateGeoFilter);
    }
  }, [map]);
  useEffect(() => {
    dragBox.current?.setActive(selectionType === SelectionType.Box);
    drawPolygon.current?.setActive(selectionType === SelectionType.Polygon);
    extentFilterer.setActive(selectionType === SelectionType.Extent);
  }, [selectionType]);
  useEffect(() => {
    if (!geoFilter.settings) {
      setSelectionType(null);
    }
  }, [geoFilter]);
  useEffect(() => drawLayer.current?.setStyle(drawFeatureStyle), [drawFeatureStyle, map]);
  useEffect(() => updateDrawFeature(drawLayer.current, geoFilter), [geoFilter, map]);

  const MapControl = useInjection<MapControlComponent>(Injectable.PopoverWrapper);
    const element = <Button
                        type={selectionType ? "primary" : "default"}
                        icon={<FontAwesomeIcon icon={faDrawPolygon as IconProp} />}
                        size="large" />;
    return (
        <MapControl
            element={element}
            tooltipTitle="Kartfilter"
            popoverItems={[
              {
                label: "Boksfilter",
                onClick: () => setSelectionType(SelectionType.Box)
              },
              {
                label: "Fritegning",
                onClick: () => setSelectionType(SelectionType.Polygon)
              },
              {
                label: "Kartutsnittfilter",
                onClick: () => setSelectionType(SelectionType.Extent)
              },
            ]} />
    );
}
