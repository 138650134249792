import {Dispatch} from "redux";
import {
    SetFocusAction,
    MoreInfoModalAction,
    SetSelectionTypeAction,
    AddLayersAction,
    AddRedactionInfoAction,
    AddFilterAction, FilterDefinitionAction, SetFilterDefinitionsAction
} from "../interfaces/actions/data";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {ComparatorValue, DefinedFilter, CustomFilter} from "../../constants/globalTypes";
import LayerDefinition from "../../interfaces/LayerDefinition";
import {DEFAULT_ZOOM} from "../../constants/defaults";
import {SelectionType} from "../../constants/enums";
import {isFilter, isCustomFilter, isDefinedFilter} from "../../utils/validationUtilities";
import {SavedFilter} from "../interfaces/states/UserState";

export const enum DataAction {
    FocusOnDataPoint = 'FOCUS_ON_DATA_POINT',
    MoreInfoAboutDataPoint = 'MORE_INFO_ABOUT_DATA_POINT',
    ShowAddNewAccidentModal = 'SHOW_ADD_NEW_ACCIDENT_MODAL',
    HideAddNewAccidentModal = 'HIDE_ADD_NEW_ACCIDENT_MODAL',
    FilterByGeometry = 'SELECT_GEOMETRY',
    SetSelectionType = 'ACTIVATE_SELECTION',
    AddLayers = 'ADD_LAYERS',
    ToggleLayerVisibility = 'TOGGLE_LAYER_VISIBILITY',
    AddFilter = 'ADD_FILTER',
    UpdateFilterDefinition = 'UPDATE_FILTER_DEFINITION',
    DeleteFilterDefinition = 'DELETE_FILTER_DEFINITION',
    InsertFilterDefinition = 'INSERT_FILTER_DEFINITION',
    ToggleExpandFilter = 'TOGGLE_EXPAND_FILTER',
    ClearAllFilters = "CLEAR_ALL_FILTERS",
    SetFilterDefinitions = "SET_FILTER_DEFINITIONS",
    AddRedactionString = "ADD_REDACTION_STRING",
}

export function addFilter(id: string, value: any) {
    return (dispatch: Dispatch) => {
        const action: AddFilterAction = {
            type: DataAction.AddFilter,
            id,
            value
        };
        dispatch(action);
    };
}

export function addLayers(
    layers: LayerDefinition[]
) {
    return (dispatch: Dispatch) => {
        const action: AddLayersAction = {
            type: DataAction.AddLayers,
            layers
        };
        dispatch(action);
    };
}

export function addRedactionString(
    redactionString: string,
    redactionInt: number
) {
    return (dispatch: Dispatch) => {
        const action: AddRedactionInfoAction = {
            type: DataAction.AddRedactionString,
            redactionString,
            redactionInt
        };
        dispatch(action);
    };
}
export function setSelectionType(
    selectionType: SelectionType|null = null
) {
    return (dispatch: Dispatch) => {
        const action: SetSelectionTypeAction = {
            type: DataAction.SetSelectionType,
            selectionType
        };
        dispatch(action);
    };
}

export function focusOnDataPoint(
    id: string|null,
    zoom: number = DEFAULT_ZOOM
) {
    return (dispatch: Dispatch) => {
        const action: SetFocusAction = {
            type: DataAction.FocusOnDataPoint,
            id,
            zoom
        };
        dispatch(action);
    };
}

export function unFocusDataPoint() {
    return focusOnDataPoint(null);
}

export function showMoreInfoAboutDataPoint(
    data: DataPoint|null,
    focus:boolean = false
) {
    return (dispatch: Dispatch) => {
        dispatch({
            type: DataAction.MoreInfoAboutDataPoint,
            data,
            focus
        } as MoreInfoModalAction);
    };
}

export function hideMoreInfoAboutDataPoint() {
    return showMoreInfoAboutDataPoint(null);
}

export function showAddNewAccidentModal() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: DataAction.ShowAddNewAccidentModal,
        });
    };
}

export function hideAddNewAccidentModal() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: DataAction.HideAddNewAccidentModal,
        });
    };
}

export function updateFilterDefinition(
  filter: DefinedFilter|CustomFilter
) {
    return (dispatch: Dispatch) => {
        const action: FilterDefinitionAction = {
            type: DataAction.UpdateFilterDefinition,
            filter
        };
        dispatch(action);
    }
}

export function deleteFilterDefinition(
  filter: DefinedFilter|CustomFilter
) {
    return (dispatch: Dispatch) => {
        const action: FilterDefinitionAction = {
            type: DataAction.DeleteFilterDefinition,
            filter
        };
        dispatch(action);
    }
}

export function insertFilterDefinition(
  filter: DefinedFilter
) {
    return (dispatch: Dispatch) => {
        const action: FilterDefinitionAction = {
            type: DataAction.InsertFilterDefinition,
            filter
        };
        dispatch(action);
    }
}

export function setFilterDefinitionsFromSavedFilter(
  savedFilter: SavedFilter
) {
    return (dispatch: Dispatch) => {
        const action: SetFilterDefinitionsAction = {
            type: DataAction.SetFilterDefinitions,
            savedFilter
        };
        dispatch(action);
    }
}

export function clearFilterDefinitionUnbound(
  filter: DefinedFilter|CustomFilter
) {
    if (isDefinedFilter(filter)) {
        filter = { ...filter, comparatorValue: undefined };
    } else if (isCustomFilter(filter)) {
        filter = { ...filter, settings: undefined };
    }
    return (dispatch: Dispatch) => {
        const action: FilterDefinitionAction = {
            type: DataAction.UpdateFilterDefinition,
            filter
        };
        dispatch(action);
    }
}

export function clearComparatorValueFromFilterUnbound(
  filter: DefinedFilter,
  comparatorValue: ComparatorValue
) {
    const comparatorValues = filter.comparatorValue as ComparatorValue[];
    filter = {
        ...filter,
        comparatorValue: comparatorValues?.filter(v => v !== comparatorValue)
    };
    return (dispatch: Dispatch) => {
        const action: FilterDefinitionAction = {
            type: DataAction.UpdateFilterDefinition,
            filter
        };
        dispatch(action);
    }
}

export function clearAllFilters() {
    return (dispatch: Dispatch) => {
        dispatch({
            type: DataAction.ClearAllFilters
        });
    }
}

export const filterDefinitionActions = {
    updateFilterDefinition,
    deleteFilterDefinition,
    insertFilterDefinition,
    clearAllFilters,
};
