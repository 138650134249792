import {AccidentLayerConfig, UserData, UserInfo} from "../interfaces/states/UserState";
import {
  AddSavedFilterAction,
  HydrateDataAction,
  SetActiveDimensionAction, UpdateAccidentLayerConfigAction, UpdateDashboardAction, UpdateDimensionGraphsAction,
  UpdateHomeStatisticViewAction, UpdatePaneSizeAction
} from "../interfaces/actions/user";
import {Action, Dispatch} from "redux";
import {DashboardSerialized, StatisticView} from "../../components/statisticViewMaker";
import {DefinedFilter, CustomFilter} from "../../constants/globalTypes";
import {Key} from "react";
import {DimensionGraph} from "../../interfaces/Config";

export enum UserAction {
  HydrateUserInfo = 'USER/HYDRATE_USER_INFO',
  HydrateUserData = 'USER/HYDRATE_USER_DATA',
  HydrateUserDataRequest = 'USER/HYDRATE_USER_DATA_REQUEST',
  HydrateUserDataSuccess = 'USER/HYDRATE_USER_DATA_SUCCESS',
  HydrateUserDataFailure = 'USER/HYDRATE_USER_DATA_FAILURE',
  PersistUserDataRequest = 'USER/PERSIST_USER_DATA_REQUEST',
  PersistUserDataSuccess = 'USER/PERSIST_USER_DATA_SUCCESS',
  PersistUserDataFailure = 'USER/PERSIST_USER_DATA_FAILURE',
  UpdateHomeStatisticView = 'USER/UPDATE_HOME_STATISTIC_VIEW',
  UpdateDashboard = 'USER/UPDATE_DASHBOARD',
  AddSavedFilter = 'USER/ADD_SAVED_FILTER',
  UpdateDimensionGraphs = 'USER/UPDATE_DIMENSION_GRAPHS',
  UpdateAccidentLayerConfig = 'USER/UPDATE_ACCIDENT_LAYER_CONFIG',
  ToggleNightMode = 'USER/SET_NIGHT_MODE',
  SetActiveDimensionIdsInMoreInfoModal = 'USER/SET_ACTIVE_DIMENSION_IDS_IN_MORE_INFO_MODAL',
  SetActiveDimensionIdsInDataCard = 'USER/SET_ACTIVE_DIMENSION_IDS_IN_DATA_CARD',
  UpdateFilterBarSize = 'USER/UPDATE_FILTER_BAR_SIZE',
  UpdateMapSize = 'USER/UPDATE_MAP_SIZE',
  ToggleExpandFilter = 'USER/TOGGLE_EXPAND_FILTER',
}

export function toggleNightMode() {
  return (dispatch: Dispatch) => {
    dispatch({ type: UserAction.ToggleNightMode });
  };
}

export function updateAccidentLayerConfig(
  accidentLayerConfig: AccidentLayerConfig
) {
  return (dispatch: Dispatch) => {
    const action: UpdateAccidentLayerConfigAction = {
      type: UserAction.UpdateAccidentLayerConfig,
      accidentLayerConfig
    }
    dispatch(action);
  };
}

export function setActiveDimensionIdsInMoreInfoModal(
  activeDimensionIds: Key[]
) {
  return (dispatch: Dispatch) => {
    const action: SetActiveDimensionAction = {
      type: UserAction.SetActiveDimensionIdsInMoreInfoModal,
      activeDimensionIds
    }
    dispatch(action);
  };
}

export function setActiveDimensionIdsInDataCard(
  activeDimensionIds: Key[]
) {
  return (dispatch: Dispatch) => {
    const action: SetActiveDimensionAction = {
      type: UserAction.SetActiveDimensionIdsInDataCard,
      activeDimensionIds
    }
    dispatch(action);
  };
}

export function hydrateUserInfo(
  data: UserInfo
): HydrateDataAction<UserInfo> {
  return { type: UserAction.HydrateUserInfo, data };
}

export function hydrateUserData(): Action {
  return { type: UserAction.HydrateUserData };
}

export function updateHomeStatisticView(
  homeStatisticView: StatisticView
): UpdateHomeStatisticViewAction {
  return {
    type: UserAction.UpdateHomeStatisticView,
    homeStatisticView
  };
}

export function updateDashboard(
  dashboard: DashboardSerialized
): UpdateDashboardAction {
  return {
    type: UserAction.UpdateDashboard,
    dashboard
  };
}

export function addSavedFilter(
  name: string,
  filters: (CustomFilter|DefinedFilter)[]
) {
  return (dispatch: Dispatch) => {
    const action: AddSavedFilterAction = {
      type: UserAction.AddSavedFilter,
      filters,
      name
    };
    dispatch(action);
  };
}

export function updateDimensionGraphs(
    dimensionGraphs: DimensionGraph[]
) {
  return (dispatch: Dispatch) => {
    const action: UpdateDimensionGraphsAction = {
      type: UserAction.UpdateDimensionGraphs,
      dimensionGraphs
    };
    dispatch(action);
  }
}

export function persistUserDataRequest(): Action {
  return { type: UserAction.PersistUserDataRequest };
}

export function persistUserDataSuccess(): Action {
  return { type: UserAction.PersistUserDataSuccess };
}

export function persistUserDataFailure(): Action {
  return { type: UserAction.PersistUserDataFailure };
}

export function hydrateUserDataRequest(): Action {
  return { type: UserAction.HydrateUserDataRequest };
}

export function hydrateUserDataSuccess(data: UserData): HydrateDataAction<UserData> {
  return {
    type: UserAction.HydrateUserDataSuccess,
    data
  };
}

export function hydrateUserDataFailure(): Action {
  return { type: UserAction.HydrateUserDataFailure };
}

export function setFilterBarSize(size: number) {
  return (dispatch: Dispatch) => {
    const action: UpdatePaneSizeAction = {
      type: UserAction.UpdateFilterBarSize,
      size
    };
    dispatch(action);
  }
}

export function setMapSize(size: number) {
  return (dispatch: Dispatch) => {
    const action: UpdatePaneSizeAction = {
      type: UserAction.UpdateMapSize,
      size
    };
    dispatch(action);
  }
}

export function toggleExpandFilter() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UserAction.ToggleExpandFilter,
    });
  }
}
