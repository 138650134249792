import {useConfig} from "../../../../../utils/hooks";
import React, {useCallback, useMemo, useState} from "react";
import {lookupFromArray} from "../../../../../utils/miscUtilities";
import _ from "lodash";
import {Modal, Select} from "antd";
import {DimensionNodeData} from "./interfaces";
import {useNodeEditorContext} from "../../NodeEditor";
import {
    dimensionIsValidForSelectorEditor,
    getOutputTypeFromDimensionType
} from "../../../utilities/helperUtilities";

interface DimensionModalProperties {
    nodeId: string;
    open: boolean;
    data: DimensionNodeData;
    onClose: () => void;
}

export default function DimensionModal(
    props: DimensionModalProperties
) {
    const {
        open,
        data,
        nodeId,
        onClose
    } = props;
    const { dimensions } = useConfig();
    const { updateNodeData } = useNodeEditorContext();
    const [dimensionId, setDimensionId] = useState(props.data.dimensionId);

    const dimensionLookup = useMemo(
        () => lookupFromArray(dimensions, ({ id }) => id) , [dimensions]);

    const options = useMemo(() => dimensions    
        .filter(dimensionIsValidForSelectorEditor)
        .map(({label, id: value}) => ({label, value})), [dimensions]);

    const onOk = useCallback(() => {
        if (_.isNumber(dimensionId)) {
            const {
                label,
                type: dimensionType
            } = dimensionLookup[dimensionId];
            const outputType = getOutputTypeFromDimensionType(dimensionType);
            updateNodeData<DimensionNodeData>(nodeId, {
                ...data,
                dimensionId,
                dimensionType,
                label,
                outputType
            });
            onClose();
        }
    }, [dimensionId, dimensionLookup, onClose]);

    return (
        <Modal
            title="Velg dimensjon"
            onOk={onOk}
            okButtonProps={{
                disabled: _.isUndefined(dimensionId)
            }}
            onCancel={onClose}
            cancelButtonProps={{
                disabled: _.isUndefined(data.dimensionId)
            }}
            open={open}>
            <Select
                value={dimensionId}
                style={{ width: '100%' }}
                onChange={setDimensionId}
                options={options} />
        </Modal>
    );
}