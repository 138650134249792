import styled from "styled-components";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {connect, ConnectedProps} from "react-redux";
import {Option} from "../diagramEditor/constants/types";
import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {Comparison, ComparisonPane, Filters} from "../../constants/globalTypes";
import {Button, Card, Select, theme} from "antd";
import { Typography } from 'antd';
import {Column} from "../ui/styled";
import _ from "lodash";
import {SavedFilter} from "../../store/interfaces/states/UserState";

const { Title } = Typography;

const SelectorsContainer = styled(Column)`
  gap: 24px;
  width: 300px;
`
function mapStateToProps(state: GlobalState) {
    const { savedFilters } = state.user.data;
    return { savedFilters };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

const ACTIVE_FILTERS = 'active-filters'

const useActiveFiltersOption: Option = {
    value: ACTIVE_FILTERS,
    label: 'Bruk aktive filter'
}

interface SelectSnapshotsToCompareProps {
    setComparison: Dispatch<SetStateAction<Comparison | undefined>>;
}

function createComparisonPane(
    option: string | number,
    saveFilters: SavedFilter[] | undefined = [],
): ComparisonPane {
    const useActiveFilters = option === useActiveFiltersOption.value
    if (_.isString(option) && useActiveFilters) {
        return { useActiveFilters }
    } else if (_.isNumber(option)) {
        return { filters: saveFilters[option].filters }
    }
    throw new Error("Invalid input in createComparisonPane")
}

export const SelectSnapshotsToCompare = connector((props: SelectSnapshotsToCompareProps & PropsFromRedux) => {
    const {
        savedFilters,
        setComparison
    } = props;

    const [leftPane, setLeftPane] = useState<ComparisonPane>()
    const [rightPane, setRightPane] = useState<ComparisonPane>()

    const options = useMemo(() => savedFilters?.map(({name: label }, value) => ({value, label})) || [], [savedFilters])
    const optionsLeft = useMemo(
        () => rightPane?.useActiveFilters ? options : [...options, useActiveFiltersOption],
        [options, rightPane])
    const optionsRight = useMemo(
        () => leftPane?.useActiveFilters ? options : [...options, useActiveFiltersOption],
        [options, leftPane])

    const { token } = theme.useToken();

    return (
        <Card>
            <SelectorsContainer>
                <Column>
                    <Title level={5}>Sammenlign</Title>
                    <Select
                        onChange={value => setLeftPane(createComparisonPane(value, savedFilters))}
                        options={optionsLeft}
                    />
                </Column>
                <Column>
                    <Title level={5}>Med</Title>
                    <Select
                        onChange={value => setRightPane(createComparisonPane(value, savedFilters))}
                        options={optionsRight}
                    />
                </Column>
                <Button
                    size="large"
                    type="primary"
                    disabled={!leftPane || !rightPane}
                    onClick={() => {
                        if (leftPane && rightPane) {
                            setComparison({ leftPane, rightPane })
                        }
                    }}
                >
                    Sammenlign
                </Button>
            </SelectorsContainer>
        </Card>
    )
})