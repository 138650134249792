import {DataDimension} from "../../constants/types";
import {Aggregation, DimensionTransform, Unit} from "../../../../constants/enums";
import {DimensionType} from "../../../../interfaces/Config";
import objectPath from "object-path";
import {Group} from "crossfilter2";
import _ from "lodash";
import {DATE_UNITS, NUMERICAL_UNITS} from "./constants";

export function dimensionHasUnits(
    dimension: DataDimension,
    aggregation?: DimensionTransform
) {
    return dimension?.type == DimensionType.Date && aggregation === DimensionTransform.Date
        || dimension?.type === DimensionType.Numerical
}

export function optionsFilter(
    dimension: DataDimension,
    value: Unit
) {
    switch (dimension.type) {
        case DimensionType.Date:
            return DATE_UNITS.includes(value);
        case DimensionType.Numerical:
            return NUMERICAL_UNITS.includes(value);
        default:
            return false;
    }
}


export function generateValueObjectByAggregation(
    aggregation: Aggregation,
    value: number
) {
    switch (aggregation) {
        case Aggregation.Count:
            return { count: value };
        case Aggregation.Sum:
            return { sum: value };
        case Aggregation.Average:
            return { avg: value };
        case Aggregation.Max:
            return { max: value };
        case Aggregation.Min:
            return { min: value };
        case Aggregation.StandardDeviation:
            return { std: value };
    }
}

export function expandPathKeys(obj: any) {
    const expandedObj: any = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (key.includes('.')) {
                const value = objectPath.get(obj, key);
                objectPath.set(expandedObj, key, value);
            } else if (typeof obj[key] === 'object') {
                expandedObj[key] = expandPathKeys(obj[key]);
            } else {
                expandedObj[key] = obj[key];
            }
        }
    }

    return expandedObj;
}

export function expandPathKeysGroup<Data>(
    sourceGroup: Group<Data, any, any>
) {
    const all = sourceGroup.all.bind(sourceGroup);
    sourceGroup.all = () => {
        return all().map(expandPathKeys);
    }
    return sourceGroup;
}

export function paddedHeatmapGroup<Data>(
    sourceGroup: Group<Data, any, any>,
    aggregation: Aggregation
) {
    return {
        all() {
            const all = sourceGroup.all();
            const keys = all.map(({ key }) => key.toString());
            const keyOne = all.map(({ key }) => key[0]);
            const keyTwo = all.map(({ key }) => key[1]);
            return _.flatMap(keyOne, (x) => _.map(keyTwo, (y) => [x, y]))
                .filter(key => !keys.includes(key.toString()))
                .map(key => ({
                    key,
                    value: generateValueObjectByAggregation(aggregation, 0)
                })).concat(all);
        },
        dispose() {
            sourceGroup.dispose();
        }
    };
}