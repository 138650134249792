import React, {Key, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import SelectorEditorModal from "./components/SelectorEditorModal";
import {useImmer} from "use-immer";
import {DimensionWithSelector, DimensionGraph} from "../../interfaces/Config";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import {lookupFromArray} from "../../utils/miscUtilities";
import { convertDimensionGraphToDimension } from "./utilities/selectorGeneratorUtilities";
import {DEFAULT_DIMENSION_GRAPH} from "./constants";
import NodePlugin from "./interface/NodePlugin";
import {DataPoint} from "../../interfaces/models/DataPoint";
import {addResultPluginIfMissing} from "./utilities/helperUtilities";

export interface SelectorEditorContextState {
    addNewDimensionGraph(showMask?: boolean): void;
    editDimensionGraph(dimensionGraphId: Key, showMask: boolean): void;
    convertDimensionGraphToDimension(
        dimensionGraph: DimensionGraph,
        dimensions: DimensionWithSelector<DataPoint>[],
        nodePluginLookup: Record<string, NodePlugin<any, any>>
    ): DimensionWithSelector<DataPoint>;
    nodePlugins: NodePlugin<any, any>[];
    nodePluginLookup: Record<string, NodePlugin<any, any>>;
}

export const SelectorEditorContext = React.createContext<SelectorEditorContextState>({} as SelectorEditorContextState);

export function useSelectorEditorContext(): SelectorEditorContextState {
    return useContext(SelectorEditorContext);
}

interface SelectorEditorProperties {
    plugins: NodePlugin<DataPoint, any>[];
    dimensionGraphs?: DimensionGraph[];
    onChange: (dimensionGraphs: DimensionGraph[]) => void;
}


export default function SelectorEditorProvider(
    props: PropsWithChildren<SelectorEditorProperties>
) {
    const {
        children,
        onChange,
        plugins: initialPlugins
    } = props;
    const initialDimensionGraphs = useMemo(
        () => lookupFromArray(props.dimensionGraphs || [], ({ id }) => id), []);
    const plugins = useMemo(() => addResultPluginIfMissing(initialPlugins), []);

    const [
        dimensionGraphs, updateDimensionGraphs
    ] = useImmer<Record<string, DimensionGraph>>(initialDimensionGraphs);
    const [showMask, setShowMask] = useState(false);
    const [currentDimensionGraph, setCurrentDimensionGraph] = useState<DimensionGraph|undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const [editing, setEditing] = useState(false);

    const updateDimensionGraph = useCallback((dimensionGraph: DimensionGraph) => updateDimensionGraphs({
        ...dimensionGraphs,
        [dimensionGraph.id]: dimensionGraph
    }), [dimensionGraphs, updateDimensionGraphs]);

    const addNewDimensionGraph = useCallback((
        showMask = true
    ) => {
        const newDimension = { id: uuid(), ...DEFAULT_DIMENSION_GRAPH };
        setCurrentDimensionGraph(newDimension);
        setShowMask(showMask);
        setOpen(true);
    }, []);

    const editDimensionGraph = useCallback((
        dimensionGraphId: Key,
        showMask = true
    ) => {
        const dimensionGraph = dimensionGraphs[dimensionGraphId];
        setCurrentDimensionGraph(dimensionGraph);
        setShowMask(showMask);
        setOpen(true);
    }, [dimensionGraphs]);


    useEffect(() => onChange(_.values(dimensionGraphs)), [dimensionGraphs]);


    const nodePluginLookup = useMemo(
        () => lookupFromArray(plugins, p => p.type()), [plugins]);

    return (
        <SelectorEditorContext.Provider value={{
            addNewDimensionGraph,
            editDimensionGraph,
            convertDimensionGraphToDimension,
            nodePlugins: plugins,
            nodePluginLookup
        }}>
            {children}
            {
                currentDimensionGraph && (
                    <SelectorEditorModal
                        onClose={() => setOpen(false)}
                        afterClose={() => {
                            setCurrentDimensionGraph(undefined);
                            setEditing(false);
                        }}
                        onSave={updateDimensionGraph}
                        dimensionGraph={currentDimensionGraph}
                        open={open}
                        editing={editing}
                        showMask={showMask} />
                )
            }
        </SelectorEditorContext.Provider>
    );
}