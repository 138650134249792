import {Edge as ReactFlowEdge, Edge, getIncomers, getOutgoers, Node as ReactFlowNode} from "reactflow";
import {DimensionWithSelector, DimensionGraph, DimensionType} from "../../../interfaces/Config";
import _ from "lodash";
import {lookupFromArray} from "../../../utils/miscUtilities";
import objectPath from "object-path";
import {Context} from "../types";
import NodePlugin from "../interface/NodePlugin";
import {DataPoint} from "../../../interfaces/models/DataPoint";

export function createContext<Data = any>(
    nodes: ReactFlowNode[],
    edges: ReactFlowEdge[],
    dimensions: DimensionWithSelector<Data>[],
    nodePluginLookup: Record<string, NodePlugin<Data, any>>
): Context<Data> {
    const nodeLookup = lookupFromArray(nodes, ({ id }) => id);
    const dimensionLookup = lookupFromArray(dimensions, ({ id }) => id);
    const inputsForNode: Record<string, string[]> = {};
    const outgoingEdgesForNode: Record<string, Edge[]> = {};
    for (const edge of edges) {
        const { target, targetHandle } = edge;
        const nodeInputs = inputsForNode[target] || [] as string[];
        if (targetHandle && !nodeInputs.includes(targetHandle)) {
            nodeInputs.push(targetHandle);
        }
        inputsForNode[target] = nodeInputs;
    }
    for (const node of nodes) {
        outgoingEdgesForNode[node.id] = edges.filter(edge => edge.source === node.id);
    }
    const startNodes = nodes.filter(node => {
        const outgoingEdgesCount = getOutgoers(node, nodes, edges).length;
        const incomingEdgesCount = getIncomers(node, nodes, edges).length;
        return outgoingEdgesCount > 0 && incomingEdgesCount === 0;
    });
    const errors: Record<string, number> = {};
    return {
        reportError(errorMessage: string): void {
            errors[errorMessage] = (errors[errorMessage] || 0) + 1;
        },
        getDimensionById(dimensionId: string): DimensionWithSelector<any> {
            return dimensionLookup[dimensionId];
        },
        getNodeById(nodeId: string): ReactFlowNode {
            return nodeLookup[nodeId];
        },
        checkIfNodeHasAllInput(nodeId: string, state: any): boolean {
            const nodeInputs = inputsForNode[nodeId] || [];
            return nodeInputs.every(nodeInput => !_.isUndefined(objectPath.get(state, [nodeId, nodeInput])))
        },
        getInputValues(nodeId: string, state: any): Record<string, any> {
            const nodeInputs = inputsForNode[nodeId] || [];
            const inputValues: Record<string, any> = {};
            for (const nodeInput of nodeInputs) {
                inputValues[nodeInput] = objectPath.get(state, [nodeId, nodeInput]);
            }
            return inputValues;
        },
        getStartNodes() {
            return startNodes;
        },
        getOutgoingEdgesForNode(nodeId: string) {
            return outgoingEdgesForNode[nodeId] || [];
        },
        getErrorCounts() {
            return errors;
        },
        execute(
            nodeId: string,
            dataPoint: Data,
            inputValues: Record<string, any>,
            nodeData: any|undefined,
            context: Context
        ) {
            const { type } = nodeLookup[nodeId];
            if (type) {
                return nodePluginLookup[type].execute(dataPoint, inputValues, nodeData, context);
            }
            // Throw Error Here
            return {};
        }
    };
}

export function convertDimensionGraphToDimension(
    dimensionGraph: DimensionGraph,
    dimensions: DimensionWithSelector<DataPoint>[],
    nodePluginLookup: Record<string, NodePlugin<any, any>>
) {
    const selector = generateFunctionFromDimensionGraph(dimensionGraph, dimensions, nodePluginLookup);
    const { id, label } = dimensionGraph;
    const filterable = false;
    const type = DimensionType.Numerical;
    return { id, label, selector, filterable, type };
}

export function generateFunctionFromDimensionGraph<Data = any>(
    dimensionGraph: DimensionGraph,
    dimensions: DimensionWithSelector<Data>[],
    nodePluginLookup: Record<string, NodePlugin<Data, any>>
) {
    const {
        nodes,
        edges
    } = dimensionGraph;
    const context = createContext<Data>(nodes, edges, dimensions, nodePluginLookup);
    const selector = (dataPoint: Data) => {
        let currentNodes = context.getStartNodes();
        const state: any = {};
        while (currentNodes.length > 0 && _.isUndefined(state.result)) {
            currentNodes = calculateOutputsForNodes(currentNodes, edges, state, dataPoint, context);
        }
        return state.result.in;
    }
    selector.context = context;
    selector.isCustom = true;
    return selector;
}

function calculateOutputsForNodes(
    nodes: ReactFlowNode[],
    allEdges: ReactFlowEdge[],
    state: any,
    d: any,
    context: Context
) {
    const nextNodes: ReactFlowNode[] = [];
    nodes.forEach(({ id, data }) => {
        const inputValues = context.getInputValues(id, state);
        const outgoingEdges = context.getOutgoingEdgesForNode(id);
        const values = context.execute(id, d, inputValues, data, context);
        for (const { target, targetHandle, sourceHandle } of outgoingEdges) {
            if (targetHandle && sourceHandle) {
                objectPath.set(state, [target, targetHandle], values[sourceHandle]);
                const node = context.getNodeById(target);
                nextNodes.push(node);
            }
        }
    });
    return nextNodes.filter(node => context.checkIfNodeHasAllInput(node.id, state));
}
