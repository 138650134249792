import React, {FC, useCallback, useMemo} from "react";
import {Modal, theme} from "antd";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DimensionGraph} from "../../../interfaces/Config";
import {useImmer} from "use-immer";
import {addOutputNodeIfMissing} from "../utilities/helperUtilities";
import SplitPane from "react-split-pane";
import {EditorMenu} from "./EditorMenu";
import {NodeEditor} from "./NodeEditor";
import {SelectorEditorModalFooter} from "./SelectorEditorModalFooter";
import NodePlugin from "../interface/NodePlugin";
import {DataPoint} from "../../../interfaces/models/DataPoint";
import {lookupFromArray} from "../../../utils/miscUtilities";
import {NodeProps} from "reactflow";

import className from "../../../assets/scss/components/selectoreditor.scss";
import {useSelectorEditorContext} from "../index";
import _ from "lodash";

interface SelectorEditorModalProperties {
    open: boolean;
    editing: boolean;
    onClose: () => void;
    afterClose: () => void;
    onSave: (dimensionGraph: DimensionGraph) => void;
    dimensionGraph: DimensionGraph;
    showMask?: boolean;
}

export default function SelectorEditorModal(
    props: SelectorEditorModalProperties
) {
    const {
        open,
        afterClose,
        onClose,
        showMask = true,
        editing,
    } = props;

    const {
        nodePlugins,
        nodePluginLookup
    } = useSelectorEditorContext();

    const initialDimensionGraph = addOutputNodeIfMissing(props.dimensionGraph);
    const [dimensionGraph, updateDimensionGraph] = useImmer<DimensionGraph>(initialDimensionGraph);

    const onSave = useCallback(() => {
        props.onSave(dimensionGraph);
        onClose();
    }, [dimensionGraph]);


    const title = useMemo(() => editing
        ? "Rediger dimensjon"
        : "Legg til ny dimensjon", [editing]);
    const { token } = theme.useToken();

    const nodeTypes = useMemo(() => lookupFromArray<NodePlugin<DataPoint, any>, FC<NodeProps>>(
        nodePlugins, d => d.type(), d => d.renderer()), []);

    const changed = useMemo(() => !_.isEqual(dimensionGraph, initialDimensionGraph), [dimensionGraph]);


    const isDimensionGraphValid = useMemo(() => {
        return dimensionGraph.nodes.every(node => {
            const {type} = node;
            if (type) {
                const incomingEdges = dimensionGraph.edges.filter(({ target }) => target === node.id);
                const outgoingEdges = dimensionGraph.edges.filter(({ source }) => source === node.id);
                return nodePluginLookup[type].validateNode(node.data, incomingEdges, outgoingEdges);
            }
            return false;
        });
    }, [dimensionGraph, nodePlugins]);

    return (
        <Modal
            title={title}
            mask={showMask}
            width={1200}
            open={open}
            closable={false}
            afterClose={afterClose}
            footer={
                <SelectorEditorModalFooter
                    changed={changed}
                    onSave={onSave}
                    onClose={onClose} />
            }
            destroyOnClose={true}>
            <DndProvider backend={HTML5Backend}>
                <div className={className.modalContent}>
                    <SplitPane
                        style={{
                            height: 460
                        }}
                        resizerStyle={{
                            background: token.colorBorderSecondary
                        }}
                        onDragStarted={console.log}
                        onDragFinished={console.log}
                        split="vertical"
                        defaultSize={300}
                        minSize={200}>
                        <EditorMenu />
                        <NodeEditor
                            nodeTypes={nodeTypes}
                            dimensionGraph={dimensionGraph}
                            updateDimensionGraph={updateDimensionGraph} />
                    </SplitPane>
                </div>
            </DndProvider>
        </Modal>
    );
}