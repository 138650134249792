import {DiagramType, InputType, Legend} from "../../constants/enums";
import {DimensionType} from "../../../../interfaces/Config";
import Diagram from "../../interfaces/Diagram";
import {GeneralDiagramConfig} from "../../constants/types";
import {Aggregation, Chart, Unit} from "../../../../constants/enums";
import {noneOf, oneOf} from "../../utilities/helperUtilities";
import {DEFAULT_MARGIN} from "../../constants/defaults";
import {
    AGGREGATION_OPTIONS,
    ALL_DIMENSION_TYPES,
    ALLOW_MULTIPLE_CHARTS,
    DIMENSION_TRANSFORM_OPTIONS,
    GROUP_BY_CHARTS,
    UNITS,
    VALUE_CHARTS,
    Y_AXIS_CHARTS
} from "./constants";
import {dimensionHasUnits, optionsFilter} from "./utilities";
import _ from "lodash";


export const generalDiagram: Diagram<GeneralDiagramConfig> = {
    type: DiagramType.GeneralDiagram,
    title: "Diagram",
    labelColumnSpan: 5,
    inputs: {
        chartType: {
            label: 'Diagram type',
            type: InputType.Radio,
            options: [
                {value: Chart.Bar, label: 'Søyle'},
                {value: Chart.Line, label: 'Linje'},
                {value: Chart.Pie, label: 'Kake'},
                {value: Chart.Heat, label: 'Varmekart'},
                {value: Chart.Bubble, label: 'Boble'},
                {value: Chart.Scatter, label: 'Spredning'},
                {value: Chart.Box, label: 'Boks'},
            ],
        },
        title: {
            label: 'Tittel',
            type: InputType.String,
            canBeUndefined: false
        },
        xAxisAggregation: {
            type: InputType.Select,
            label: 'Aggregering',
            defaultValue: Aggregation.Count,
            options: AGGREGATION_OPTIONS,
            group: 'X-akse',
            conditionalShow: ({ chartType }) => chartType === Chart.Bubble
        },
        xAxis: {
            label: 'Dimensjon',
            type: InputType.Dimension,
            dimensionType: ({ chartType }, { type, isArray}) =>
                (noneOf(chartType, Chart.Bubble, Chart.Scatter) || type === DimensionType.Numerical && !isArray)
                && ALL_DIMENSION_TYPES.includes(type),
            group: 'X-akse',
            conditionalShow: ({ chartType, xAxisAggregation }) => chartType !== Chart.Bubble
                || xAxisAggregation !== Aggregation.Count
        },
        xAxisTransform: {
            label: 'Transformer',
            type: InputType.Select,
            options: DIMENSION_TRANSFORM_OPTIONS,
            conditionalShow: ({ xAxis }) => xAxis?.type === DimensionType.Date,
            group: 'X-akse'
        },
        xAxisUnit: {
            label: 'Format',
            type: InputType.Select,
            options: UNITS,
            optionsFilter: ({xAxis}, {value}) => optionsFilter(xAxis, value as Unit),
            conditionalShow: ({ xAxis, xAxisTransform}) => dimensionHasUnits(xAxis, xAxisTransform),
            group: 'X-akse'
        },
        yAxisAggregation: {
            type: InputType.Select,
            label: 'Aggregering',
            defaultValue: Aggregation.Count,
            options: AGGREGATION_OPTIONS,
            group: 'Y-akse',
            conditionalShow: ({ chartType }) =>
                noneOf(chartType, Chart.Heat, Chart.Pie, Chart.Scatter, Chart.Box)
        },
        yAxis: {
            label: 'Dimensjon',
            type: InputType.Dimension,
            dimensionType: ({ chartType }, { type, isArray}) =>
                ((chartType === Chart.Heat && ALL_DIMENSION_TYPES.includes(type)
                    || type === DimensionType.Numerical) && !isArray),
            conditionalShow: ({ yAxisAggregation, chartType }) => {
                return (yAxisAggregation !== Aggregation.Count && Y_AXIS_CHARTS.includes(chartType))
                || oneOf(chartType, Chart.Heat, Chart.Scatter, Chart.Box)
            },
            allowMultiple: ({ groupBy, chartType }) => !groupBy && ALLOW_MULTIPLE_CHARTS.includes(chartType),
            group: 'Y-akse'
        },
        yAxisTransform: {
            label: 'Transformer',
            type: InputType.Select,
            options: DIMENSION_TRANSFORM_OPTIONS,
            conditionalShow: ({ yAxis, chartType }) =>
                yAxis?.type === DimensionType.Date && oneOf(chartType, Chart.Heat, Chart.Bubble),
            group: 'Y-akse'
        },
        yAxisUnit: {
            label: 'Format',
            type: InputType.Select,
            options: UNITS,
            optionsFilter: ({yAxis}, {value}) => optionsFilter(yAxis!, value as Unit),
            conditionalShow: ({ yAxis, yAxisTransform}) => !!yAxis && dimensionHasUnits(yAxis, yAxisTransform),
            group: 'Y-akse'
        },
        valueAggregation: {
            type: InputType.Select,
            label: 'Aggregering',
            defaultValue: Aggregation.Count,
            options: AGGREGATION_OPTIONS,
            group: 'Verdi',
            conditionalShow: ({ chartType }) => VALUE_CHARTS.includes(chartType),
        },
        value: {
            label: 'Dimensjon',
            type: InputType.Dimension,
            dimensionType: (data, dimension) =>
                dimension.type === DimensionType.Numerical && !dimension.isArray,
            conditionalShow: ({ valueAggregation, chartType }) =>
                valueAggregation !== Aggregation.Count && VALUE_CHARTS.includes(chartType),
            group: 'Verdi'
        },
        groupBy: {
            label: ({chartType}) => chartType === Chart.Bubble ? 'Bobledimensjon' : 'Grupper',
            type: InputType.Dimension,
            canBeUndefined: ({ chartType }) => chartType !== Chart.Bubble,
            dimensionType: [DimensionType.Categorical, DimensionType.GeoCategorical],
            conditionalShow: ({ chartType }) => GROUP_BY_CHARTS.includes(chartType),
            placeAfter: ({ chartType }) => chartType === Chart.Bubble ? 'title' : 'value',
            allowActions: false
        },
        margins: {
            type: InputType.Numerical,
            label: 'margin',
            defaultValue: DEFAULT_MARGIN,
            properties: ['top', 'right', 'bottom', 'left'],
        },
        legend: {
            type: InputType.Select,
            label: 'Forklaring',
            options: [
                {value: Legend.None, label: 'Ingen'},
                {value: Legend.TopLeft, label: 'Øverst til venstre'},
                {value: Legend.TopRight, label: 'Øverst til høyre'},
                {value: Legend.BottomLeft, label: 'Nederst til venstre'},
                {value: Legend.BottomRight, label: 'Nederst til høyre'}
            ],
            conditionalShow: ({ chartType, groupBy, yAxis }) =>
                (!!groupBy && oneOf(chartType, Chart.Bar, Chart.Line, Chart.Scatter))
                || (_.isArray(yAxis) && yAxis.length > 1 && oneOf(chartType, Chart.Bar))
                || chartType === Chart.Pie
        },
        maxRadius: {
            type: InputType.Numerical,
            label: 'max-radius',
            defaultValue: 200,
            minValue: 0,
            conditionalShow: ({ chartType }) => chartType === Chart.Pie
        },
        innerRadius: {
            type: InputType.Numerical,
            label: 'indre radius',
            defaultValue: 0,
            minValue: 0,
            conditionalShow: ({ chartType }) => chartType === Chart.Pie
        },
    },
    isDataValid({ yAxis, value, yAxisAggregation, valueAggregation }) {
        return (!!yAxis || yAxisAggregation === Aggregation.Count) || (!!value || valueAggregation === Aggregation.Count)
    },
    validateData({ title, yAxis, value, yAxisAggregation, valueAggregation}) {
        if (!_.isString(title) || title.length === 0) {
            return { valid: false, errorMessage: 'Tittel er påkrevd' };
        }
        return {
            valid: true,
        }
    }
}