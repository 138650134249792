import NodePlugin from "../../../interface/NodePlugin";
import {Edge, Handle, NodeProps, Position} from "reactflow";
import React, {FC, memo, useMemo, useState} from "react";
import _ from "lodash";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import NodeMenu from "../../NodeMenu";
import DimensionModal from "./DimensionModal";
import {Context, MenuItem} from "../../../types";
import {DataPoint} from "../../../../../interfaces/models/DataPoint";
import {NodeCategory, NodeType} from "../../../enums";
import {DimensionNodeData} from "./interfaces";

// @ts-ignore
import className from "../../../../../assets/scss/components/selectoreditor.scss";

export default class DimensionNode implements NodePlugin<DataPoint, DimensionNodeData> {
    public renderer(): FC<NodeProps<DimensionNodeData>> {
        return memo((props: NodeProps<DimensionNodeData>) => {

            const {
                isConnectable,
                data,
                id: nodeId
            } = props;

            const {
                label,
                dimensionId,
                dimensionType
            } = data;

            const [open, setOpen] = useState(false);
            const isDimensionUndefined = useMemo(
                () => _.isUndefined(label) || _.isUndefined(dimensionId) || _.isUndefined(dimensionType), [data]);

            const actions = useMemo(() => [
                {
                    icon: faGear,
                    action: () => setOpen(true)
                }
            ], []);

            return (
                <div className={className.dimensionNode}>
                    <NodeMenu actions={actions} />
                    <div className={className.label}>
                        {
                            label
                                ? <span>{label}</span>
                                : <div className={className.placeHolder} /> }
                    </div>
                    <Handle
                        type="source"
                        position={Position.Right}
                        id="out"
                        style={{ background: 'black' }}
                        isConnectable={isConnectable}
                    />
                    <DimensionModal
                        nodeId={nodeId}
                        onClose={() => setOpen(false)}
                        open={open || isDimensionUndefined}
                        data={data} />
                </div>
            );
        });
    }

    public execute(
        dataPoint: DataPoint,
        inputValues: Record<string, any>,
        nodeData: DimensionNodeData,
        context: Context
    ): Record<string, any> {
        const { dimensionId } = nodeData;
        const out = context.getDimensionById(dimensionId!).selector(dataPoint);
        return { out };
    }

    public validateConnection(
        handle: string,
        node: any,
    ): boolean {
        return true;
    }

    public validateNode(
        nodeData: DimensionNodeData,
        incomingEdges: Edge[],
        outgoingEdges: Edge[]
    ): boolean {
        return !_.isUndefined(nodeData.dimensionId)
            && !_.isUndefined(nodeData.dimensionType)
            && !_.isUndefined(nodeData.label)
            && outgoingEdges.length > 0;
    }

    public type(): NodeType {
        return NodeType.Dimension;
    }

    public category(): NodeCategory {
        return NodeCategory.General;
    }

    public menuItems(): MenuItem<DimensionNodeData>[] {
        return [
            {
                type: NodeType.Dimension,
                label: "Dimensjon"
            }
        ];
    }


}