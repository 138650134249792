import {DataDimension, DimensionTypeValidator, InputEntry, ValueFromDataFunction} from "../constants/types";
import _ from "lodash";
import {useMemo} from "react";
import {Input, OrderedInput} from "../interfaces/Diagram";

export function noneOf(value: any, ...values: any[]) {
    return values.every(v => v !== value)
}

export function oneOf(value: any, ...values: any[]) {
    return values.some(v => v === value);
}

export function callValueFromData(
    value: string|boolean|ValueFromDataFunction<any, any>|undefined,
    currentData: any,
    fallbackValue?: any
): any {
    return useMemo(() => {
        const computedValue = _.isFunction(value) ? value(currentData) : value;
        return computedValue || fallbackValue;
    }, [value, currentData]);
}

export function cast<Type>(value: any): Type {
    return value as Type;
}

export function wrapInArray<T = any>(
    value: any,
    shouldWrap: boolean = true
): T[] {
    if (shouldWrap) {
        return [value];
    }
    return value;
}

export function orderInputs(data: any, inputs: Record<string, Input<any>>): [string, OrderedInput<any>][] {
    const inputsWithPlaceBefore = _.keys(inputs);
    return _.entries(inputs).map(([dataProperty, input]) => {
        const { placeBefore, placeAfter } = input;
        const shouldPlaceAfter = _.isFunction(placeAfter);
        const placeFunc = _.isFunction(placeAfter) ? placeAfter : placeBefore;
        const orderByDataProperty = _.isFunction(placeFunc) ? placeFunc(data) : dataProperty
        return [dataProperty, {
            ...input,
            order: inputsWithPlaceBefore.indexOf(orderByDataProperty) + Number(shouldPlaceAfter)/2,
        }] as [string, OrderedInput<any>];
    })
        .sort(([, { order: orderA}], [, { order: orderB}]) => orderA - orderB)
}


export function groupInputs(
    data: any,
    inputs: Record<string, Input<any>>
) {
    const groupLookup: Record<string, InputEntry[]> = {};
    const groupedInputs: (InputEntry|InputEntry[])[] = [];
    orderInputs(data, inputs).forEach(([dataProperty, input]) => {
        const { group, conditionalShow } = input;
        const shouldShow = _.isFunction(conditionalShow) ? conditionalShow(data) : true;
        if (shouldShow) {
            const groupLabel = _.isFunction(group) ? group(data) : group;
            if (groupLabel) {
                if (!_.isArray(groupLookup[groupLabel])) {
                    groupLookup[groupLabel] = [[dataProperty, input]];
                    groupedInputs.push(groupLookup[groupLabel])
                } else {
                    groupLookup[groupLabel].push([dataProperty, input]);
                }
            } else {
                groupedInputs.push([dataProperty, input]);
            }
        }
    });
    return groupedInputs;
}

export function optionsIncludesValue(options: { value: any, label: string }[], value: any) {
    const possibleValues = options.map(({ value }) => value);
    const values = wrapInArray(value);
    return values.every(value => possibleValues.includes(value))
}

export function filterDimension(
    dimensionType: DimensionTypeValidator,
    dimension: DataDimension,
    data: any
) {
    const { type } = dimension;
    return _.isFunction(dimensionType)
        ? dimensionType(data, dimension)
        : _.isArray(dimensionType)
            ? dimensionType.includes(type)
            : type === dimensionType;
}