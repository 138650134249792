import React, {useState} from "react";
import { Radio } from "antd";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {
    DataPointInfoModalComponent,
    FunctionalComponent,
    PermissionWrapperComponent
} from "../../constants/globalTypes";
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 20px;
`

export default function Models() {
    const [model, setModel] = useState("frekvens");
    const onChange = (e: any) => setModel(e.target.value);
    return (
        <Container>
            <Radio.Group onChange={onChange} defaultValue={model} buttonStyle="solid" style={{ paddingBottom: 50 }}>
                <Radio.Button value="frekvens">Frekvens</Radio.Button>
                <Radio.Button value="konsekvens">Konsekvens</Radio.Button>
            </Radio.Group>
            <img src={`/static/images/svg/${model}.svg`} />
        </Container>
    )
}
