import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Diagram from "./interfaces/Diagram";
import {Resizable} from "react-resizable";
import {useImmer} from "use-immer";
import {Button} from "antd";
import _ from "lodash";
import {DiagramInstance} from "./constants/types";
import {useDiagramEditorContext} from "./index";

// @ts-ignore
import className from "../../assets/scss/components/diagrameditor.scss";

export interface PreviewAreaProperties {
    diagram?: Diagram<any>;
    open?: boolean
}


export function DiagramPreviewArea(props: PreviewAreaProperties) {
    const { diagram, open } = props;
    const {
        chartConfig,
        renderDiagram,
        isDiagramValid
    } = useDiagramEditorContext();
    const diagramElementRef = useRef<HTMLDivElement>(null);
    const diagramInstanceRef = useRef<DiagramInstance|undefined>();
    const [size, updateSize] = useImmer({
        width: 500,
        height: 400
    });
    const [currentData, setCurrentData] = useState<any>();
    const shouldRefresh = useMemo(() => !_.isEqual(chartConfig, currentData), [chartConfig, currentData]);
    const refreshDisabled = useMemo(() => !diagram?.isDataValid(chartConfig), [chartConfig]);
    const onResize = useCallback((event, {size: nextSize}) => updateSize(() => nextSize), []);
    const onResizeStop = useCallback(
        () => {
            diagramInstanceRef.current?.updateSize();
        },
        [diagramInstanceRef]);

    const rerender = useCallback(() => {
        if (diagram) {
            setCurrentData(chartConfig);
            diagramInstanceRef.current?.dispose();
            diagramInstanceRef.current = renderDiagram(diagramElementRef.current!, diagram, chartConfig, true)
            diagramInstanceRef.current?.updateSize();
        }
    }, [chartConfig])

    useEffect(() => {
        if (open && isDiagramValid) {
            // rerender()
        }
    }, [open])

    if (diagram) {
        return (
            <Resizable
                height={size.height}
                width={size.width}
                resizeHandles={['s', 'w', 'sw']}
                onResizeStop={onResizeStop}
                maxConstraints={[500, 400]}
                onResize={onResize}>
                <div className={className.preview} style={size}>
                    <div
                        ref={diagramElementRef}
                        style={{
                            opacity: shouldRefresh ? 0.3 : 1.0
                        }}
                        className={className.diagram} />
                    <div
                        style={{ display: shouldRefresh ? 'flex' : 'none' }}
                        className={className.refreshChartButtonContainer}>
                        <Button
                            className={className.rerenderButton}
                            disabled={refreshDisabled}
                            onClick={rerender}>
                            {
                                _.isUndefined(currentData) ? "Forhåndsvis" : "Oppdater"
                            }
                        </Button>
                    </div>
                </div>
            </Resizable>
        );
    }
    return <></>;
}
