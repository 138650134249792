import React from 'react';
import {EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge} from 'reactflow';
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {useNodeEditorContext} from "./NodeEditor";

export default function DeletableEdge(props: EdgeProps) {
    const {
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        style = {},
        markerEnd,
        selected,
        id
    } = props;
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const {
        deleteEdge
    } = useNodeEditorContext();

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan">
                    {
                        selected && (
                            <Button
                                type="primary"
                                size="small"
                                className="nodrag nopan"
                                onClick={() => deleteEdge(id)}
                                danger>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )
                    }
                </div>
            </EdgeLabelRenderer>
        </>
    );
};