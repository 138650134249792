import {addEdge as addEdgeReactFlow} from "reactflow";
import {EdgeType, NodeType, OutputType} from "../enums";
import {Connection, Edge} from "@reactflow/core/dist/esm/types";
import _ from "lodash";
import {DimensionWithSelector, DimensionGraph, DimensionType} from "../../../interfaces/Config";
import {OUTPUT_NODE} from "../constants";
import NodePlugin from "../interface/NodePlugin";
import ResultNode from "../components/nodes/result";

export function addEdgeWithType(
    params: Edge | Connection,
    edges: Edge[],
    type: EdgeType
) {
    return addEdgeReactFlow({ ...params, type }, edges);
}

export function addOutputNodeIfMissing(
    dimensionGraph: DimensionGraph
) {
    const { nodes } = dimensionGraph;
    const missingOutputNode = !_.uniq(nodes.map(({ type }) => type)).includes(NodeType.Result);
    if (missingOutputNode) {
        dimensionGraph.nodes = nodes.concat(OUTPUT_NODE);
    }
    return dimensionGraph;
}

export function isNumberArray(v: any) {
    return _.isArray(v) && v.every(_.isNumber);
}

export function addResultPluginIfMissing(plugins: NodePlugin<any, any>[]) {
    const hasResultPlugin = plugins.map(p => p.type()).includes(NodeType.Result);
    if (!hasResultPlugin) {
        return plugins.concat(new ResultNode());
    }
    return plugins;
}

export function dimensionIsValidForSelectorEditor(dimensionDefinition: DimensionWithSelector<any>) {
    const {
        type,
        isArray
    } = dimensionDefinition;
    const outputType = getOutputTypeFromDimensionType(type);
    return !_.isUndefined(outputType) && !isArray;
}

export function getOutputTypeFromDimensionType(dimensionType: DimensionType): OutputType|undefined {
    switch (dimensionType) {
        case DimensionType.Date:
            return OutputType.Date;
        case DimensionType.Numerical:
            return OutputType.Numerical;
        case DimensionType.Categorical:
        case DimensionType.GeoCategorical:
            return OutputType.Categorical;
        case DimensionType.Text:
            return OutputType.String;
    }
}

export function checkHandleConnectionCount(
    handel: string,
    minConnections: number,
    maxConnections: number,
    edges: Edge[]
) {
    const connectionCount = edges
        .filter(({ sourceHandle, targetHandle }) => sourceHandle === handel || targetHandle === handel)
        .length;
    return connectionCount >= minConnections && connectionCount <= maxConnections;
}