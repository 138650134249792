import React, {FC, useCallback, useEffect, useMemo, useRef} from "react";
import {addEdgeWithType} from "../utilities/helperUtilities";
import {EdgeType} from "../enums";
import {
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    BackgroundVariant,
    Controls,
    EdgeChange, getRectOfNodes,
    NodeChange, NodeProps,
    ReactFlow, ReactFlowInstance, useReactFlow
} from "reactflow";
import {DEFAULT_VIEWPORT} from "../constants";
import {DimensionGraph} from "../../../interfaces/Config";
import {Updater} from "use-immer";
import {useNodeDropZone} from "../utilities/dndUtilities";
import DeletableEdge from "./DeletableEdge";
import {Connection} from "@reactflow/core/dist/esm/types";
import {useSelectorEditorContext} from "../index";
import {lookupFromArray} from "../../../utils/miscUtilities";
import _ from "lodash";

interface NodeEditorProps {
    nodeTypes: Record<string, FC<NodeProps>>;
    dimensionGraph: DimensionGraph;
    updateDimensionGraph: Updater<DimensionGraph>;
}

export interface NodeEditorContextState {
    updateNodeData: <NodeData = any>(id: string, newNodeData: NodeData) => void;
    deleteNode: (idOfNodeToDelete: string) => void;
    deleteEdge: (idOfEdgeToDelete: string) => void;
}

export const NodeEditorContext = React.createContext<NodeEditorContextState>({} as NodeEditorContextState);

export function useNodeEditorContext(): NodeEditorContextState {
    return React.useContext(NodeEditorContext) as NodeEditorContextState;
}

const edgeTypes = {
    [EdgeType.Deletable]: DeletableEdge
};

export function NodeEditor(
    props: NodeEditorProps
) {
    const {
        dimensionGraph,
        updateDimensionGraph,
        nodeTypes
    } = props;

    const instance = useRef<ReactFlowInstance>();
    const { setViewport, setDropRef } = useNodeDropZone(dimensionGraph, updateDimensionGraph);
    const { nodePluginLookup } = useSelectorEditorContext();


    const updateNodeData = useCallback((id: string, newNodeData: any) => updateDimensionGraph({
        ...dimensionGraph,
        nodes: dimensionGraph.nodes.map(node =>
            node.id === id
                ? {...node, data: newNodeData}
                : node)
    }), [dimensionGraph, updateDimensionGraph]);

    const deleteNode = useCallback((idOfNodeToDelete: string) => updateDimensionGraph({
        ...dimensionGraph,
        nodes: dimensionGraph.nodes.filter(({id}) => id !== idOfNodeToDelete),
        edges: dimensionGraph.edges.filter(
            ({source, target}) => source !== idOfNodeToDelete && target !== idOfNodeToDelete)
    }), [dimensionGraph, updateDimensionGraph]);

    const deleteEdge = useCallback((idOfEdgeToDelete: string) => updateDimensionGraph({
        ...dimensionGraph,
        edges: dimensionGraph.edges.filter(({id}) => id !== idOfEdgeToDelete)
    }), [dimensionGraph, updateDimensionGraph]);

    const onConnect = useCallback((params) => updateDimensionGraph({
        ...dimensionGraph,
        edges: addEdgeWithType(params, dimensionGraph.edges, EdgeType.Deletable)
    }), [dimensionGraph, updateDimensionGraph]);

    const onNodesChanges = useCallback((changes: NodeChange[]) => updateDimensionGraph({
        ...dimensionGraph,
        nodes: applyNodeChanges(changes, dimensionGraph.nodes)
    }), [dimensionGraph, updateDimensionGraph]);

    const onEdgesChange = useCallback((changes: EdgeChange[]) => updateDimensionGraph(draft => ({
        ...draft,
        edges: applyEdgeChanges(changes, draft.edges)
    })), [dimensionGraph, updateDimensionGraph]);

    return (
        <NodeEditorContext.Provider value={{ updateNodeData, deleteNode, deleteEdge }}>
            <ReactFlow
                ref={setDropRef}
                style={{
                    position: 'relative'
                }}
                onInit={reactFlowInstance => instance.current = reactFlowInstance}
                fitView={true}
                nodeOrigin={[0.5, 0.5]}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onMove={(event, {x, y, zoom}) => setViewport({ x, y, zoom })}
                nodes={dimensionGraph.nodes}
                edges={dimensionGraph.edges}
                onNodesChange={onNodesChanges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                isValidConnection={(connection: Connection) => {
                    const { source, sourceHandle, target, targetHandle } = connection;
                    if (_.isString(source) && _.isString(target) && instance.current) {
                        const sourceNode = instance.current.getNode(source);
                        const targetNode = instance.current.getNode(target);
                        if (sourceNode && sourceNode.type && targetNode && targetNode.type) {
                            const sourcePlugin = nodePluginLookup[sourceNode.type];
                            const targetPlugin = nodePluginLookup[targetNode.type];
                            if (sourcePlugin && targetPlugin) {
                                const incomingEdges = dimensionGraph.edges
                                    .filter(edge => edge.target === target && edge.targetHandle === targetHandle);
                                return targetPlugin.validateConnection(targetHandle!, sourceNode, incomingEdges);
                            }
                        }
                    }
                    return false;
                }}
                proOptions={{ hideAttribution: true }}>
                <Controls />
                <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
            </ReactFlow>
        </NodeEditorContext.Provider>
    );
}
