import React, {useCallback, useEffect, useRef} from "react";
import {DiagramInstance} from "./constants/types";
import {useDiagramEditorContext} from "./index";
import {DashboardComponent} from "../../constants/globalTypes";
import _ from "lodash";
import {theme} from "antd";
import {useSelector} from "react-redux";
import {getNightMode} from "../../store/selectors/user";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGripVertical, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {SettingOutlined} from "@ant-design/icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Layout} from "react-grid-layout";

// @ts-ignore
import className from "../../assets/scss/components/filtergrids.scss";
import {useResizeObserver} from "../../utils/hooks";

interface DiagramRendererProperties {
    dashboardComponent: DashboardComponent;
    onEdit: () => void;
    onDelete: () => void;
    layout: Layout
    nonEditable?: boolean
}


const DiagramTitle = (
    props: DiagramRendererProperties
) => {
    const {
        dashboardComponent: {
            data
        },
        onEdit,
        onDelete,
        nonEditable = false
    } = props;
    const { token } = theme.useToken();
    return (
        <div
            style={{
                borderBottomColor: token.colorBorder,
            }}
            className={className.title}>
            <div className={nonEditable ? undefined : className.draggableHandle}>
                {
                    !nonEditable && (
                        <FontAwesomeIcon
                            style={{
                                color: token.colorBorder,
                            }}
                            className={className.movableIcon}
                            icon={faGripVertical as IconProp} />
                    )
                }
                <div className={className.text}>{data?.title}</div>
            </div>
            {

                !nonEditable && (
                    <>
                        <SettingOutlined
                            style={{
                                color: token.colorPrimary
                            }}
                            onClick={onEdit}
                            className={className.icon} />
                        <FontAwesomeIcon
                            style={{
                                color: token.colorPrimary
                            }}
                            onClick={onDelete}
                            className={className.icon}
                            icon={faTrashAlt as IconProp} />
                    </>
                )
            }
        </div>
    )
}

const DiagramOverlay = (
    props: DiagramRendererProperties
) => {
    const {
        onEdit
    } = props;

    return (
        <div className={`${className.full} ${className.draggableHandle}`}>
            <div className={className.buttonContainer}>
                <SettingOutlined
                    onClick={onEdit}
                    className={className.icon} />
            </div>
        </div>
    );
}

export default function DiagramRenderer(
    props: DiagramRendererProperties
) {
    const {
        dashboardComponent,
        layout,
    } = props;
    const { h, w } = layout

    const {
        renderDiagram,
        availableDiagrams
    } = useDiagramEditorContext();

    const { token } = theme.useToken();
    const nightMode = useSelector(getNightMode);
    const diagram = availableDiagrams.find(diagram => diagram.type === dashboardComponent.type)
    const ref = useRef(null);
    const { width } = useResizeObserver(ref);

    const diagramElementRef = useRef<HTMLDivElement>(null);
    const diagramInstanceRef = useRef<DiagramInstance|undefined>();

    const renderChart = useCallback(() => {
        if (diagram && diagramElementRef.current) {
            diagramInstanceRef.current?.dispose();
            diagramInstanceRef.current = renderDiagram(diagramElementRef.current!, diagram, dashboardComponent.data, true)
            diagramInstanceRef.current!.updateSize();
        }
    }, [diagramElementRef.current, dashboardComponent.data]);

    useEffect(renderChart, [dashboardComponent.data]);
    useEffect(() => {
        diagramInstanceRef.current?.updateSize()
    }, [h, w, width]);

    const withTitle = _.isString(dashboardComponent.data.title);
    return (
        <div
            style={{
                background: nightMode ? token.colorBgElevated : token.colorFillSecondary,
                boxShadow: token.boxShadow
            }}
            className={`${className.gridItem} ${nightMode ? className.night : ''}`}>
            {
                withTitle
                    ? <DiagramTitle {...props} />
                    : <DiagramOverlay {...props} />
            }
            <div
                ref={ref}
                className={className.gridItemContent}>
                <div
                    id={`diagram-${dashboardComponent.id}`}
                    ref={diagramElementRef}/>
            </div>
        </div>
    )
}