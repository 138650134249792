import {Metric} from "./enums";
import {Option} from "../components/diagramEditor/constants/types";

export const METRIC_OPTIONS = [
  { value: Metric.Count, label: 'Antall ulykker' },
  { value: Metric.Sum, label: 'Sum' },
  { value: Metric.Average, label: 'Gjennomsnitt' },
  { value: Metric.Max, label: 'Maximum' },
  { value: Metric.Min, label: 'Minimum' },
  { value: Metric.StandardDeviation, label: 'Standardavvik' },
];


export const monthOptions: Option[] = [
  { label: "Januar", value: 0 },
  { label: "Februar", value: 1 },
  { label: "Mars", value: 2 },
  { label: "April", value: 3 },
  { label: "Mai", value: 4 },
  { label: "Juni", value: 5 },
  { label: "Juli", value: 6 },
  { label: "August", value: 7 },
  { label: "September", value: 8 },
  { label: "Oktober", value: 9 },
  { label: "November", value: 10 },
  { label: "Desember", value: 11 }
];
