import {
    Key,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import ResizeObserver from "resize-observer-polyfill";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import {isDefined, isFilter, isCustomFilter, isDefinedFilter} from "./validationUtilities";
import {useInjection} from "inversify-react";
import DataProvider from "../components/datasource/DataProvider";
import Injectable from "../injection/injectable";
import createCrossfilter, {Crossfilter} from "crossfilter2";
import Config, {DimensionWithSelector, DimensionType} from "../interfaces/Config";
import {DataPoint} from "../interfaces/models/DataPoint";
import {Updater, useImmer} from "use-immer";
import {ComponentResizer, DefinedFilter, CustomFilter} from "../constants/globalTypes";
import {ComponentType} from "../constants/enums";
import {useDataSourceContext} from "../components/datasource/DataSourceProvider";
import ComponentRendererProperties from "../interfaces/properties/ComponentRendererProperties";
import {ActionCreatorsMapObject, bindActionCreators} from "redux";
import {useDispatch} from "react-redux";

export function useDebouncedCallback(
  func: (...args: any) => any,
  time: number,
  dependencies: any[],
) {
    return useCallback(_.debounce(func, time), dependencies);
}

export function useClassName(
    name: string,
    initialActive = false
): [string, (active: boolean) => void] {
    const [className, setClassName] = useState(initialActive ? name : '');
    const setActive = (active: boolean) => setClassName(active ? name : '');
    return [className, setActive];
}

export interface ResizeObserverEntry {
    target: HTMLElement;
    contentRect: DOMRectReadOnly;
}

type SizeState = {
    setX: (value: number) => void,
    setY: (value: number) => void,
    setWidth: (value: number) => void,
    setInnerWidth: (value: number) => void,
    setHeight: (value: number) => void,
    setInnerHeight: (value: number) => void,
}

const refLookup: {[key: string]: SizeState } = {};

const handleResize = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (!Array.isArray(entries)) {
        return;
    }

    for (const entry of entries) {
        if (isDefined(refLookup[entry.target.id])) {
            const computedStyle = getComputedStyle(entry.target);

            const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

            const marginX = parseFloat(computedStyle.marginLeft) + parseFloat(computedStyle.marginRight);
            const marginY = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom);

            const borderX = parseFloat(computedStyle.borderLeftWidth) + parseFloat(computedStyle.borderRightWidth);
            const borderY = parseFloat(computedStyle.borderTopWidth) + parseFloat(computedStyle.borderBottomWidth);

            const x = entry.target.offsetLeft;
            const y = entry.target.offsetTop;
            const width = entry.target.offsetWidth;
            const innerWidth = width - paddingX - marginX - borderX;
            const height = entry.target.offsetHeight;
            const innerHeight = height - paddingY - marginY - borderY;

            refLookup[entry.target.id].setX(x);
            refLookup[entry.target.id].setY(y);
            refLookup[entry.target.id].setWidth(width);
            refLookup[entry.target.id].setInnerWidth(innerWidth);
            refLookup[entry.target.id].setHeight(height);
            refLookup[entry.target.id].setInnerHeight(innerHeight);
        } else {
            console.warn("Has non active entries!");
        }
    }
};
let resizeObserver = new ResizeObserver(handleResize as ResizeObserverCallback);


export const useResizeObserver = (
    ref: RefObject<HTMLElement>,
    debounce: number = 20
) => {
    const [width, setWidth] = useState<number>();
    const [x, setX] = useState<number>();
    const [y, setY] = useState<number>();
    const [innerWidth, setInnerWidth] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [innerHeight, setInnerHeight] = useState<number>();

    useEffect(() => {
        const { current } = ref;
        if (!isDefined<HTMLElement>(current)) {
            return;
        }

        current.id = current.id || uuid();

        refLookup[current.id] = {
            setX: _.debounce(setX, debounce),
            setY: _.debounce(setY, debounce),
            setWidth: _.debounce(setWidth, debounce),
            setInnerWidth: _.debounce(setInnerWidth, debounce),
            setHeight: _.debounce(setHeight, debounce),
            setInnerHeight: _.debounce(setInnerHeight, debounce),
        }

        resizeObserver.observe(current);

        return () => resizeObserver.unobserve(current);
    }, [ref]);

    return {
        width: width || 0,
        height: height || 0,
        innerWidth: innerWidth || 0,
        innerHeight: innerHeight || 0,
        x: x || 0,
        y: y || 0
    };
};

export const useCrossfilter = (editing?: boolean) => {
    const dataProvider = useInjection<DataProvider>(Injectable.DataProvider);
    const [data, setData] = useState<DataPoint[]>(dataProvider.data);
    useEffect(() => dataProvider.onDataChange(setData), [dataProvider]);
    const { dataSource } = useDataSourceContext()
    return useMemo(() => {
        if (editing) {
            return data.length > 0
              ? createCrossfilter(dataProvider.data)
              : undefined;
        }
        return dataSource.crossfilter;
    },[data]);
}

export const useDimensionOptions = (
  options: {
      type?: DimensionType|DimensionType[];
      notType?: DimensionType|DimensionType[];
      onlyFilterable?: boolean;
  }
) => {
    const {
        type,
        notType,
        onlyFilterable
    } = options;
    const config = useInjection<Config>(Injectable.Config);
    const dimensions = useMemo(() => {
        let dimensions = config.dimensions || [];
        if (type) {
            dimensions = dimensions?.filter(
              _.isArray(type)
                ? dimension => type.includes(dimension.type)
                : dimension => dimension.type == type
            )
        }
        if (notType) {
            dimensions = dimensions?.filter(
              _.isArray(notType)
                ? dimension => !notType.includes(dimension.type)
                : dimension => dimension.type !== notType
            )
        }
        if (onlyFilterable) {
            dimensions = dimensions.filter(dimension => dimension.filterable);
        }
        return dimensions;
    }, [config]);
    return useMemo(() => {
        return dimensions?.map(dimension => ({
            value: dimension.id,
            label: dimension.label,
            dimension
        }));
    }, [dimensions]);
}

export function useDataBuilder<T>(
  componentType: ComponentType,
  initialValue: Partial<T>,
  onDataChange: (componentType: ComponentType, data: T, valid: boolean) => void,
  validator: (data: Partial<T>) => boolean
): [Partial<T>, Updater<Partial<T>>, boolean] {
    const initialValid = validator(initialValue);
    const [data, updater] = useImmer<Partial<T>>(initialValue);
    const [valid, setValid] = useState(initialValid);
    useEffect(() => {
        const nextValid = validator(data);
        onDataChange(componentType, data as T, nextValid);
        setValid(nextValid);
    }, [data]);
    return [data, updater, valid];
}


export function useComponentCreator<T>(
  func: (crossfilter: Crossfilter<DataPoint>, data: T) => ComponentResizer|undefined,
  props: ComponentRendererProperties<any>
): [() => void, boolean] {
    const {
        editing,
        data,
        width,
        height,
        isEditingExistingComponent
    } = props;
    const [changed, setChanged] = useState(false);
    const crossfilter = useCrossfilter(editing);
    const componentResizer = useRef<ComponentResizer>();
    const create = useCallback(() => {
        if (crossfilter) {
            componentResizer.current?.dispose();
            componentResizer.current = func(crossfilter, data as T);
            setChanged(false);
        }
    }, [data, crossfilter, width, height]);

    useEffect(() => {
        setChanged(true);
        if (!editing) {
            create();
        }
    }, [data]);

    useEffect(() => {
        if ((!editing && !componentResizer.current) || isEditingExistingComponent) {
            create();
        }
    }, []);

    useEffect(() => {
        componentResizer.current?.setSize(width, height);
    }, [width, height]);

    return [create, changed];
}

export function useArray<T>(
  initialArray: T[] = []
): [T[], (item: T) => void] {
    const arrayRef = useRef<T[]>([]);
    const [array, setArray] = useState<T[]>(initialArray);
    const updateArray = useCallback((item: T) => {
        const nextArray = array.concat(item);
        const index = nextArray.length;
        setArray(nextArray);
    }, [array]);
    return [array, updateArray]
}

export function useDimension(
  dimensionId?: Key|null,
  dependencies: any[] = []
): DimensionWithSelector<DataPoint>|undefined {
    const config = useInjection<Config>(Injectable.Config);
    return useMemo(() => {
        return config.dimensions.filter(({id}) => `${dimensionId}` === `${id}`).pop()
    }, [dimensionId].concat(dependencies));
}

export function useConfig(): Config {
    return useInjection<Config>(Injectable.Config);
}

export function useFilterLabel(
  filter: DefinedFilter|CustomFilter
) {
    const config = useInjection<Config>(Injectable.Config);
    return useMemo(() => {
        if (isDefinedFilter(filter)) {
            return config.dimensions
              .filter(({id}) => filter.dimensionId.toString() === id.toString())
              .pop()?.label;
        } else if (isCustomFilter(filter)) {
            return filter.label;
        }
    }, [filter]);

}

export function callOnce(
  func: () => void,
  when: (() => boolean)|null = null
) {
    const called = useRef(false);
    const dependencies = _.isFunction(when) ? undefined : [];
    useEffect(() => {
        if (!called.current && (!when || when())) {
            func();
            called.current = true;
        }
    }, dependencies);
}

export function callSetterOnValueChange<S>(
  setter: (value: S) => void, value: S) {
    useEffect(() => {
        setter(value);
    }, [value]);
}

export function useActions(
  actions: ActionCreatorsMapObject,
) {
    const dispatch = useDispatch();
    return bindActionCreators(actions, dispatch);
}
