import {Button, Modal} from "antd";
import _ from "lodash";
import React, {useCallback} from "react";
const { confirm } = Modal;

interface SelectorEditorModalFooterProperties {
    onSave: () => void;
    onClose: () => void;
    changed: boolean;
}

export function SelectorEditorModalFooter(
    props: SelectorEditorModalFooterProperties
) {
    const {
        onSave,
        onClose,
        changed
    } = props;

    const onClick = useCallback(() => {
        if (changed) {
            confirm({
                content: "Ved å avbryte nå mister du arbeid som ikke er lagret, er du sikker på at du ønsker å avbryte?",
                okText: "Ja",
                onOk: onClose,
                cancelText: "Nei",
                onCancel: _.noop,
            });
        } else {
            onClose();
        }
    }, [changed, onClose]);

    return (
        <>
            <Button onClick={onClick}>
                Avbryt
            </Button>
            <Button onClick={onSave}>
                Lagre
            </Button>
        </>
    )
}