import DataSource from "../../interfaces/DataSource";
import { DataProcessor, DataSourceEventCallback, DimensionFunction } from "./constants/types";
import crossfilter, { Crossfilter, Dimension, NaturallyOrderedValue } from "crossfilter2";
import { alwaysTrue } from "../../utils/miscUtilities";
import {
    errorInDataProcessorFunction,
    invalidDataProcessorFunction,
    rangeError
} from "../../constants/errorStrings";
import {
    isArray,
    isDefined,
    isFunction
} from "../../utils/validationUtilities";
import { injectable } from "inversify";
import _ from "lodash";
import Filter from "../../interfaces/models/Filter";
import { DataType } from "../../constants/enums";
import { CrossfilterEvent } from "./constants/enums";
import * as dc from "dc";
import {config as dcConfig, Scale} from "dc";
import * as d3 from "d3";
import {DataPoint} from "../../interfaces/models/DataPoint";
import TextDimension from "./TextDimension";
import {resolve} from "inversify-react";
import Injectable from "../../injection/injectable";
import {Store} from "redux";
import Config, {DimensionWithSelector} from "../../interfaces/Config";
import {Disposable} from "../../constants/globalTypes";
import {Key} from "react";

@injectable()
export default class CrossfilterDataSource implements DataSource {

    @resolve(Injectable.Store)
    private readonly _store!: Store;

    @resolve(Injectable.Config)
    private readonly _config!: Config;

    private readonly _crossFilter: Crossfilter<DataPoint>;
    private readonly _dimensions: { [key: string]: Dimension<DataPoint, NaturallyOrderedValue> } = {};
    private readonly _dimensionFunctions: Record<string, (value: any) => boolean> = {};
    private readonly _onDataFilteredCallbacks: DataSourceEventCallback[] = [];
    private readonly _onDataChangedCallbacks: DataSourceEventCallback[] = [];
    private readonly _colorScale: { [key: string]: Scale<any> } = {};
    private readonly _labels: Record<string, string> = {};
    private readonly _textDimension: Record<string, TextDimension> = {};
    private _uniqueValues: Record<Key, any[]> = {};
    private _bounds: Record<Key, { min: number, max: number }> = {};

    constructor() {
        this._crossFilter = crossfilter<DataPoint>([]);
        this._setupEventListener();
    }

    public get crossfilter(): crossfilter.Crossfilter<DataPoint> {
        return this._crossFilter;
    }

    public data(
        data: DataPoint[],
        clear: boolean = true
    ): void {
        this._addData(data, clear);
    }

    public getLabel(id: string) {
        return this._labels[id];
    }

    public clearAllFilters(): void {
        _.values(this._dimensions).forEach(dimension => dimension.filterAll());
    }

    public dimension<Value extends NaturallyOrderedValue>(
        id: string,
        label?: string,
        dimensionFunction?: DimensionFunction<Value>,
        isArray?: boolean
    ): Dimension<DataPoint, Value> {
        if (isDefined(label)) {
            this._labels[id] = label as string;
        }
        if (!isDefined(this._dimensions[id]) && dimensionFunction) {
            this._dimensions[id] = this._crossFilter.dimension<Value>(dimensionFunction, isArray);
        }
        return this._dimensions[id] as Dimension<DataPoint, Value>;
    }

    public filter(id: string, filterFunction: (value: any) => boolean) {
        if (this._dimensions[id]) {
            this._dimensionFunctions[id] = filterFunction;
            this._dimensions[id].filter(filterFunction);
        }
    }

    public textDimension(
        id: string,
        idAccessor: (dataPoint: DataPoint) => string | number,
        label: string,
        dimensionFunction: DimensionFunction<String>
    ): TextDimension {
        if (!isDefined(this._textDimension[id]) && idAccessor && label && dimensionFunction) {
            const crossfilterDimension = this.dimension(id, label, idAccessor);
            this._textDimension[id] = new TextDimension(idAccessor, dimensionFunction, crossfilterDimension);
        }
        return this._textDimension[id];
    }

    public colorScale(id: string, scheme?: string[]) {
        const { _defaultColors: defaultScheme } = dcConfig as any;
        if (!this._colorScale[id]) {
            scheme = scheme || defaultScheme;
            this._colorScale[id] = d3.scaleOrdinal(scheme);
            this._updateColorScale(id);
        }
        return this._colorScale[id];
    }

    public takeFiltered(
        count: number = Infinity,
        offset: number = 0
    ): Promise<DataPoint[]> {
        const collection = this._crossFilter.allFiltered();
        return CrossfilterDataSource.take(collection, count, offset);
    }

    public take(
        count: number = Infinity,
        offset: number = 0
    ): Promise<DataPoint[]> {
        const collection = this._crossFilter.all();
        return CrossfilterDataSource.take(collection, count, offset);
    }

    public getByIndex(
        index: number
    ): Promise<DataPoint> {
        const data = this._crossFilter.all()
        if (index < data.length) {
            const dataPoint = data[index]
            return Promise.resolve(dataPoint);
        }
        return Promise.reject(rangeError);
    }

    public getFilters(): Filter[] {

        return _.entries<Dimension<DataPoint, NaturallyOrderedValue>>(this._dimensions)
            .filter(([_, dimension]) => isDefined(dimension.currentFilter()))
            .map(([id, _]) => ({ id }));
    }

    public onDataFiltered(
        callback: DataSourceEventCallback
    ): Disposable {
        const {
            _onDataFilteredCallbacks
        } = this;
        this._onDataFilteredCallbacks.push(callback);
        return {
            dispose() {
                const index = _onDataFilteredCallbacks.indexOf(callback);
                if (index > -1) {
                    _onDataFilteredCallbacks.splice(index, 1);
                }
            }
        }
    }

    public onDataChanged(
        callback: DataSourceEventCallback
    ): Disposable {
        const {
            _onDataChangedCallbacks
        } = this;
        _onDataChangedCallbacks.push(callback);
        if (this._crossFilter.all().length > 0) {
            callback(this);
        }
        return {
            dispose() {
                const index = _onDataChangedCallbacks.indexOf(callback);
                if (index > -1) {
                    _onDataChangedCallbacks.splice(index, 1);
                }
            }
        }
    }

    public clear() {
        this._crossFilter.remove(alwaysTrue)
    }

    public hasData() {
        return this._crossFilter.all().length > 0;
    }

    public isElementFiltered(
        index: number,
        ignoreDimensions: any[] = []
    ): boolean {
        return this._crossFilter.isElementFiltered(index, ignoreDimensions);
    }

    public getUniqueValuesForDimension(
        dimension: DimensionWithSelector<DataPoint>
    ) {
        const {
            id,
            selector
        } = dimension;
        if (!_.isArray(this._uniqueValues[id])) {
            const uniqueValuesLookup: any = {}
            this._crossFilter.all().map(selector).forEach(value => {
                uniqueValuesLookup[value] = (uniqueValuesLookup[value] || 0) + 1
            });
            this._uniqueValues[id] = Object.entries(uniqueValuesLookup).map(([key, value]) => ({
                label: `${key} (${value})`,
                value: key
            }))
        }
        return this._uniqueValues[id];
    }

    private _setupEventListener(): void {
        this._crossFilter.onChange(type => {
            switch (type) {
                case CrossfilterEvent.DataAdded:
                case CrossfilterEvent.DataRemoved:
                    this._onDataChangedCallbacks.forEach(callback => callback(this));
                    _.keys(this._colorScale).forEach(this._updateColorScale.bind(this));
                    break;
                case CrossfilterEvent.DataFiltered:
                    dc.redrawAll();
                    this._onDataFilteredCallbacks.forEach(callback => callback(this));
                    break;
            }
        });
    }

    private _processData(
        response: Response,
        processor: DataProcessor | DataProcessor[],
        type: DataType
    ) {
        let processors: DataProcessor[] = isArray(processor) ? processor : [processor];
        try {
            let promise: Promise<any> = CrossfilterDataSource.getInitialPromise(response, type);
            if (isArray(processors, isFunction)) {
                for (const processor of processors) {
                    promise = promise.then(processor);
                }
                return promise;
            }
        } catch (msg) {
            return Promise.reject(errorInDataProcessorFunction(msg as string))
        }
        return Promise.reject(invalidDataProcessorFunction);
    }

    private _addData(
        data: DataPoint[],
        clear: boolean
    ) {
        if (clear) {
            this._crossFilter.remove(alwaysTrue)
        }
        this._crossFilter.add(data);
        _.entries(this._textDimension).forEach(([, textDimension]) => textDimension.updateFuse(data));
        _.entries(this._dimensions).forEach(([key, dimension]) => {
            if (dimension.hasCurrentFilter()) {
                const currentFilter = dimension.currentFilter()!;
                dimension.filter(currentFilter);
            }
        });
        this._uniqueValues = {};
        this._bounds = {};
        dc.redrawAll();
    }

    private _updateColorScale(id: string) {
        if (this._dimensions[id]) {
            const all = this._dimensions[id].group().all().slice();
            const domain = _.orderBy(all, 'value', 'desc').map(d => d.key);
            this._colorScale[id].domain(domain as any);
        }
    }

    private static take(
      collection: ReadonlyArray<DataPoint>,
      count: number = Infinity,
      offset: number = 0
    ): Promise<DataPoint[]> {
        if (offset <= collection.length) {
            const result = collection.slice(offset, offset + count);
            return Promise.resolve(result);
        }
        return Promise.reject(rangeError);
    }

    private static getInitialPromise(
      response: Response,
      type: DataType
    ): Promise<unknown> {
        switch (type) {
            case DataType.Json:
                return response.json();
            case DataType.Text:
                return response.text();
        }
    }

    public getBoundsForDimension(
        dimension: DimensionWithSelector<DataPoint> | undefined
    ): { max: number; min: number } {
        const result = { max: -Infinity, min: Infinity };
        if (dimension) {
            const { selector } = dimension;
            this._crossFilter.all().map(selector).forEach(value => {
                result.max = value > result.max ? value : result.max;
                result.min = value < result.min ? value : result.min;
            });
        }
        return result;
    }

}

