import {useInjection} from "inversify-react";
import {FunctionalComponent} from "../constants/globalTypes";
import Injectable from "../injection/injectable";
import React, {useMemo} from "react";
import BarChartStatisticVisualizer from "../components/riskModel/plugins/BarChartStatisticVisualizer";
import {useRiskStatisticsDummyData} from "../components/riskModel/utilities/dummyDataUtilities";
import RiskModelViewer from "../components/riskModel/components/RiskModelViewer";
import {testNetwork} from "../components/riskModel/network";
import {XYPosition} from "reactflow";
import {GroupID} from "../components/riskModel/enums";

const POSITIONS: Record<string, XYPosition> = {
    [GroupID.Rules]: { x: 0, y: 0 },
    [GroupID.Regulations]: { x: 200, y: 0 },
    result: { x: 400, y: 0 }
}

export default function RiskModel() {

    const network = useMemo(() => testNetwork(), []);

    const visualizationPlugins = useMemo(() => [
        new BarChartStatisticVisualizer()
    ], []);

    const dummyData = useRiskStatisticsDummyData(network);

    return (
        <RiskModelViewer
            network={network}
            positionFromId={id => POSITIONS[id]}
            statisticsDefinition={dummyData}
            visualizationPlugins={visualizationPlugins} />
    );
}
