import BaseInput from "../interfaces/BaseInput";
import OptionInput from "../interfaces/OptionInput";
import {Option} from "../constants/types";
import {callValueFromData, wrapInArray} from "./helperUtilities";
import {useEffect, useMemo} from "react";
import _ from "lodash";
import {InputRenderer} from "../interfaces/InputRenderer";

export function useOptions(
    props: InputRenderer<BaseInput & OptionInput>,
    options: Option[],
    data: any
) {
    const {
        input,
        update,
        label
    } = props;
    const valueCanBeUndefined = callValueFromData(input.canBeUndefined, data);
    const allowMultiple = callValueFromData(input.allowMultiple, data, false);
    const fallbackValue = options[0]?.value;
    const defaultValue = useMemo(() => {
        if (!_.isUndefined(input.defaultValue)) {
            return wrapInArray(input.defaultValue, allowMultiple);
        } else if (!_.isUndefined(fallbackValue) && !valueCanBeUndefined) {
            return wrapInArray(fallbackValue, allowMultiple);
        }
    }, [fallbackValue, allowMultiple, input]);

    const value = useMemo(() => {
        const value = _.isUndefined(props.value) ? defaultValue : props.value;
        if (allowMultiple && !_.isArray(value)) {
            return wrapInArray(value);
        } else if (!allowMultiple && _.isArray(value)) {
            return value[0];
        }
        return value;
    }, [props.value, allowMultiple, defaultValue]);

    useEffect(() => {
        const possibleValues = options.map(({ value }) => value);
        const nextValue = _.isArray(value)
            ? value.filter(v => possibleValues.includes(v))
            : possibleValues.includes(value) ? value : undefined;
        if (!_.isEqual(nextValue, value)) {
            update(nextValue);
        }
    }, [_.toString(options), value]);

    useEffect(() => {
        if (value !== props.value) {
            update(value);
        }
    }, [value, props.value]);

    return [value, defaultValue];
}