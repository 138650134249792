import * as React from "react";
import {Button, Card, Dropdown, Input, Modal, Tag, theme} from "antd";
import className from "../../assets/scss/components/addedfilters.scss";
import {bindActionCreators, Dispatch} from "redux";
import {FolderOpenOutlined, SaveOutlined} from '@ant-design/icons';
import {
    addFilter,
    clearFilterDefinitionUnbound,
    clearComparatorValueFromFilterUnbound, setFilterDefinitionsFromSavedFilter
} from "../../store/actions/data";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {CSSProperties, useMemo, useState} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {getLabelTextForFilter, isActiveFilter} from "../../utils/filterUtilities";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import Config from "../../interfaces/Config";
import {ComparatorValue, DefinedFilter, CustomFilter} from "../../constants/globalTypes";
import _ from "lodash";
import {addSavedFilter} from "../../store/actions/user";
import SaveSnapshot from "./SaveSnapshot";
import AddedFiltersProperties from "../../interfaces/properties/AddedFiltersProperties";
import {useDataSourceContext} from "../datasource/DataSourceProvider";

function AddedFilterTag(
  props: {
      filter: DefinedFilter|CustomFilter,
      comparatorValue?: ComparatorValue
      closable?: boolean
  }
) {
    const { maxLengthFilterValue } = useInjection<Config>(Injectable.Config);
    const { filter, comparatorValue } = props;
    const dispatch = useDispatch();
    const {
        clearFilterDefinition,
        clearComparatorValueFromFilter
    } = useMemo(() => bindActionCreators({
        clearFilterDefinition: clearFilterDefinitionUnbound,
        clearComparatorValueFromFilter: clearComparatorValueFromFilterUnbound
    }, dispatch), []);

    if (_.isUndefined(comparatorValue)) {
        return (
          <Tag
            key={filter.filterId}
            closable={props.closable}
            onClose={() => clearFilterDefinition(filter)}>
              {
                  getLabelTextForFilter(filter, maxLengthFilterValue)
              }
          </Tag>
        )
    }
    return (
      <Tag
        key={`${filter.filterId}-${comparatorValue}`}
        closable={props.closable}
        onClose={() => clearComparatorValueFromFilter(filter as DefinedFilter, comparatorValue)}>
          {
              getLabelTextForFilter(filter, maxLengthFilterValue, comparatorValue)
          }
      </Tag>
    );
}

function AntDAddedFilters(props: PropsFromRedux & AddedFiltersProperties) {
    const {
        addSavedFilter,
        inline = false,
        includeTools = true
    } = props;

    const [filterName, setFilterName] = useState("");
    const [showSaveModal, setShowModal] = useState(false);

    const {
        filters = []
    } = useDataSourceContext();

    const closeModal = (save: boolean = false) => {
        setShowModal(false);
        if (save) {
            addSavedFilter(filterName, filters);
        }
        setFilterName("");
    };

    const { token } = theme.useToken()

    const noFilters = filters.filter(isActiveFilter).length == 0;
    return (
        <div className={className.container} style={{
            padding: inline ? 0 : undefined,
            background: inline ? token.colorBgContainer : undefined
        }}>
            <Card className={className.card} style={{
                borderWidth: inline ? 0 : undefined,
            }}>
                <div className={className.content}>
                    <div className={className.filters}>
                        {
                            _.flatMap(
                              filters.filter(isActiveFilter),
                              (filter: DefinedFilter) => {
                                  const { filterId } = filter;
                                  return _.isArray(filter.comparatorValue)
                                    ? filter.comparatorValue.map((value, index) =>
                                          <AddedFilterTag
                                            key={`${filterId}-${index}`}
                                            filter={filter}
                                            closable={includeTools}
                                            comparatorValue={value} />)
                                    : <AddedFilterTag
                                        key={filterId}
                                        closable={includeTools}
                                        filter={filter} />;
                                }
                            )
                        }
                        {
                            noFilters
                              ? <div className={className.noFilter}>Ingen filter</div>
                              : <></>
                        }
                    </div>
                    {
                        includeTools && <SaveSnapshot buttonType="text" buttonSize="large" />
                    }
                </div>
            </Card>
            <Modal
              open={showSaveModal}
              onCancel={() => closeModal()}
              footer={false}
            >
                <div className={className.modalContent}>
                    <Input
                      onChange={e => setFilterName(e.target.value)}
                      placeholder="Filternavn" />
                    <div className={className.buttonContainer}>
                        <Button
                          onClick={() => closeModal()}>Avbryt</Button>
                        <Button
                          type="primary"
                          onClick={() => closeModal(true)}
                          disabled={filterName.length === 0}
                          icon={<SaveOutlined />} >Lagre</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

function mapStateToProps(
  state: GlobalState
) {
    const {
        data: {
            filters: filters
        },
        user: {
            data: {
                savedFilters
            }
        }
    } = state;
    return {
        filters,
        savedFilters
    };
}


function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        addFilter,
        addSavedFilter,
        setFilterDefinitionsFromSavedFilter
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AntDAddedFilters);
