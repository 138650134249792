import React, {useState} from "react";
import {callSetterOnValueChange} from "../../utils/hooks";
import {Button} from "antd";
import {FullscreenExitOutlined, FullscreenOutlined, PlusOutlined} from "@ant-design/icons";

// @ts-ignore
import className from "../../assets/scss/statistics.scss";

interface DashboardToolsProperties {
    addNewComponent: () => void;
    onFullscreenClick: (open: boolean) => void;
}

export function DashboardTools(props: DashboardToolsProperties) {
    const {
        addNewComponent,
        onFullscreenClick
    } = props;
    const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
    callSetterOnValueChange(onFullscreenClick, isFullScreenOpen);

    return (
        <div className={className.toolbar}>
            {
                !isFullScreenOpen && (
                    <Button
                        onClick={addNewComponent}
                        type="default"
                        icon={<PlusOutlined />}
                        size="middle" />
                )
            }
            <Button
                type="default"
                size="middle"
                onClick={() => setIsFullScreenOpen(!isFullScreenOpen)}
                icon={
                    isFullScreenOpen
                        ? <FullscreenExitOutlined />
                        : <FullscreenOutlined />
                }
            />
        </div>
    );
}