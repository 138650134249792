import {Collapse} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";
import {MenuItemRenderer} from "../MenuItemRenderer";
import {useSelectorEditorContext} from "../index";
import {MenuItem, NodeCategoryWithoutIgnore} from "../types";
import _ from "lodash";
import {HEADER_LABEL} from "../constants";

// @ts-ignore
import className from "../../../assets/scss/components/selectoreditor.scss";

export function EditorMenu(

) {
    const {
        nodePlugins
    } = useSelectorEditorContext();

    const categorizedMenuItems = useMemo(() => {
        const categorizedMenuItems: Record<string, MenuItem<any>[]> = {};
        nodePlugins.forEach(plugin => {
            if (_.isFunction(plugin.category)) {
                const menuItems = plugin.menuItems()
                const category = plugin.category() as NodeCategoryWithoutIgnore;
                const key = HEADER_LABEL[category];
                categorizedMenuItems[key] = _.isUndefined(categorizedMenuItems[key])
                    ? menuItems
                    : categorizedMenuItems[key].concat(menuItems);
            }
        });
        return categorizedMenuItems;
    }, []);
    const sortedCategoryKeys = useMemo(
        () => _.keys(categorizedMenuItems).sort((a, b) => a.localeCompare(b)),[]);

    return (
        <Collapse
            bordered={false}
            className={className.menu}
            defaultActiveKey={sortedCategoryKeys}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            ghost>
            {
                sortedCategoryKeys.map(key => {
                    const menuItems = categorizedMenuItems[key];
                    return (
                        <Collapse.Panel header={key} key={key}>
                            <div className={className.menuPanel}>
                                {
                                    menuItems.map(menuItem => <MenuItemRenderer {...menuItem} />)
                                }
                            </div>
                        </Collapse.Panel>
                    )
                })
            }
        </Collapse>
    );
}