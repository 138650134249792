import React, {useRef} from "react";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators, Dispatch} from "redux";
import {connect, ConnectedProps, useSelector} from "react-redux";
import TabComponentProperties from "../../interfaces/properties/TabComponentProperties";
import {updateDashboard} from "../../store/actions/user";
import {DEFAULT_DASHBOARD} from "../../constants/defaults";
import {StatisticViewMakerProperties} from "../statisticViewMaker";
import {injectReactComponent} from "../../utils/injectionUtilities";
import Injectable from "../../injection/injectable";
import {DashboardProvider} from "../dashboard/DashboardProvider";
import {Dashboard} from "../dashboard/Dashboard";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import {useResizeObserver} from "../../utils/hooks";

export interface StatisticsTabProperties extends TabComponentProperties {

}

function StatisticsTab(
    props: StatisticsTabProperties & PropsFromRedux
) {
    const {
        dashboard = DEFAULT_DASHBOARD,
        updateDashboard
    } = props;
    const ref = useRef<HTMLDivElement>(null)
    const {width} = useResizeObserver(ref)
    const {
        dataSource: {
            crossfilter
        }
    } = useDataSourceContext();
    return (
        <div
            ref={ref}
            style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        }}>
            <DashboardProvider crossfilter={crossfilter}>
                <Dashboard
                    onDashboardChange={updateDashboard}
                    dashboard={dashboard}
                    width={width} />
            </DashboardProvider>
        </div>
    )
}

function mapStateToProps(state: GlobalState) {
  const {
    dashboard
  } = state.user.data || {};
  return {
      dashboard
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({
    updateDashboard
  }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(StatisticsTab);

