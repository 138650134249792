import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {Card, theme} from "antd";
import GridLayout, {Layout} from "react-grid-layout";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {DashboardComponentType} from "../../constants/enums";
import {useResizeObserver} from "../../utils/hooks";
import {DashboardComponent, PartialComponent} from "../../constants/globalTypes";
import {useImmer} from "use-immer";
import {v4 as uuid} from "uuid";
import _ from "lodash";
import {DashboardSerialized} from "../statisticViewMaker";
import {DashboardTools} from "./DashboardTools";
import {useDiagramEditorContext} from "../diagramEditor";
import {DiagramType} from "../diagramEditor/constants/enums";
import DiagramRenderer from "../diagramEditor/DiagramRenderer";

// @ts-ignore
import className from "../../assets/scss/statistics.scss";
// @ts-ignore
import filterGridClassName from "../../assets/scss/components/filtergrids.scss";
import {useToken} from "antd/es/theme";

type Dashboardstate = {
    componentType?: DashboardComponentType;
    componentIndex?: number;
    initialComponent?: PartialComponent;
}

function getDefaultLayout(id: string): Layout {
    return {
        i: id,
        x: 0,
        y: 0,
        w: 5,
        h: 8
    };
}

function useDashboardState(
    props: DashboardProperties
) {
    const {
        dashboard: initialStatisticView,
        onDashboardChange
    } = props;
    const [dashboard, setDashboard] = useState<DashboardSerialized>(initialStatisticView);
    const [state, updateState] = useImmer<Dashboardstate>({});
    const {
        componentIndex,
        initialComponent
    } = state;

    const {
        openDiagramEditor
    } = useDiagramEditorContext()

    const updateDashboard = useCallback((dashboardSerialized: DashboardSerialized) => {
        onDashboardChange(dashboardSerialized);
        setDashboard(dashboardSerialized);
    },[onDashboardChange, setDashboard]);

    const addOrUpdateComponentInSerializedDashboard = useCallback((component: DashboardComponent) => {
        const components = dashboard.components.slice();
        const layout = { ... dashboard.layout };
        layout[component.id] = layout[component.id] || getDefaultLayout(component.id);
        const componentIndex = components.findIndex(({ id }) => id === component.id)
        if (componentIndex > 0) {
            components[componentIndex] = component;
        } else {
            components.push(component as DashboardComponent);
        }
        updateDashboard({ ...dashboard, components, layout });
    }, [dashboard, componentIndex]);

    const addNewComponent = useCallback(() => {
        openDiagramEditor(undefined, data => {
            addOrUpdateComponentInSerializedDashboard({
                id: uuid(),
                type: DiagramType.GeneralDiagram,
                data
            });
        })
    }, [updateState]);

    const editExistingComponent = useCallback(({ id, type, data: oldData }: DashboardComponent) => {
        openDiagramEditor(oldData, data => addOrUpdateComponentInSerializedDashboard({ id, type, data }))
    }, [updateState]);

    const deleteComponent = useCallback((component: DashboardComponent) => {
        const components = dashboard.components.filter(c => c.id !== component.id);
        const layout = { ...dashboard.layout };
        delete layout[component.id];
        updateDashboard({ components, layout });
    }, [dashboard, updateDashboard]);

    const updateLayout = useCallback((nextLayout: Layout[]) => {
        const layout = {...dashboard.layout};
        nextLayout.forEach(componentLayout => {
            layout[componentLayout.i] = componentLayout;
        });
        if (!_.isEqual(layout, dashboard.layout)) {
            updateDashboard({ ...dashboard, layout });
        }
    }, [dashboard]);

    const isEditingExistingComponent = _.isNumber(componentIndex);

    return {
        dashboard,
        addNewComponent,
        editExistingComponent,
        initialComponent,
        updateLayout,
        isEditingExistingComponent,
        deleteComponent
    }
}


interface DashboardProperties {
    onDashboardChange: (state: DashboardSerialized) => void;
    dashboard: DashboardSerialized;
    includeTools?: boolean;
    width?: number;
    nonEditable?: boolean;
}

export function Dashboard(props: DashboardProperties) {
    const ref = useRef(null);
    const size = useResizeObserver(ref);

    const handle = useFullScreenHandle()

    const {
        width,
        includeTools = true,
        nonEditable = false
    } = props

    const {
        addNewComponent,
        editExistingComponent,
        dashboard,
        updateLayout,
        deleteComponent
    } = useDashboardState(props);

    const onFullscreenClick = useCallback((open: boolean) => {
        if (open) {
            handle.enter()
        } else {
            handle.exit()
        }
    }, [])

    const { token } = theme.useToken()

    return (
        <FullScreen
            handle={handle}
            className={className.statisticViewMakerContainer}>
            <Card
                ref={ref}
                style={{ background: token.colorBgContainer, borderRadius: 0 }}
                className={className.content}
                bordered={false}>
                {
                    includeTools && !nonEditable && (
                        <DashboardTools
                            onFullscreenClick={onFullscreenClick}
                            addNewComponent={addNewComponent} />
                    )
                }
                <GridLayout
                    draggableHandle={`.${filterGridClassName.draggableHandle}`}
                    cols={12}
                    rowHeight={30}
                    onLayoutChange={updateLayout}
                    width={width || size.width}
                    isResizable={!nonEditable}
                    containerPadding={[20, 20]}>
                    {
                        dashboard.components.map(component => {
                            const layout = dashboard.layout[component.id];
                            return (
                                <div
                                    key={component.id}
                                    data-grid={layout}>
                                    <DiagramRenderer
                                        nonEditable={nonEditable}
                                        dashboardComponent={component}
                                        layout={layout}
                                        onEdit={() => editExistingComponent(component)}
                                        onDelete={() => deleteComponent(component)} />
                                </div>
                            );
                        })
                    }
                </GridLayout>
            </Card>
        </FullScreen>
    )
}