import React from "react";
import {useInjection} from "inversify-react";
import Injectable from "../injection/injectable";
import {FunctionalComponent} from "../constants/globalTypes";
import {ComparisonSplit} from "../components/comparison";

export default function Comparison() {

    return  <ComparisonSplit />

}
