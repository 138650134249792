import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faTurnUp} from "@fortawesome/free-solid-svg-icons";
import NodeMenu from "../../NodeMenu";
import {Handle, Position} from "reactflow";
import {InputNumber, Select} from "antd";
import {Option} from "../../../../diagramEditor/constants/types";
import {CaseData, DefaultCaseData} from "./types";

// @ts-ignore
import className from "../../../../../assets/scss/components/selectoreditor.scss";
import {SWITCH_CASE_INPUT} from "./constants";

interface CaseProperties {
    id: string;
    data: CaseData|DefaultCaseData;
    onChange: (data: CaseData) => void;
    options?: Option[];
}

export function Case(props: CaseProperties) {
    const {
        data = {},
        options = [],
        onChange,
        id
    } = props;

    const {
        isDefaultCase = false,
    } = data;

    const [expanded, setExpanded] = useState(true);

    const caretIcon = useMemo(() => expanded
        ? <FontAwesomeIcon icon={faCaretDown}/>
        : <FontAwesomeIcon icon={faCaretRight}/>, [expanded]);

    const maxHeight = useMemo(() => expanded ? undefined : 36, [expanded]);
    const caseText = useMemo(() => isDefaultCase ? 'Default' : 'Case' , [isDefaultCase]);

    const optionsWithCaseValues = useMemo(() => {
        if (!isDefaultCase) {
            const {
                caseValues = []
            } = data as CaseData;
            return caseValues.map(caseValue => ({
                label: caseValue,
                value: caseValue
            })).concat(options);
        }
        return [];
    }, [options, data]);

    return (
        <div className={className.case}>
            <NodeMenu />
            <Handle
                id={`${SWITCH_CASE_INPUT}-${id}`}
                type="target"
                position={Position.Left}
                style={{ background: 'black', top: 20, left: -15 }}
                isConnectable={true} />
            <div
                className={className.content}
                style={{ maxHeight }}>
                <div className={`${className.header} nodrag`}>
                    <div
                        className={className.collapsibleTrigger}
                        onClick={() => setExpanded(!expanded)}>
                        <div className={className.caret}>
                            {caretIcon}
                        </div>
                        <span className={className.caseText}>
                        {caseText}
                    </span>
                    </div>
                    {
                        !isDefaultCase && (
                            <Select
                                maxTagCount="responsive"
                                mode="multiple"
                                value={(data as CaseData).caseValues}
                                onChange={value => onChange({
                                    ...data,
                                    caseValues: value
                                })}
                                className={className.select}
                                options={optionsWithCaseValues}
                                bordered={false} />
                        )
                    }
                </div>
                <div className={className.output}>
                    <div className={className.arrow}>
                        <FontAwesomeIcon
                            icon={faTurnUp}
                            className="fa-rotate-90" />
                    </div>
                    <div className="nodrag">
                        <InputNumber
                            value={data.outputValue}
                            onChange={value => onChange({
                                ...(data as any),
                                outputValue: value as number
                            })}
                            className={className.input}
                            defaultValue={1}
                            bordered={false} />
                    </div>
                </div>
            </div>
        </div>
    );
}
