import NodePlugin from "../../../interface/NodePlugin";
import {DataPoint} from "../../../../../interfaces/models/DataPoint";
import {Edge, Handle, NodeProps, Position} from "reactflow";
import {Context, MenuItem} from "../../../types";
import React, {memo} from "react";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {SwitchNodeData} from "./interfaces";
import {useCaseState, useDimension, useValueOptions} from "./hooks";
import {Case} from "./Case";
import {NodeCategory, NodeType, OutputType} from "../../../enums";

// @ts-ignore
import className from "../../../../../assets/scss/components/selectoreditor.scss";
import {SWITCH_CASE_INPUT, SWITCH_IN, SWITCH_OUT} from "./constants";
import BaseNodeData from "../../../interface/BaseNodeData";
import {checkHandleConnectionCount} from "../../../utilities/helperUtilities";

export default class SwitchNode implements NodePlugin<DataPoint, SwitchNodeData> {
    public renderer(): React.FC<NodeProps<SwitchNodeData>> {
        return memo((props: NodeProps<SwitchNodeData>) => {
            const { id, data, isConnectable } = props;
            const dimension = useDimension(id);
            const { addCase, cases, defaultCase, updateCaseData, updateDefaultCase } = useCaseState(id, data, dimension);
            const { shouldIncludeDefault, valueOptions } = useValueOptions(data, dimension);
            return (
                <div className={className.switchNode}>
                    <Handle
                        id={SWITCH_IN}
                        type="target"
                        position={Position.Left}
                        style={{ background: 'black', top: 25 }}
                        isConnectable={isConnectable}
                    />
                    <div className={className.header}>
                        <div className={className.label}>
                            Switch
                        </div>
                        {
                            !_.isUndefined(dimension) && <FontAwesomeIcon
                                onClick={addCase}
                                className={`${className.button} nodrag`}
                                icon={faCirclePlus} />
                        }
                    </div>
                    <div className={className.cases}>
                        {
                            _.entries(cases)
                                .map(([key, caseData]) => (
                                    <Case
                                        key={key}
                                        id={key}
                                        options={valueOptions}
                                        onChange={caseData => updateCaseData(key, caseData)}
                                        data={caseData} />
                                ))
                        }
                        {
                            shouldIncludeDefault && (
                                <Case
                                    key="default"
                                    id="default"
                                    onChange={updateDefaultCase}
                                    data={defaultCase} />
                            )
                        }
                    </div>
                    <Handle
                        type="source"
                        position={Position.Right}
                        id={SWITCH_OUT}
                        style={{ background: 'black', bottom: 25, top: 'auto' }}
                        isConnectable={isConnectable}
                    />
                </div>
            );
        });
    }
    public execute(
        dataPoint: DataPoint,
        inputValues: Record<string, any>,
        nodeData: SwitchNodeData,
        context: Context
    ): Record<string, any> {
        const value = inputValues[SWITCH_IN];
        const {
            cases,
            defaultCase
        } = nodeData;
        for (const caseData of _.values(cases)) {
            if (caseData.caseValues.includes(value)) {
                return { [SWITCH_OUT]: caseData.outputValue };
            }
        }
        return { [SWITCH_OUT]: defaultCase?.outputValue };
    }
    public validateConnection(handle: string, node: NodeProps<BaseNodeData>): boolean {
        if (handle === SWITCH_IN) {
            return node.data.outputType === OutputType.Categorical;
        } else if (handle.startsWith(SWITCH_CASE_INPUT)) {
            return node.data.outputType === OutputType.Numerical;
        }
        return false;
    }

    public validateNode(
        nodeData: SwitchNodeData,
        incomingEdges: Edge[],
        outgoingEdges: Edge[]
    ): boolean {
        return checkHandleConnectionCount(SWITCH_IN, 1, 1, incomingEdges)
            && checkHandleConnectionCount(SWITCH_OUT, 1, Infinity, outgoingEdges);
    }

    public type(): NodeType {
        return NodeType.Switch;
    }

    public category(): NodeCategory {
        return NodeCategory.Logic;
    }

    public menuItems(): MenuItem<SwitchNodeData>[] {
        return [
            {
                type: NodeType.Switch,
                label: "Switch",
                data: { outputType: OutputType.Numerical }
            }
        ];
    }

}