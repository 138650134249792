import StringInput from "../interfaces/StringInput";
import React from "react";
import {InputRenderer} from "../interfaces/InputRenderer";
import {Form, Input,} from "antd";

// @ts-ignore
import className from "../../../assets/scss/components/diagrameditor.scss";

export default function StringInputRenderer(props: InputRenderer<StringInput>) {
    const {
        input: {
            canBeUndefined,
        },
        label,
        update,
        value
    } = props;
    return (
        <Form.Item
            name={label}
            label={label}
            rules={[{ required: !canBeUndefined }]}>
            <Input
                defaultValue={value}
                value={value}
                onChange={e => update(e.target.value)} />
        </Form.Item>
    );
}
