import React, {useRef} from "react";
import {COLORS, MISSING_NODE_TYPE_COLOR} from "./constants";
import {useDrag} from "react-dnd";
import {Tag} from "antd";
import {MenuItem} from "./types";

const tagPlusStyle: React.CSSProperties = {
    padding: 6,
    fontSize: 14,
    borderStyle: 'dashed',
    minWidth: 32,
    textAlign: 'center',
    marginInlineEnd: 0
};

export function MenuItemRenderer(props: MenuItem<any>) {
    const tagRef = useRef<HTMLElement|null>(null);
    const { type, label, data } = props;
    const color = COLORS[type] || MISSING_NODE_TYPE_COLOR;
    const [{ opacity, cursor }, dragRef] = useDrag(
        () => ({
            type: 'element',
            item: {
                type,
                ...data
            },
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
                cursor: monitor.isDragging() ? 'pointer' : 'grab'
            })
        }),
        [type, data]
    );
    return (
        <Tag
            ref={ref => {
                dragRef(ref);
                tagRef.current = ref
            }}
            style={{ ...tagPlusStyle, opacity, cursor }}
            color={color}>
            { label }
        </Tag>
    );
}