import {useInjection} from "inversify-react";
import Config from "../../interfaces/Config";
import Injectable from "../../injection/injectable";
import {useDataSourceContext} from "../datasource/DataSourceProvider";
import React, {PropsWithChildren, useMemo} from "react";
import DcPlugin from "../diagramEditor/implementations/dc";
import {DataPoint} from "../../interfaces/models/DataPoint";
import DimensionNode from "../selectorEditor/components/nodes/dimension";
import MathNode from "../selectorEditor/components/nodes/math";
import SwitchNode from "../selectorEditor/components/nodes/switch";
import {useDispatch, useSelector} from "react-redux";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {bindActionCreators} from "redux";
import {updateDimensionGraphs} from "../../store/actions/user";
import SelectorEditorProvider from "../selectorEditor";
import DiagramEditorProvider from "../diagramEditor";
import {Crossfilter} from "crossfilter2";


export function DashboardProvider({
    children
}: PropsWithChildren<any>) {
    const {
        dataSource: {
            crossfilter
        }
    } = useDataSourceContext();
    const { dimensions } = useInjection<Config>(Injectable.Config);
    const diagramEditorPlugins = useMemo(() => [
        new DcPlugin<DataPoint>(crossfilter)
    ], [crossfilter]);
    const selectorEditorPlugins = useMemo(() => [
        new DimensionNode(),
        new MathNode(),
        new SwitchNode()
    ], []);

    const { dimensionGraphs } = useSelector((state: GlobalState) => state.user.data);

    const dispatch = useDispatch();
    const actions = bindActionCreators({ updateDimensionGraphs }, dispatch);

    return (
        <SelectorEditorProvider
            plugins={selectorEditorPlugins}
            dimensionGraphs={dimensionGraphs}
            onChange={actions.updateDimensionGraphs} >
            <DiagramEditorProvider
                plugins={diagramEditorPlugins}
                dimensions={dimensions}>
                {children}
            </DiagramEditorProvider>
        </SelectorEditorProvider>
    );
}