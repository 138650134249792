import BaseInput from "./interfaces/BaseInput";
import {Checkbox} from "antd";
import _ from "lodash";
import React from "react";
import {InputRenderer} from "./interfaces/InputRenderer";

// @ts-ignore
import className from "../../assets/scss/components/diagrameditor.scss";

export default function FormItemLabel(props: InputRenderer<BaseInput>) {
    const {
        label,
        input: {
            optional = false
        }
    } = props;
    return (
        <div className={className.label}>
            <div className={className.checkbox}>
                {
                    optional ? <Checkbox /> : <></>
                }
            </div>
            {_.capitalize(label)}
        </div>
    );
}