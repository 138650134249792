import React, {useMemo, useRef, useState} from "react";
import styled from "styled-components";
import SplitPane from "react-split-pane";
import {Comparison, ComparisonPane, MainMapComponent} from "../../constants/globalTypes";
import {theme} from "antd";
import GlobalState from "../../store/interfaces/states/GlobalState";
import {connect, ConnectedProps} from "react-redux";
import DataSourceProvider from "../datasource/DataSourceProvider";
import {SelectSnapshotsToCompare} from "./SelectSnapshotsToCompare";
import {useInjection} from "inversify-react";
import Injectable from "../../injection/injectable";
import {Dashboard} from "../dashboard/Dashboard";
import {bindActionCreators, Dispatch} from "redux";
import {updateDashboard} from "../../store/actions/user";
import {DashboardProvider} from "../dashboard/DashboardProvider";
import {DEFAULT_DASHBOARD} from "../../constants/defaults";
import {useResizeObserver} from "../../utils/hooks";
import AddedFiltersProperties from "../../interfaces/properties/AddedFiltersProperties";

interface ComparisonSplitProps {

}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const SelectorContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const MapContainer = styled.div`
  width: 100%;
  height: 350px;
  min-height: 350px;
  position: relative;
`

const ComparisonPaneContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
`

const DividerHorizontal = styled.div`
  width: 3px;
`

const DividerVertical = styled.div`
  height: 3px;
  min-height: 3px;
`

interface ComparisonProps {
    pane: ComparisonPane
}

function mapStateToProps(state: GlobalState) {
    const { filters } = state.data
    const { savedFilters, dashboard } = state.user.data;
    return { filters, savedFilters, dashboard };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({
        updateDashboard
    }, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>

const ComparisonPane = connector(function (props: ComparisonProps & PropsFromRedux) {
    const {
        pane,
        filters,
        updateDashboard,
        dashboard = DEFAULT_DASHBOARD,
    } = props;
    const ref = useRef<HTMLDivElement>(null)
    const { width } = useResizeObserver(ref);
    const { token } = theme.useToken();

    const staticFilters = useMemo(
        () => (pane.useActiveFilters ? filters : pane.filters)?.map(filter => ({...filter})) ?? [],
        [pane])
    const Map = useInjection<MainMapComponent>(Injectable.MainMap);
    const AddedFilters = useInjection<React.FunctionComponent<AddedFiltersProperties>>(Injectable.AddedFilters);

    return (
        <ComparisonPaneContainer>
            <DataSourceProvider staticFilters={staticFilters}>
                <MapContainer ref={ref}>
                    <Map
                        includeMapFilterControl={false}
                        includeFilterControl={false} />
                </MapContainer>
                <DividerVertical style={{ background: token.colorBorderSecondary }} />
                <AddedFilters includeTools={false} inline={true} />
                <DividerVertical style={{ background: token.colorBorderSecondary, height: 1, minHeight: 1 }} />
                <DashboardProvider>
                    <Dashboard
                        nonEditable={true}
                        onDashboardChange={updateDashboard}
                        dashboard={dashboard}
                        width={width} />
                    </DashboardProvider>
            </DataSourceProvider>
        </ComparisonPaneContainer>
    )
})

export function ComparisonSplit({}: ComparisonSplitProps) {
    const [comparison, setComparison] = useState<Comparison>()
    const { token } = theme.useToken();

    if (comparison) {
        return (
            <Container>
                <ComparisonPane pane={comparison.leftPane} />
                <DividerHorizontal style={{ background: token.colorBorderSecondary }} />
                <ComparisonPane pane={comparison.rightPane} />
            </Container>
        )
    } else {
        return (
            <SelectorContainer style={{ background: token.colorBgLayout }}>
                <SelectSnapshotsToCompare setComparison={setComparison} />
            </SelectorContainer>
        )
    }
}