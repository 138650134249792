import SelectInput from "../interfaces/SelectInput";
import _ from "lodash";
import {useDiagramEditorContext} from "../index";
import {callValueFromData} from "../utilities/helperUtilities";
import React, {ReactElement, useMemo} from "react";
import {Button, Form, Select} from "antd";
import {InputRenderer} from "../interfaces/InputRenderer";
import {useOptions} from "../utilities/hooks";

// @ts-ignore
import className from "../../../assets/scss/components/diagrameditor.scss";
import {joinClassNames} from "../../../utils/miscUtilities";

function tagRenderer(props: any) {
    const { label } = props;
    return (
        <span className={className.tagLabel}>
            {label}
            <span className={className.comma}>,&nbsp;</span>
        </span>
    );
}

export default function SelectInputRenderer(
    props: InputRenderer<SelectInput> & { elementAfterSelect?: ReactElement }
) {
    const {
        input,
        update,
        label,
        elementAfterSelect
    } = props;
    const {
        options: optionBeforeFilter,
        canBeUndefined,
        optionsFilter = _.stubTrue,
    } = input;
    const { chartConfig } = useDiagramEditorContext();
    const notRequired = callValueFromData(canBeUndefined, chartConfig, false);
    const options = useMemo(
        () => optionBeforeFilter.filter(option => optionsFilter(chartConfig, option)),
        [optionBeforeFilter]);
    const [value, defaultValue] = useOptions(props, options, chartConfig);
    const allowMultiple = callValueFromData(input.allowMultiple, chartConfig, false);

    return (
        <Form.Item
            label={label}
            rules={[{ required: !notRequired }]}>
            <div className={joinClassNames(
                className.dimensionInput,
                elementAfterSelect ? className.hasElementAfterSelect : undefined)}>
                <Select
                    mode={allowMultiple ? 'multiple' : undefined}
                    tagRender={allowMultiple ? tagRenderer : undefined}
                    allowClear={notRequired}
                    defaultValue={defaultValue}
                    value={value}
                    options={options}
                    onChange={update} />
                {
                    !_.isUndefined(elementAfterSelect) && elementAfterSelect
                }
            </div>
        </Form.Item>
    );

}