import {DimensionGraph} from "../../../interfaces/Config";
import {Updater} from "use-immer";
import {useCallback, useRef, useState} from "react";
import {DEFAULT_VIEWPORT} from "../constants";
import {Node} from "reactflow";
import {useDrop} from "react-dnd";
import {v4 as uuid} from "uuid";
import {NodeType} from "../enums";

type Item = {
    type: NodeType;
} & Record<string, any>;
export function useNodeDropZone(
    dimensionGraph: DimensionGraph,
    updateDimensionGraph: Updater<DimensionGraph>
) {
    const container = useRef<HTMLDivElement|null>(null);
    const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

    const addNode = useCallback((node: Node) => updateDimensionGraph({
        ...dimensionGraph,
        nodes: dimensionGraph.nodes.concat(node)
    }), [dimensionGraph, updateDimensionGraph]);

    const { nodes } = dimensionGraph;
    const [{}, drop] = useDrop(() => ({
        accept: 'element',
        drop: (item: Item, monitor) => {
            const offset = monitor.getClientOffset();
            if (offset && container.current) {
                const { type, ...data } = item;
                const { x, y } = offset;
                const { x: flowX, y: flowY, zoom } = viewport;
                const { x: containerX, y: containerY } = container.current.getBoundingClientRect();
                const id = uuid();
                const position = {
                    x: (x - containerX - flowX) / zoom,
                    y: (y - containerY - flowY) / zoom
                };
                addNode({ id, position, type, data });
            }
            return { name: 'node-editor' };
        }
    }), [viewport, nodes]);
    return {
        setViewport,
        setDropRef: (ref: any) => {
            drop(ref)
            container.current = ref;
        },
    }
}
