import {NodeCategory, NodeType} from "./enums";
import React from "react";
import {Node} from "reactflow";
import {DimensionGraph} from "../../interfaces/Config";

export const COLORS = {
    [NodeType.Math]: '#108ee9',
    [NodeType.Switch]: '#f50',
    [NodeType.Dimension]: '#87d068',
    [NodeType.Result]: "#f57d1a"
};

export const DEFAULT_VIEWPORT = {
    x: 0,
    y: 0,
    zoom: 1
};

export const MISSING_NODE_TYPE_COLOR = 'black';

export const OUTPUT_NODE: Node = {
    id: NodeType.Result,
    position: { x: 0, y: 0 },
    type: NodeType.Result,
    data: undefined,
};

export const DEFAULT_DIMENSION_GRAPH: Omit<DimensionGraph, "id"> = {
    nodes: [],
    edges: [],
    label: "Dimensjonnavn"
};

export const HEADER_LABEL: Record<Exclude<NodeCategory, NodeCategory.Ignore>, string> = {
    [NodeCategory.Logic]: "Logikk",
    [NodeCategory.Math]: "Matte",
    [NodeCategory.General]: "Generell"
};

